import React from 'react';
import tools from "../tools_svg";

const SvgBox = React.memo((props) => {
    const onMouseUpPoint = (e) => {
        // console.log('>>>>>>>>>>>>>', e)
        props.onMouseUpPoint()
    };
    // console.log('>>>>>>>>>>>>>', props.coords)
    
    return (
        <svg
            onMouseMove={props.onMouseMove}
            onClick={props.onClick}
            onMouseUp={onMouseUpPoint}
            height={'100%'} width={'100%'}>
            {tools.createSvgElement(props)}

        </svg>
    );
});

export default SvgBox;