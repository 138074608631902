import React, { useContext, useState, useEffect } from 'react';
import './form_new_palan_set_style.less';

import GlobalDispatchContext from "../../global_dispatch_context";
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack, IStackProps, IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import {createTheme, getTheme, loadTheme} from 'office-ui-fabric-react/lib/Styling';
import { SpinButton } from 'office-ui-fabric-react/lib/SpinButton';
import ListFloors from "./components/list";
import {
	IButtonStyles,
	IconButton,
	HighContrastSelector,
	Label,
	IContextualMenuProps,
	IIconProps,
	mergeStyleSets,
	FontWeights,
	ContextualMenu,
	Toggle,
	DefaultButton,
	Modal,
	IDragOptions,
} from 'office-ui-fabric-react';
import ImageUploader from "react-images-upload";
import { Image } from 'office-ui-fabric-react/lib/Image';
import './style/form_new_plan_set_style.less';


const theme = getTheme();
// const { fonts } = theme;



const NewPlanSetMain = (props) => {
	
	const {state, dispatch} = useContext(GlobalDispatchContext);
	const [statusShowModal, setStatusShowModal] = useState(false);
	const [dataFloor, setDataFloor] = useState(null);
	const [countFloor, setCountFloor] = useState(1);
	const [listFloors, setListFloors] = useState([]);
	
	useEffect(() => {
		if (state.modeDisplay === 'light') {
			loadTheme(
				createTheme({
					defaultFontStyle: { fontFamily: 'Roboto, sans-serif', fontWeight: 400 },
					fonts: {
						medium: { fontSize: 14 }
					},
					palette: {
						themePrimary: '#46b875',
						themeLighterAlt: '#f6fcf8',
						themeLighter: '#dbf4e5',
						themeLight: '#beead0',
						themeTertiary: '#85d4a6',
						themeSecondary: '#57c083',
						themeDarkAlt: '#3fa569',
						themeDark: '#358c59',
						themeDarker: '#276742',
						neutralLighterAlt: '#f8f8f8',
						neutralLighter: '#f4f4f4',
						neutralLight: '#eaeaea',
						neutralQuaternaryAlt: '#dadada',
						neutralQuaternary: '#d0d0d0',
						neutralTertiaryAlt: '#c8c8c8',
						neutralTertiary: '#b2b2b2',
						neutralSecondary: '#999999',
						neutralPrimaryAlt: '#808080',
						neutralPrimary: '#1c1c1c',
						neutralDark: '#4e4e4e',
						black: '#353535',
						white: '#ffffff'
					}
				})
			);
		}
		else {
			loadTheme({
				palette: {
					themePrimary: '#727372',
					themeLighterAlt: '#050505',
					themeLighter: '#121212',
					themeLight: '#222222',
					themeTertiary: '#444544',
					themeSecondary: '#646564',
					themeDarkAlt: '#808180',
					themeDark: '#939493',
					themeDarker: '#afb0af',
					neutralLighterAlt: '#000000',
					neutralLighter: '#000000',
					neutralLight: '#000000',
					neutralQuaternaryAlt: '#000000',
					neutralQuaternary: '#000000',
					neutralTertiaryAlt: '#000000',
					neutralTertiary: '#272625',
					neutralSecondary: '#4e4c4b',
					neutralPrimaryAlt: '#72706e',
					neutralPrimary: '#827f7d',
					neutralDark: '#a09d9b',
					black: '#b9b7b5',
					white: '#000000',
				}
			});
		}
		
	}, [state.modeDisplay]);
	
	
	useEffect(() => {
		
		let list = [];
		
		for (var i = 1; i <= countFloor; i++) {
			list.push({
				markerFloor: i,
				image: ''
			})
		}
		
		setListFloors(list);
		
	}, [countFloor]);
	
	const stackStyles  = {
		root: {
			width: 1000
		}
	};
	const columnProps  = {
		tokens: { childrenGap: 15 },
		styles: { root: { width: 300 } },
	};
	
	const columnProps2  = {
		tokens: { childrenGap: 15 },
		styles: { root: { width: 700 } },
	};
	
	const columnProps3  = {
		tokens: { childrenGap: 15 },
		styles: { root: { width: 700 } },
	};
	
	
	const addIcon = { iconName: 'Add' };
	const customSplitButtonStyles = {
		splitButtonMenuButton: { backgroundColor: 'white', width: 28, border: 'none' },
		splitButtonMenuIcon: { fontSize: '7px' },
		splitButtonDivider: { backgroundColor: '#c8c8c8', width: 1, right: 26, position: 'absolute', top: 4, bottom: 4 },
		splitButtonContainer: {
			selectors: {
				[HighContrastSelector]: { border: 'none' },
			},
		},
	};
	
	const menuProps = {
		items: [
			{
				key: 'emailMessage',
				text: 'Email message',
				iconProps: { iconName: 'Mail' },
			},
			{
				key: 'calendarEvent',
				text: 'Calendar event',
				iconProps: { iconName: 'Calendar' },
			},
		],
	};
	
	const onClickAdd = () => {
		setStatusShowModal(true);
	};
	
	const onClickHideModal = () => {
		setStatusShowModal(false);
	}
	
	const onClickAddWithData = (data) => {
		setStatusShowModal(true);
		setDataFloor(data);
	};
	
	
	const contentStyles = mergeStyleSets({
		container: {
			display: 'flex',
			flexFlow: 'column nowrap',
			alignItems: 'stretch',
		},
		header: [
			// tslint:disable-next-line:deprecation
			theme.fonts.xLargePlus,
			{
				flex: '1 1 auto',
				borderTop: `4px solid ${'#46b875'}`,
				color: theme.palette.neutralPrimary,
				display: 'flex',
				alignItems: 'center',
				fontWeight: FontWeights.semibold,
				padding: '12px 12px 14px 24px',
			},
		],
		body: {
			flex: '4 4 auto',
			padding: '0 24px 24px 24px',
			overflowY: 'hidden',
			selectors: {
				p: { margin: '14px 0' },
				'p:first-child': { marginTop: 0 },
				'p:last-child': { marginBottom: 0 },
			},
		},
	});
	const iconButtonStyles = {
		root: {
			color: theme.palette.neutralPrimary,
			marginLeft: 'auto',
			marginTop: '4px',
			marginRight: '2px',
		},
		rootHovered: {
			color: theme.palette.neutralDark,
		},
	};
	
	const cancelIcon = { iconName: 'Cancel' };
	
	// console.log('>>>>>>>>>>>', dataFloor)
	
	const _onDecrement = (value) => {
		if (countFloor > 1) {
			setCountFloor(countFloor - 1);
			dispatch({
				type:'GET_NEW_PLAN_SET_FLOORS',
				countFloors: countFloor - 1
			})
		}
		
	};
	
	const _onIncrement = (value) => {
		setCountFloor(countFloor + 1);
		dispatch({
			type:'GET_NEW_PLAN_SET_FLOORS',
			countFloors: countFloor + 1
		})
	};
	
	const onChangeImageInput = (picture, index) => {
		
		dispatch({
			type:'GET_NEW_PLAN_IMAGE_OF_FLOORS',
			picture,
			index
		})
	}
	
	const onChangeName = (e) => {
		dispatch({
			type:'GET_NEW_PLAN_DETAIL_INFO',
			status: 'name',
			data: e.target.value
		})
	}
	
	const onChangeCoords = (e) => {
		dispatch({
			type:'GET_NEW_PLAN_DETAIL_INFO',
			status: 'coords',
			data: e.target.value.split(', ')
		})
	}
	
	const onChangePlId = (e) => {
		dispatch({
			type:'GET_NEW_PLAN_DETAIL_INFO',
			status: 'plId',
			data: e.target.value
		})
	}
	
	const onBlurSpin = (e) => {
		console.log('>>>>>>>>>>>>', e.target.value)
		if (+e.target.value <= 100 ) {
			setCountFloor(e.target.value);
			dispatch({
				type:'GET_NEW_PLAN_SET_FLOORS',
				countFloors: e.target.value
			})
		}
		else {
			setCountFloor(100);
			dispatch({
				type:'GET_NEW_PLAN_SET_FLOORS',
				countFloors: 100
			})
		}
		
	}
	
	return (
		<div className={'NewPlanSetMain'} >
			<Stack horizontal tokens={{ childrenGap: 50 }} styles={stackStyles}>
				<Stack {...columnProps}>
					<TextField onChange={onChangeName} label="Имя план сета" placeholder={'Имя'} />
					<SpinButton
						// defaultValue="1"
						label={'Количество этажей (минимум 1 этаж)'}
						min={1}
						onBlur={onBlurSpin}
						max={100}
						onDecrement={_onDecrement}
						onIncrement={_onIncrement}
						step={1}
						value={countFloor}
						// iconProps={{ iconName: 'IncreaseIndentLegacy' }}
					
					/>
					<TextField onChange={onChangeCoords} label="Координаты (latitude, longitude)" placeholder={'latitude, longitude'} />
					<TextField onChange={onChangePlId} label="pl id (не обязательно)" placeholder={'id'} />
					
				</Stack>
				<Stack {...columnProps2}>
					
					<ListFloors
						_items={listFloors}
						onClickAdd={onClickAddWithData}
						onClickHideModal={onClickHideModal}
						onChangeImageInput={onChangeImageInput}
					/>
				
				</Stack>
				
				
				{/*<Stack {...columnProps}>*/}
				{/*	<TextField label="Required " required />*/}
				{/*	<TextField ariaLabel="Required without visible label" required />*/}
				{/*	<TextField label="With error message" errorMessage="Error message" />*/}
				
				{/*</Stack>*/}
				
			</Stack>
			
			{/*<div className="tempDevelopment">*/}
			{/*Этот раздел в разработке. Перейдите по ссылке с uuid план сета!*/}
			{/*</div>*/}
		</div>
	);
};

export default NewPlanSetMain;