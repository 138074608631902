import React, {useEffect, useState, useReducer, useContext} from 'react';
import cookie from 'react-cookies';
import { parseQuery } from './components/tools';
import './App.css';
import './App23.less';
import TopPanelMain from "./components/top_panel";
import LeftPanelMain from "./components/left_panel";
import PanelDetailObjects from "./components/panel_detail_objects";
import MainMaps from "./components/maps";
// import initialState from "./inistial_state";
import reducer from "./reducer";
import GlobalDispatchContext from "./global_dispatch_context";
import styled from 'styled-components';
import axios from 'axios';
import { loadTheme, createTheme } from 'office-ui-fabric-react/lib/Styling';
import { ClipLoader } from 'react-spinners';
import { Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    PrimaryButton,
    Modal,
    IconButton,
    Callout,
    DelayedRender,
    DirectionalHint
} from 'office-ui-fabric-react';

import { FontSizes } from '@uifabric/styling';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
// import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { SpinButton } from 'office-ui-fabric-react/lib/SpinButton';
import Cookies from 'universal-cookie';
import NewPlanSetMain from "./components/form_new_plan_set";

import { useHotkeys } from 'react-hotkeys-hook';
import EngineeringModal from "./components/engineering_menu/engineering_modal";
import hostForLocation from "./hostnames/hostname";
import ListPlanSetsMain from "./components/list_plansets";


const cookie2 = new Cookies();



const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 206px 1fr 1fr 206px;
  grid-template-rows: 50px calc(100% - 50px);
  ${
    props => props.state.uuidSet === '' ?
        `grid-template-areas:
          "header header header header"
          "main main main main";`
        :
    props => props.state.statusSelectedObjects ? 
        `grid-template-areas:
          "header header header header"
          "nav main main sidebar";` : 
        `grid-template-areas:
          "header header header header"
          "nav main main main";`
    }
  
`;

const BoxLoader = styled.div`
    display: ${props => (props.statusLoadReportings ? 'grid' : 'none')};
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: ${props => props.statusLight ? 'rgba(255,255,255,.5)' : 'rgba(0,0,0,.5)'};
    justify-content: center;
    align-items: center;
   
`;

const CHECK_VERSION_INTERVAL = 120000;

const App = React.memo(() => {
    const initialState = useContext(GlobalDispatchContext);
    const [token, setToken] = useState('');
    const [state, dispatch] = useReducer(reducer, initialState);
    const [statusLoad, setStatusLoad] = useState(false);
    const [statusShowNotif, setStatusShowNotif] = useState(false);
    const [dataAfterSave, setDataAfterSave] = useState(null);
    const [dataError, setDataError] = useState(null);
    const [statusOpasity, setStatusOpasity] = useState(false);
    const [statusShowModalOfNewGroupZones, setStatusShowModalOfNewGroupZones] = useState(false);
    const [nameGroupZone, onChangeNameGroupZone] = useState('');
    const [markerGroupZone, onChangeMarkerGroupZone] = useState('');
    const [errorMessage, setError] = useState(false);
    const [errorMessageNameGroupZone, setErrorMessageNameGroupZone] = useState(null);
    const [statusShowPanelSettings, setStatusShowPanelSettings] = useState(false);
    const [versionInterval, setVersionInterval] = useState(null);
    const [statusShowModalNewVersion, setStatusShowModalNewVersion] = useState(false);
    const [engineeringCount, setEngineeringCount] = useState(0);
    const [engineeringShow, setEngineeringShow] = useState(false);
    const [obj, getTempObj] = useState({});
    const [statusLoadRemote, getStatusLoadRemote] = useState(false);
    const [statusShowModalInfo, getStatusShowModalInfo] = useState(false);


    const getAxios = async (url, typeAction) => {
        try {
            const response = await axios.get(url);
            // console.log('>>>>>>>>>>>>>>>>',response);
            dispatch({
                type:typeAction,
                data: response.data
            })
            setStatusLoad(false);
            getStatusLoadRemote(false);
            
            // return response
        } catch (error) {
            createTimer();
            console.error(error);
            setDataError(error);
            setStatusLoad(false);
            getStatusLoadRemote(false);
        }
        // return response;
    };

    // console.log('>>>>>>>>>>>>>>>>',state.token);

    const postAxios = async (type,url, typeAction, data, funcUpdate) => {
        try {
            await axios({
                method: type,
                url: url,
                data: data,
                headers: {

                    'x-token': state.token,
                    // 'Content-Type': 'multipart/form-data'
                }


            }).then(function (response) {

                createTimer();
                // setDataAfterSave();
                if (response.data.status === 'saved') {
                    if (funcUpdate) {
                        funcUpdate();
                    }
                    
                    setDataAfterSave(response.data.status);
                }
            });


            // dispatch({
            //     type:typeAction,
            //     data: response.data
            // })
            // return response
        } catch (error) {
            setDataError(error);
            console.error(error);
        }
        // return response;
    };
    
    const postAxiosForNewPlanSert = async (type,url, typeAction, data, funcUpdate) => {
        try {
            await axios({
                method: type,
                url: url,
                data: data,
                headers: {
                    
                    'x-token': state.token,
                    'Content-Type': 'multipart/form-data'
                }
                
                
            }).then(function (response) {
                // console.log('>>>>>>>>>>>>>>>>>1112', response.status)
                createTimer();
                // setDataAfterSave();
                if (response.status === 200) {
                    let urlGetListPlanSets = `${hostForLocation.getHostName().maps}/geometry/available_plan_sets/`
                    getAxiosWithHeaders( urlGetListPlanSets, 'GET_LIST_PLAN_SETS');
                    // console.log('>>>>>>>>>>>>>>>>>111')
                    if (funcUpdate) {
                        funcUpdate();
                    }
    
                    setStatusLoad(false);
                    // dispatch({
                    //     type:'CLEAR_NEW_PLAN_SET'
                    // })
                    setDataAfterSave(response.data.status);
                    alert('План сет успешно сохранился')
                }
            });
            
            
            // dispatch({
            //     type:typeAction,
            //     data: response.data
            // })
            // return response
        } catch (error) {
            setStatusLoad(false);
            setDataError(error);
            alert(error)
            console.error(error);
        }
        // return response;
    };
    
    const getAxiosWithHeaders = async (url, typeAction) => {
        try {
            await axios({
                method: 'get',
                url: url,
                // data: data,
                headers: {
                    
                    'x-token': state.token,
                }
                
                
            }).then(function (response) {
    
                return dispatch({
                    type:typeAction,
                    data: response.data
                })
            });
            
            
            // dispatch({
            //     type:typeAction,
            //     data: response.data
            // })
            // return response
        } catch (error) {
            setDataError(error);
            console.error(error);
        }
        // return response;
    };
    
    const getAxiosRemoteTracks = async (url, typeAction, urlPlanSet) => {
        try {
            const response = await axios.get(url);
            // console.log('>>>>>>>>>>>>>>>>',response);
            // dispatch({
            //     type:typeAction,
            //     data: response.data
            // })
            // setStatusLoad(false);
            // getStatusLoadRemote(false);
            getAxiosRemotePlanSet(urlPlanSet, typeAction,  response.data);
            // return response
        } catch (error) {
            console.error(error);
        }
        // return response;
    };
    
    const getAxiosRemotePlanSet = async (url, typeAction, dataTrack) => {
        try {
            const response = await axios.get(url);
            // console.log('>>>>>>>>>>>>>>>>',response);
            dispatch({
                type:typeAction,
                data: response.data,
                dataTracks: dataTrack
            })
            setStatusLoad(false);
            getStatusLoadRemote(false);
            // return response
        } catch (error) {
            console.error(error);
        }
        // return response;
    };
    
    useEffect(() => {
        const back = JSON.parse(localStorage.getItem('backend'));
        dispatch({
            type:'FILL_BACKEND_ENGINEERING',
            backend: back
        })
    }, []);
    
    
    useEffect(() => {
        
        setInterval(() => {
            getRemotePlanSet(state.uuidSet);
        }, 30000);
    }, [state.uuidSet]);
    
    useEffect(() => {
        
        if (state.src.dataPlanSet !== null) {
            getStatusShowModalInfo(state.dataTrackForDraw[state.selectedFloor] ? false : true)
        }
        
    }, [state.dataTrackForDraw]);

    const createTimer = () => {
        setStatusShowNotif(true);
        setTimeout(() => {
            setStatusShowNotif(false);
        }, 5000);
    };


    useEffect(() => {
        const query = parseQuery(window.location.search);
        const myToken = query.token ? query.token : cookie.load('xtoken');
        // console.log('!!!!!!!!!!!!!!!!!', myToken)

        const objCookieForSettings = {
            colorScheme: cookie.load('colorScheme') ? cookie.load('colorScheme') : null,
            statusGrid: cookie.load('statusGrid') ? cookie.load('statusGrid') : null,
            stepGrid: cookie.load('stepGrid') ? cookie.load('stepGrid') : null
        }

        dispatch({
            type:'GET_COOKIE_SETTINGS',
            objCookieForSettings: objCookieForSettings
        })

        const arrayPathName = window.location.pathname.split('/');
        dispatch({
            type:'GET_TOKEN',
            token: token
        })
        if (arrayPathName[1] === 'make_geometry') {
            
            dispatch({
                type:'GET_UUID',
                uuidSet: arrayPathName[2]
            })
        }
        if (!myToken || myToken === 'undefined') {
            window.location.replace('https://sso.wifizone.me/accounts/login?next=' + document.location.href, '_blank');
        }



        setToken(prev => myToken);

        if (token) {
            cookie.save('xtoken', token, { path: '/' });
            // getMaps(token);
        }
    }, [token]);

    useEffect(() => {
        
        if (state.uuidSet !== '') {
            if (state.backend === null || Object.keys(state.backend).length > 0) {
                if (state.src.dataPlanSet === null) {
                    setStatusLoad(true);
                    hostForLocation.getLocationForHost(document.location.host, state.backend);
                    const urlPlanset = `${hostForLocation.getHostName().maps}/geometry/plan_set/${state.uuidSet}/`;
                    // console.log('>>>>>>>>>>>>>>');
                    getAxios(urlPlanset, 'GET_PLAN_SET');
    
                    const urlTrack = `${hostForLocation.getHostName().maps}/geometry/tracks/${state.uuidSet}/`;
                    getAxios(urlTrack, 'GET_TRACK');
                }
                
            }

        }
        else {
            if (state.backend === null || Object.keys(state.backend).length > 0) {
                if (state.token !== '') {
                    // console.log('>>>>>>>>!!!!', state.token)
                    // console.log('>>>>>>>>>>>>>>>!!!!!!!!>>>>');
                    let urlGetListPlanSets = `${hostForLocation.getHostName().maps}/geometry/available_plan_sets/`
                    getAxiosWithHeaders( urlGetListPlanSets, 'GET_LIST_PLAN_SETS');
                }
                
            }
            
        }
    }, [state.uuidSet, state.backend, state.token]);

    useEffect(() => {
        fetch('/version.txt', { cache: 'no-cache' })
            .then(res => (res.ok ? res.text() : Promise.reject(res)))
            .then(text => {
                cookie2.set('version', text.trim(), { path: '/' });
            })
            .catch(() => console.warn('Net Error'));

        const interval = setInterval(checkVersion, CHECK_VERSION_INTERVAL);
        setVersionInterval(interval);
        
        return () => {
            clearInterval(versionInterval);
        };
    }, []);
    
    

    useEffect(() => {
        if (state.modeDisplay === 'light') {
            loadTheme(
                createTheme({
                    defaultFontStyle: { fontFamily: 'Roboto, sans-serif', fontWeight: 400 },
                    fonts: {
                        medium: { fontSize: 14 }
                    },
                    palette: {
                        themePrimary: '#46b875',
                        themeLighterAlt: '#f6fcf8',
                        themeLighter: '#dbf4e5',
                        themeLight: '#beead0',
                        themeTertiary: '#85d4a6',
                        themeSecondary: '#57c083',
                        themeDarkAlt: '#3fa569',
                        themeDark: '#358c59',
                        themeDarker: '#276742',
                        neutralLighterAlt: '#f8f8f8',
                        neutralLighter: '#f4f4f4',
                        neutralLight: '#eaeaea',
                        neutralQuaternaryAlt: '#dadada',
                        neutralQuaternary: '#d0d0d0',
                        neutralTertiaryAlt: '#c8c8c8',
                        neutralTertiary: '#b2b2b2',
                        neutralSecondary: '#999999',
                        neutralPrimaryAlt: '#808080',
                        neutralPrimary: '#1c1c1c',
                        neutralDark: '#4e4e4e',
                        black: '#353535',
                        white: '#ffffff'
                    }
                })
            );
        }
        else {
            loadTheme({
                palette: {
                    themePrimary: '#727372',
                    themeLighterAlt: '#050505',
                    themeLighter: '#121212',
                    themeLight: '#222222',
                    themeTertiary: '#444544',
                    themeSecondary: '#646564',
                    themeDarkAlt: '#808180',
                    themeDark: '#939493',
                    themeDarker: '#afb0af',
                    neutralLighterAlt: '#000000',
                    neutralLighter: '#000000',
                    neutralLight: '#000000',
                    neutralQuaternaryAlt: '#000000',
                    neutralQuaternary: '#000000',
                    neutralTertiaryAlt: '#000000',
                    neutralTertiary: '#272625',
                    neutralSecondary: '#4e4c4b',
                    neutralPrimaryAlt: '#72706e',
                    neutralPrimary: '#827f7d',
                    neutralDark: '#a09d9b',
                    black: '#b9b7b5',
                    white: '#000000',
                }
            });
        }

    }, [state.modeDisplay]);
    
    useEffect(() => {
        if (engineeringCount > 0) {
            setEngineeringShow(true);
            // openEngineeringModal(backend);
        }
    }, [engineeringCount]);
    
    
    
    useEffect(() => {
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>!!!!!1')
        hostForLocation.getLocationForHost(document.location.host, state.backend);
        
    }, [document.location.host, state.backend]);
    
    useEffect(() => {
        if (state.backend !== null) {
            if (Object.keys(state.backend).length !== 0) {
                localStorage.setItem('backend', JSON.stringify(state.backend));
            }
        }
        
    }, [state.backend]);
    
    useEffect(() => {
        if (state.statusConflict === false && state.statusPressSave && statusLoadRemote === false) {
            const urlSavePlanSet = `${hostForLocation.getHostName().maps}/geometry/save_plan_set/${state.uuidSet}/`;
            const urlSaveTrack = `${hostForLocation.getHostName().maps}/geometry/save_tracks/${state.uuidSet}/`;
            // console.log('>>>>>>>>>>>>>>>>!!!!!',
            //     encodeURIComponent(JSON.stringify(obj.dataPlanSet)),
            //     // obj.dataPlanSet.plans[0].layers.place_layer
            // );
            setStatusLoad(true);
            postAxios('post', urlSavePlanSet, 'GET_TRACK', `data=${encodeURIComponent(JSON.stringify(obj.dataPlanSet))}`, updatePlansetRequest);
            postAxios('post', urlSaveTrack, 'GET_TRACK', `tracks=${JSON.stringify(obj.tracksData)}`, updateTracksRequest);
        }
        
        
    }, [state.statusConflict, state.statusPressSave, statusLoadRemote]);
    // useEffect(() => {
    //     console.log('>>>>>>>>>>>>>>>>>>>>>>>>!!!!!1', state.statusConflict)
    //     if (state.statusConflict === false && state.statusPressSave) {
    //         console.log('>>>>>>>>>>>>>>>>>>>>>>>>!!!!!')
    //         const urlSavePlanSet = `${hostForLocation.getHostName().maps}/geometry/save_plan_set/${state.uuidSet}/`;
    //         const urlSaveTrack = `${hostForLocation.getHostName().maps}/geometry/save_tracks/${state.uuidSet}/`;
    //         // console.log('>>>>>>>>>>>>>>>>!!!!!',
    //         //     encodeURIComponent(JSON.stringify(obj.dataPlanSet)),
    //         //     // obj.dataPlanSet.plans[0].layers.place_layer
    //         // );
    //         setStatusLoad(true);
    //         postAxios('post', urlSavePlanSet, 'GET_TRACK', `data=${encodeURIComponent(JSON.stringify(obj.dataPlanSet))}`, updatePlansetRequest);
    //         postAxios('post', urlSaveTrack, 'GET_TRACK', `tracks=${JSON.stringify(obj.tracksData)}`, updateTracksRequest);
    //     }
    //
    //
    // }, [state.statusConflict, state.statusPressSave]);
    useEffect(() => {
        if (state.statusDefaultPage === 'listPlanSet') {
            // console.log('>>>>>>>>>>>>>>>!!!!!!!!>>>>1', state.token);
            if (state.token !== '') {
                let urlGetListPlanSets = `${hostForLocation.getHostName().maps}/geometry/available_plan_sets/`
                getAxiosWithHeaders( urlGetListPlanSets, 'GET_LIST_PLAN_SETS');
            }
            
        }
        
    }, [state.statusDefaultPage]);
    const updatePlansetRequest = () => {
        if (state.uuidSet !== '') {
            // console.log('>>>>>>>>>>>>>>1');
            const urlPlanset = `${hostForLocation.getHostName().maps}/geometry/plan_set/${state.uuidSet}/`;
            getAxios(urlPlanset, 'GET_PLAN_SET');

        }
    };
    
    const getPlansetRequestInfo = (data) => {
        // console.log('>>>>>>>>>>>>>>1',data.uuid);
        if (data.uuid !== '') {
            
    
            setStatusLoad(true);
            const urlPlanset = `${hostForLocation.getHostName().maps}/geometry/plan_set/${data.uuid}/`;
            getAxios(urlPlanset, 'GET_PLAN_SET_INFO');
            
        }
    };
    
    const getRemotePlanSet = (uuidSet) => {
        if (uuidSet !== '') {
            getStatusLoadRemote(true);
            const urlPlanset = `${hostForLocation.getHostName().maps}/geometry/plan_set/${state.uuidSet}/`;
            const urlTrack = `${hostForLocation.getHostName().maps}/geometry/tracks/${state.uuidSet}/`;
    
            
            getAxiosRemoteTracks(urlTrack, 'GET_REMOTE_PLAN_SET', urlPlanset);
            
        }
    };

    const updateTracksRequest = () => {
        if (state.uuidSet !== '') {
            
            const urlTrack = `${hostForLocation.getHostName().maps}/geometry/tracks/${state.uuidSet}/`;
            getAxios(urlTrack, 'GET_TRACK');

        }
    };

    // console.log('>>>>>>>>>>>>>>>>finally', state.dataPlanSet);

    const saveData = (obj) => {
        if (!obj.tempStatusTrackLength) {
            getRemotePlanSet(state.uuidSet);
            getTempObj(obj)
            dispatch({
                type:'GET_STATUS_PRESS_SAVE',
                status: true
            })
        }
        
       
    };
    
    const saveDataAfterConflict = (obj2) => {
        const urlSavePlanSet = `${hostForLocation.getHostName().maps}/geometry/save_plan_set/${state.uuidSet}/`;
        const urlSaveTrack = `${hostForLocation.getHostName().maps}/geometry/save_tracks/${state.uuidSet}/`;
        // console.log('>>>>>>>>>>>>>>>>!!!!!',
        //     encodeURIComponent(JSON.stringify(obj.dataPlanSet)),
        //     // obj.dataPlanSet.plans[0].layers.place_layer
        // );
        setStatusLoad(true);
        postAxios('post', urlSavePlanSet, 'GET_TRACK', `data=${encodeURIComponent(JSON.stringify(obj2.dataPlanSet))}`, updatePlansetRequest);
        postAxios('post', urlSaveTrack, 'GET_TRACK', `tracks=${JSON.stringify(obj2.tracksData)}`, updateTracksRequest);
        
    };
    
    const onShowModalConflict = () => {
        getRemotePlanSet(state.uuidSet);
        
    };

    const checkVersion = () => {
        const cookieVer = cookie2.get('version');
        fetch('/version.txt', { cache: 'no-cache' })
            .then(res => (res.ok ? res.text() : Promise.reject(res)))
            .then(text => {
                // console.log('>>>>>>>>>>>>>>>>',cookieVer.trim(), text.trim());
                if (cookieVer.trim() !== text.trim()) {
                    setStatusShowModalNewVersion(true);
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 55000);
                } else {
                    // console.log('Versions are equal');
                }
            })
            .catch(() => console.warn('Net Error'));
    };

    const hideModalNewVersion = () => {
        setStatusShowModalNewVersion(false);
    };

    const reloadPage = () => {
        window.location.reload(false);
    };

    const verticalStackProps = {
        styles: { root: { overflow: 'hidden', width: '100%' } },
        tokens: { childrenGap: 20 }
    };

    const horizontalStackProps = {
        horizontal: true,
        tokens: { childrenGap: 16 }
    };

    const onChoise = (e) => {
        setStatusShowNotif(false);
    };

    // if (dataError !== null) {
    //     console.log('>>>>>>>>>>>>>>>>', dataError.message);
    // }

    const getStatusContextMenu = (data) => {
        setStatusOpasity(data !== null);
    };

    const closeModal = () => {
        setStatusShowModalOfNewGroupZones(false);
        onChangeNameGroupZone('');
        onChangeMarkerGroupZone('');
    };

    let _dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });
    const contentStylesEngeneering = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            height: 'auto',
            width: '60%',
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });

    const iconButtonStyles = mergeStyleSets({
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: theme.palette.neutralDark
        }
    });

    const columnProps = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } }
    };

    const onChangeMarker = (e) => {
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>',e.target.value)
        onChangeMarkerGroupZone(e.target.value);
    }

    const onChangeName = (e) => {
        onChangeNameGroupZone(e.target.value);
    }
    // console.log('>>>>>>>>>>>>>>>>', state.objMarkersByFloorOfGroupZone)
    const onClickButtonAddGroupZone = (e) => {
        // console.log('>>>>>>>>>>>>>>>>', state.selectedFloor, state.objMarkersByFloorOfGroupZone)
        if ( state.objMarkersByFloorOfGroupZone[state.selectedFloor][markerGroupZone] === undefined) {
            if (state.objMarkersOfGroupZone[markerGroupZone] === undefined) {
                setError(false);
                setErrorMessageNameGroupZone(null);
                dispatch({
                    type:'CREATE_NEW_GROUP_ZONE',
                    name: nameGroupZone,
                    marker: markerGroupZone
                })
                setStatusShowModalOfNewGroupZones(false);
                onChangeNameGroupZone('');
                onChangeMarkerGroupZone('');
            }
            else {
                if (state.objMarkersOfGroupZone[markerGroupZone]?.nameGroup === nameGroupZone) {
                    setError(false);
                    setErrorMessageNameGroupZone(null);
                    dispatch({
                        type:'CREATE_NEW_GROUP_ZONE',
                        name: nameGroupZone,
                        marker: markerGroupZone
                    })
                    setStatusShowModalOfNewGroupZones(false);
                    onChangeNameGroupZone('');
                    onChangeMarkerGroupZone('');
                }
                else {
                    setErrorMessageNameGroupZone('Существует группа зон с таким же маркером названия тоже должны совпадать.');
                }
            }
            
        }
        else {
            setError('На этом этаже уже существует группа зон с таким маркером.');
        }
    };

    // console.log('>>>>>>>>>>>>>>>>>>>>>>>',e.target.value)

    const onDismissMenu = (e) => {
        e.stopPropagation()
        setStatusShowPanelSettings(false);
    };

    const onShowPanelSettings = () => {
        setStatusShowPanelSettings(true);
    };

    const styles = mergeStyleSets({
        buttonArea: {
            verticalAlign: 'top',
            display: 'inline-block',
            textAlign: 'center',
            margin: '0 100px',
            minWidth: 130,
            height: 32
        },
        configArea: {
            minWidth: '300px',
            display: 'inline-block'
        },
        callout: {
            maxWidth: 300
        },
        calloutExampleButton: {
            width: '100%'
        },
        header: {
            padding: '18px 24px 12px'
        },
        title: [
            theme.fonts.xLarge,
            {
                margin: 0,
                color: theme.palette.neutralPrimary,
                fontWeight: FontWeights.semilight
            }
        ],
        inner: {
            height: '100%',
            padding: '0 24px 20px'
        },
        subtext: [
            theme.fonts.small,
            {
                margin: 0,
                color: theme.palette.neutralPrimary,
                fontWeight: FontWeights.semilight
            }
        ],
        link: [
            theme.fonts.medium,
            {
                color: theme.palette.neutralPrimary
            }
        ],
        actions: {
            position: 'relative',
            marginTop: 20,
            width: '100%',
            whiteSpace: 'nowrap'
        }
    });

    const onChangeThemeColor = (e, data) => {

        cookie.save('colorScheme', data.key, { path: '/' });
        dispatch({
            type:'GET_MODE_DISPLAY',
            mode: data.key
        })
    };

    const onChangeGridToggle = (e, checked) => {

        cookie.save('statusGrid', checked, { path: '/' });
        dispatch({
            type:'GET_GRID_TOGGLE',
            status: checked
        })
        if (checked) {
            cookie.save('stepGrid', state.stepGrid, { path: '/' });
        }
    };

    const onChangeMarkToggle = (e, checked) => {

        // cookie.save('statusGrid', checked, { path: '/' });
        dispatch({
            type:'GET_MARK_TOGGLE',
            status: checked
        })

    };


    const getStepGridPlus = (value) =>{

        if (state.stepGrid < 1) {
            let stringTemp = state.stepGrid + '';
            let _stringTemp = '';
            let array = stringTemp.split('.');
            if (array[1] === '9') {
                _stringTemp = 1;
            }
            else if (stringTemp === '0') {
                _stringTemp = `0.${1}`
            }
            else {
                _stringTemp = `0.${+array[1] + 1}`
            }

            cookie.save('stepGrid', _stringTemp, { path: '/' });
            dispatch({
                type:'GET_GRID_STEP',
                step: +_stringTemp
            })
        }

    };

    const getStepGridMinus = (value) =>{
        if (state.stepGrid > 0) {
            let stringTemp = state.stepGrid + '';
            let _stringTemp = '';
            let array = stringTemp.split('.');
            if (array[1] === '1') {
                _stringTemp = 0.1;
            }
            else if (stringTemp === '1') {
                _stringTemp = `0.${9}`
            }
            else {
                _stringTemp = `0.${+array[1] - 1}`
            }
            cookie.save('stepGrid', _stringTemp, { path: '/' });
            dispatch({
                type:'GET_GRID_STEP',
                step: +_stringTemp
            })
        }

    };

    const styleIconButton = {
        root: {
            color: 'rgb(141,79,79)'
        }
    };

    const optionsChoice = [
        {
            key: 'light',
            // imageSrc: TestImages.choiceGroupBarUnselected,
            // imageAlt: 'Bar chart icon',
            // selectedImageSrc: TestImages.choiceGroupBarSelected,
            // imageSize: { width: 32, height: 32 },
            iconProps: { iconName: 'CalendarDay' },
            styles: {
                root: {
                    background: 'rgb(255,255,255)',
                    color: '#1c1c1e'
                }
            },
            text: 'Светланя тема' // This text is long to show text wrapping.
        },
        {
            key: 'dark',
            // imageSrc: TestImages.choiceGroupBarUnselected,
            // selectedImageSrc: TestImages.choiceGroupBarSelected,
            // imageSize: { width: 32, height: 32 },
            iconProps: { iconName: 'CalendarDay' },
            text: 'Темная сторона',
            styles: {
                root: {
                    background: '#1c1c1e',
                    color: 'rgb(255,255,255)'
                }
            }
        }
    ];

    const iconPropsForSpinBuuton = { iconName: 'Padding' };

    const styleDiv = { width: '185px' };

    const iconPropsForIconBuuton = { iconName: 'Cancel' };

    const tokensStack = { childrenGap: 50 };

    const stylesStack = { root: { width: 300 } };
    
    useHotkeys('shift+d+o', () => setEngineeringCount((prev) => prev + 1));
    
    const onModalButtonClick = (name, params) => {
        dispatch({
            type:'FILL_BACKEND_ENGINEERING',
            backend: params
        })
        setEngineeringShow(false);
    };
    
    const onHideEngineeringMenu = (e) => {
        setEngineeringShow(false);
    };
    
    const onHideInfoModal = (e) => {
        getStatusShowModalInfo(false);
    };
    
    const createNewPlanSet = (e) => {
    
        const urlCreatePlanSet = `${hostForLocation.getHostName().maps}/geometry/create_plan_set/`;
        var formData = new FormData();
        
        formData.append("plan_set_data", JSON.stringify(state.newPlanSetData));
        // formData.append("FILES", state.filesData);
        state.filesData.forEach((item, index) => {
            // console.log('>>>>>>>>>>>>>>>>>>>', item)
            formData.append(index, item, item.name);
        });
        setStatusLoad(true);
    
        postAxiosForNewPlanSert('post', urlCreatePlanSet, null, formData, null);
    }
    
    // console.log('>>>>>>>>>>>>>>>>>>>', dataError)
    
    // state.dataTrackForDraw[state.selectedFloor]
    return (
        <GlobalDispatchContext.Provider value={{ state, dispatch }}>
            <Container state={state} className={state.modeDisplay === 'light' ? `App` : `App dark`}>
                {state.uuidSet !== '' ?
                    <><MainMaps
                    getStatusContextMenu={getStatusContextMenu}
                    />
        
                    <LeftPanelMain
                    state={state}
                    statusOpasity={statusOpasity}
                    setStatusShowModalOfNewGroupZones={setStatusShowModalOfNewGroupZones}
                    /></>
                    :
                    state.statusDefaultPage === 'newPlanSet' ?
                    <>
                        <NewPlanSetMain/>
                        <div
                            className={'leftPanel'} id={'leftPanel'} >
                            
                        </div>
                        
                    </>
                    :
                    <>
                        <ListPlanSetsMain
                            getPlansetRequestInfo={getPlansetRequestInfo}
                        />
                        <div
                            className={'leftPanel'} id={'leftPanel'} >
    
                        </div>

                    </>
    
                }

                {
                    state.statusSelectedObjects ?
                        <PanelDetailObjects
                            state={state}
                        /> :
                        null
                }

                <TopPanelMain
                    state={state}
                    saveData={saveData}
                    statusOpasity={statusOpasity}
                    saveDataAfterConflict={saveDataAfterConflict}
                    onShowModalConflict={onShowModalConflict}
                    createNewPlanSet={createNewPlanSet}
                />
                <div className="boxMenuButton">
                    <Stack tokens={{ childrenGap: 8 }} horizontal>
                        <IconButton
                            iconProps={{iconName: 'GlobalNavButton' }}
                            title="GlobalNavButton"
                            ariaLabel="GlobalNavButton"
                            onClick={onShowPanelSettings}
                            styles={styleIconButton}
                        />
                    </Stack>

                </div>
                <div>


                    {statusShowPanelSettings ? <Callout
                        className={styles.callout}
                        target={{x: window.innerWidth - 40, y: 8}}
                        onDismiss={onDismissMenu}
                        role="status"
                        aria-live="assertive"
                        directionalHint={DirectionalHint.leftTopEdge}
                    >
                        <DelayedRender>
                            <>
                                <div className={styles.header}>
                                    <p className={styles.title}>
                                        Настройки
                                    </p>
                                </div>
                                <div className={styles.inner}>
                                    <Stack {...verticalStackProps}>
                                        <Stack {...horizontalStackProps}>
                                            <ChoiceGroup
                                                label="Цветовая схема"
                                                selectedKey={state.modeDisplay}
                                                options={optionsChoice}
                                                onChange={onChangeThemeColor}
                                            />
                                        </Stack>
                                        <Toggle
                                            label="Включение сетки"
                                            checked={state.statusGrid}
                                            onText="On"
                                            offText="Off"
                                            onChange={onChangeGridToggle}
                                        />
                                        <div style={styleDiv}>
                                            <SpinButton
                                                value={state.stepGrid}
                                                label={'Шаг сетки'}
                                                min={0}
                                                max={1}
                                                step={0.1}
                                                disabled={!state.statusGrid}
                                                iconProps={iconPropsForSpinBuuton}
                                                // tslint:disable:jsx-no-lambda
                                                // onFocus={() => console.log('onFocus called')}
                                                // onBlur={() => console.log('onBlur called')}
                                                onIncrement={getStepGridPlus}
                                                onDecrement={getStepGridMinus}
                                            />
                                        </div>
                                        <Toggle
                                            label="Включение надписей"
                                            checked={state.isShowMark}
                                            onText="On"
                                            offText="Off"
                                            onChange={onChangeMarkToggle}
                                        />

                                    </Stack>
                                </div>


                            </>
                        </DelayedRender>
                    </Callout> : null}
                </div>
                <BoxLoader statusLight={state.modeDisplay === 'light'} statusLoadReportings={statusLoad}>
                    <div className={'itemSpiner'}>
                        <ClipLoader
                            color={state.modeDisplay === 'light' ? '#46b875' : 'rgb(255,255,255)'}
                            loading={statusLoad}
                            css={'margin: 0 auto;'}
                        />
                    </div>

                </BoxLoader>
                {statusShowNotif ? <div className="boxNotifacation">
                    <Stack {...horizontalStackProps}>
                        <Stack {...verticalStackProps}>
                            {dataError !== null ?
                                <MessageBar
                                    dismissButtonAriaLabel="Close"
                                    onDismiss={onChoise}
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                >
                                    {dataError.message}
                                </MessageBar> :
                                <MessageBar
                                    dismissButtonAriaLabel="Close"
                                    onDismiss={onChoise}
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}

                                >
                                Изменения успешно сохранены! -
                                    {dataAfterSave}
                            </MessageBar>}
                        </Stack>
                    </Stack>
                </div> : null}


                <Modal
                    titleAriaId={'New group zone'}
                    subtitleAriaId={'subText'}
                    isOpen={statusShowModalOfNewGroupZones}
                    onDismiss={closeModal}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                    dragOptions={_dragOptions}
                >
                    <div className={contentStyles.header}>
                        <span id={'New group zone'}>Новая группа зон</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={iconPropsForIconBuuton}
                            ariaLabel="Close popup modal"
                            onClick={closeModal}
                        />
                    </div>
                    
                    <div id={'subText'} className={contentStyles.body}>
                        <Stack vertical tokens={tokensStack} styles={stylesStack}>
                            <Stack {...columnProps}>
                                <TextField
                                    label="Название группы"
                                    value={nameGroupZone}
                                    onChange={onChangeName}
                                    errorMessage={errorMessageNameGroupZone}
                                />
                                <TextField
                                    label="Маркер группы"
                                    value={markerGroupZone}
                                    onChange={onChangeMarker}
                                    errorMessage={errorMessage}
                                />
                                <PrimaryButton
                                    text="Сохранить"
                                    disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                                    onClick={onClickButtonAddGroupZone}
                                    // allowDisabledFocus
                                    // disabled={disabled}
                                    // checked={checked}
                                />
                            </Stack>

                        </Stack>
                    </div>
                </Modal>
                <Modal
                    titleAriaId={'New Hash'}
                    subtitleAriaId={'subText'}
                    isOpen={statusShowModalNewVersion}
                    onDismiss={hideModalNewVersion}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                    dragOptions={_dragOptions}
                >
                    <div className={contentStyles.header}>
                        <span id={'New group zone'}>Новая версия</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={iconPropsForIconBuuton}
                            ariaLabel="Close popup modal"
                            onClick={hideModalNewVersion}
                        />
                    </div>
                    <div id={'subText'} className={contentStyles.body}>
                        <Stack vertical tokens={tokensStack} styles={stylesStack}>
                            <Stack {...columnProps}>
                                <div>
                                    <p>
                                        Доступна новая версия web-приложения Shopster Maps Service. Пожалуйста, перезагрузите страницу для корректной работы.
                                    </p>
                                </div>
                                <PrimaryButton
                                    text="Перезагрузить"
                                    // disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                                    onClick={reloadPage}
                                    // allowDisabledFocus
                                    // disabled={disabled}
                                    // checked={checked}
                                />
                            </Stack>

                        </Stack>
                    </div>
                </Modal>
                <Modal
                    // titleAriaId={'New Hash'}
                    // subtitleAriaId={'subText'}
                    isOpen={engineeringShow}
                    onDismiss={onHideEngineeringMenu}
                    isBlocking={false}
                    containerClassName={contentStylesEngeneering.container}
                    // dragOptions={_dragOptions}
                >
                    <div className={contentStylesEngeneering.header}>
                        <span id={'New group zone'}>Инженерное меню</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={iconPropsForIconBuuton}
                            ariaLabel="Close popup modal"
                            onClick={onHideEngineeringMenu}
                        />
                    </div>
                    <div className={contentStylesEngeneering.body}>
                        <EngineeringModal
                            backend={state.backend}
                            onModalButtonClick={onModalButtonClick}
                        />
                    </div>
                    
                </Modal>
    
                <Modal
                    // titleAriaId={'New Hash'}
                    // subtitleAriaId={'subText'}
                    isOpen={statusShowModalInfo}
                    onDismiss={onHideInfoModal}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                    // dragOptions={_dragOptions}
                >
                    
                    <div className={contentStyles.header}>
                        <span id={'New group zone'}>Информация</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={iconPropsForIconBuuton}
                            ariaLabel="Close popup modal"
                            onClick={hideModalNewVersion}
                        />
                    </div>
                    <div id={'subText'} className={contentStyles.body}>
                        <Stack vertical tokens={tokensStack} styles={stylesStack}>
                            <Stack {...columnProps}>
                                <div>
                                    <p>
                                        В этом план сете нету данных по трекам
                                    </p>
                                </div>
                            </Stack>
        
                        </Stack>
                    </div>
                    
    
                </Modal>

            </Container>
        </GlobalDispatchContext.Provider>

    );
});



export default App;
