import React, {useContext, useEffect, useState} from 'react';
// import './form_new_palan_set_style.less';

import { FocusZone, FocusZoneDirection } from 'office-ui-fabric-react/lib/FocusZone';
import { List } from 'office-ui-fabric-react/lib/List';
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import {  getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { getTheme, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import ImageUploader from "react-images-upload";
import { SpinButton, ISpinButtonStyles } from 'office-ui-fabric-react/lib/SpinButton';
import GlobalDispatchContext from "../../../global_dispatch_context";



const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

// const { fonts } = theme;



const ListFloors = (props) => {
	const {state, dispatch} = useContext(GlobalDispatchContext);
	const [dataFloor, setDataFloor] = useState(null);
	const [_items, set_items] = useState(null);
	
	useEffect(() => {
		
		let tempItems = _items;
		let _tempItems = [];
		if (tempItems !== null) {
			tempItems.forEach((item, index) => {
				_tempItems.push({
					...item,
					datafloorsState: state.newPlanSetData.floors[index]
				})
			});
			set_items(_tempItems)
		}
		
		
		
		
		setDataFloor(state.newPlanSetData.floors)
		
		
	}, [state.newPlanSetData.floors]);
	
	useEffect(() => {
		let tempItems = []
		props._items.forEach((item, index) => {
			tempItems.push({
				...item,
				datafloorsState: state.newPlanSetData.floors[index]
			})
			
		});
		set_items(tempItems)
		
	}, [props._items]);
	
	
	const classNames = mergeStyleSets({
		container: {
			overflow: 'auto',
			maxHeight: 500,
			// width: 700
		},
		itemCell: [
			getFocusStyle(theme, { inset: -1 }),
			{
				minHeight: 54,
				padding: 10,
				boxSizing: 'border-box',
				borderBottom: `1px solid ${semanticColors.bodyDivider}`,
				display: 'flex',
				selectors: {
					'&:hover': { background: palette.neutralLight },
				},
			},
		],
		itemImage: {
			flexShrink: 0,
		},
		itemContent: {
			marginLeft: 10,
			overflow: 'hidden',
			flexGrow: 1,
		},
		itemName: [
			fonts.xLarge,
			{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
		],
		itemIndex: {
			fontSize: fonts.small.fontSize,
			color: palette.neutralTertiary,
			marginBottom: 10,
		},
		chevron: {
			alignSelf: 'center',
			marginLeft: 10,
			color: palette.neutralTertiary,
			fontSize: fonts.large.fontSize,
			flexShrink: 0,
		},
	});
	
	
	
	const _onRenderCell = (item, index, isScrolling) => {
		const _onClickAddWithData = () => {
			// props.onClickAdd(item)
		};
		
		const styles = {
			root: {
				width: '100px',
				// border: '1px solid #fffff'
			},
			spinButtonWrapper : {
				border: '1px solid #fff'
				
			},
			spinButtonWrapperHovered: {
				border: '1px solid rgb(138, 136, 134)'
				
			}
		};
		const _onChangeImageInput = (pic) => {
			// console.log('>>>>>>>>>', pic)
			props.onChangeImageInput(pic, index);
		}
		
		const _onDecrement = (value) => {
			// console.log('>>>>>>>>>>', value)
			dispatch({
				type:'GET_NEW_PLAN_LABEL_OF_ITEM_FLOORS',
				status: 'decrement',
				index
			})
			
		};
		
		const _onIncrement = (value) => {
			// setCountFloor(countFloor + 1);
			dispatch({
				type:'GET_NEW_PLAN_LABEL_OF_ITEM_FLOORS',
				status: 'increment',
				index
			})
			// console.log('>>>>>>>>>>2', value)
		};
		
		const onBlurSpin = (e) => {
			// setCountFloor(countFloor + 1);
			dispatch({
				type:'GET_NEW_PLAN_LABEL_OF_ITEM_FLOORS_BLUR',
				index,
				value: e.target.value
			})
			// console.log('>>>>>>>>>>2', value)
		};
		
		// console.log('>>>>>>>>>>2', item.datafloorsState)
		if (item.datafloorsState !== undefined) {
			return (
				<div className={classNames.itemCell}
					 onClick={_onClickAddWithData}
					 data-is-focusable={true}>
					<div className={classNames.itemContent}>
						{/*<div className={classNames.itemName}>{item.markerFloor + ' этаж'}</div>*/}
						<SpinButton
							styles={styles}
							// upArrowButtonStyles={upArrowButtonStyles}
							// downArrowButtonStyles={downArrowButtonStyles}
							value={item.datafloorsState.floor_label}
							label={'этаж:'}
							// min={-10}
							max={10}
							step={1}
							incrementButtonAriaLabel={'Increase value by 1'}
							decrementButtonAriaLabel={'Decrease value by 1'}
							onDecrement={_onDecrement}
							onIncrement={_onIncrement}
							onBlur={onBlurSpin}
						/>

						<div className={classNames.itemIndex}>{`Item ${index}`}</div>
					</div>
					<ImageUploader
						withIcon={true}
						buttonText="Choose images"
						onChange={_onChangeImageInput}
						imgExtension={[".jpg", ".gif", ".png", ".gif"]}
						maxFileSize={10000000}
						label={'Максимальный размер файла: 10mb, можно использовать: jpg, gif, png'}
						withPreview={true}
						singleImage={true}
						className={'ImageUploader'}
					/>
					{/*<input onChange={props.onChangeImageInput} type="file"/>*/}
					{/*<Image*/}
					{/*	className={classNames.itemImage}*/}
					{/*	src={item.image}*/}
					{/*	width={50}*/}
					{/*	height={50}*/}
					{/*	imageFit={ImageFit.cover}*/}
					{/*/>*/}

				</div>
			);
		}
		else {
			return null;
		}

	}
	
	
	
	return (
		<FocusZone direction={FocusZoneDirection.vertical}>
			<div className={classNames.container} data-is-scrollable={true}>
				<List items={_items} onRenderCell={_onRenderCell} />
			</div>
		</FocusZone>
	);
};

export default ListFloors;