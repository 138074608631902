import React, {useContext, useState, useEffect} from 'react';
// import ItemSubsections from "./item_subsections";
import GlobalDispatchContext from "../../../global_dispatch_context";
// import MainSelect from "../../react_select/main";
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import Select from 'react-select';

// import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import { initializeIcons } from '@uifabric/icons';
initializeIcons();

const SubSectionsBox = (props) => {

    const {state, dispatch} = useContext(GlobalDispatchContext);
    const [floor, setFloor] = useState(null);


    useEffect(() => {
        setFloor({
            label: +state.selectedFloor,
            value: +state.selectedFloor,
            key: +state.selectedFloor,
            text: +state.selectedFloor,
            isSelected:true
        });
    }, []);
    useEffect(() => {

        setFloor({
            label: +state.selectedFloor,
            value: +state.selectedFloor,
            key: +state.selectedFloor,
            text: +state.selectedFloor,
            isSelected:true
        });
    }, [
        state.selectedFloor,
        state.floorsDataForSelect
    ]);




    const onSelectSection = (e, item) => {
        // console.log('>>>>>>>>>>>>>>>>>', item.key)

        dispatch({
            type:'GET_SECTION_STATUS',
            status: item.key
        })
    };

    const onCheckSection = name => (e, isChacked) => {
        dispatch({
            type:'ON_CHECKED_SUBSECTION',
            keyName: name,
            status:isChacked
        })
    };


    let componentNodeSection = [];
    let componentCheck = [];
    state.sectionList.forEach((item, index) => {
        if (item.keyNameRu === 'Треки') {
            
            componentNodeSection.push(
                {
                    name: item.keyNameRu,
                    url: '',
                    key: item.name,
                    isExpanded: true,
                    disabled: (item.keyNameRu !== 'Масштаб' ? state.statusGoToGeomaping : false) ||
                        state.mapsStatusIntersectionPolygons || (state.dataTrackForDraw[state.selectedFloor] ? false : true)
                    || state.checkedPlacesInputCreate
                }
            );
            if (item.name === state.sectionStatus) {
                componentCheck.push(
                    <Checkbox
                        // onChange={onCheckSection}
                        disabled
                        checked={state.dataCkeckedSubsection[item.name].statusBackground}
                    />
                );
            }
            else {
                // const _onCheckSection = (e, isChacked) => {
                //     onCheckSection(e, isChacked, item.name)
                // };
        
        
                componentCheck.push(
                    <Checkbox
                        onChange={onCheckSection(item.name)}
                        disabled={(index !== 0 ? state.statusGoToGeomaping : false) || state.mapsStatusIntersectionPolygons || (state.dataTrackForDraw[state.selectedFloor] ? false : true)
                        || state.checkedPlacesInputCreate}
                        checked={state.dataCkeckedSubsection[item.name].statusBackground}
                    />
                );
            }
        }
        else {
            componentNodeSection.push(
                {
                    name: item.keyNameRu,
                    url: '',
                    key: item.name,
                    isExpanded: true,
                    disabled: (item.keyNameRu !== 'Масштаб' ? state.statusGoToGeomaping : false) ||
                        state.mapsStatusIntersectionPolygons || state.checkedPlacesInputCreate
                }
            );
            if (item.name === state.sectionStatus) {
                componentCheck.push(
                    <Checkbox
                        // onChange={onCheckSection}
                        disabled
                        checked={state.dataCkeckedSubsection[item.name].statusBackground}
                    />
                );
            }
            else {
                // const _onCheckSection = (e, isChacked) => {
                //     onCheckSection(e, isChacked, item.name)
                // };
        
        
                componentCheck.push(
                    <Checkbox
                        onChange={onCheckSection(item.name)}
                        disabled={(index !== 0 ? state.statusGoToGeomaping : false) || state.mapsStatusIntersectionPolygons
                        ||  state.checkedPlacesInputCreate}
                        checked={state.dataCkeckedSubsection[item.name].statusBackground}
                    />
                );
            }
        }
        
        
        

    });


    const stackTokens = { childrenGap: 24 };


    const onChangeFloor = (e, t, p) => {
        // console.log('>>>>>>>>>',e,  t)
        setFloor(e);
        dispatch({
            type:'ON_CHANGE_FLOOR',
            optionFloor: e,
        })
    };

    const stylesNav = {
        root: {
            width: 208,
            height: 310,
            boxSizing: 'border-box',
            // border: '1px solid #eee',
            overflowY: 'hidden'
        }
    };

    const groupNav = [
        {
            links: componentNodeSection
        },

    ];

    const stylesDropdown = {
        dropdown: {
            width: 180
        }
    };

    // console.log('>>>>>>>>>>>>', state.selectedFloor, state.floorsDataForSelect, floor)


    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
        </div>
    );

    return (
        <div className={'SubSectionsBox'}>

            <Nav
                onLinkClick={onSelectSection}
                selectedKey={state.sectionStatus}
                selectedAriaLabel="Selected"
                ariaLabel="Nav basic example"
                styles={stylesNav}
                groups={groupNav}
            />
            <div className="boxChecked">
                <Stack
                    tokens={stackTokens}
                >
                    {
                        componentCheck
                    }
                </Stack>
            </div>
            <div className="boxSelectFloor">
                <span>Этаж</span>
                <Stack tokens={stackTokens} verticalAlign="end">
                    {/*<Dropdown*/}
                    {/*    label="Этаж"*/}
                    {/*    defaultSelectedKey={floor ? floor.key : null}*/}
                    {/*    selectedKeys={floor ? floor.key : null}*/}
                    {/*    options={state.floorsDataForSelect}*/}
                    {/*    onChange={onChangeFloor}*/}
                    {/*    styles={stylesDropdown}*/}
                    {/*    required={true}*/}
                    {/*/>*/}

                    <Select
                        value={floor}
                        options={state.floorsDataForSelect}
                        formatGroupLabel={formatGroupLabel}
                        onChange={onChangeFloor}
                        isDisabled={state.mapsStatusIntersectionPolygons || state.checkedPlacesInputCreate}
                    />
                </Stack>

            </div>
        </div>
    );
};

export default SubSectionsBox;