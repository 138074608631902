const initialState = {
    statusSelectedObjects: false,
    modeDisplay: 'light',
    token:'',
    uuidSet:'',
    src: {
        dataPlanSet: null,
        initialDataPlanSetByFloor: null,
        remoteDataPlanSet: null,
        remoteDataPlanSetFloor: null,
        remoteTracks: null,
        dataTrack:null,
        dataTrackModifiedForDrow: null
    },
    dataPlanSetByFloor: {},
    sectionList:[
        {
            name:'scale',
            keyNameRu:'Масштаб',
            keyNameEn:'Scale',
            statusSelect:true,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'devices',
            keyNameRu:'Устройства',
            keyNameEn:'Devices',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'places',
            keyNameRu:'Помещения',
            keyNameEn:'Places',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'zones',
            keyNameRu:'Зоны',
            keyNameEn:'Zones',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'tracks',
            keyNameRu:'Треки',
            keyNameEn:'Tracks',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'polygons',
            keyNameRu:'Полигоны',
            keyNameEn:'Polygons',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        },
        {
            name:'background',
            keyNameRu:'Периметр',
            keyNameEn:'Background',
            statusSelect:false,
            subSectionList: [
                {
                    name:''
                }
            ],
            typeDraw:''
        }
    ],
    sectionStatus:'scale',
    floorsDataForSelect:[],
    selectedFloorDataForSelect: {},
    selectedFloor:1,
    dataTrackForDraw:{},
    tracksData:{},
    selectedGruopMarker:'',
    selectedNavigationGeometryType:'polygonIn',
    selectedScaleType:'segment',
    // defaul value = 'segment'

    dataBySelectedObjects: null,
    typeTrack: 'wifi',
    dataCkeckedSubsection: {
        scale: {
            statusBackground: false,
            data:{}
        },
        devices: {
            statusBackground: false,
            data:{}
        },
        places: {
            statusBackground: false,
            data:{}
        },
        zones: {
            statusBackground: false,
            data:{}
        },
        tracks: {
            statusBackground: false,
            data:{}
        },
        polygons: {
            statusBackground: false,
            data:{}
        },
        background: {
            statusBackground: false,
            data:{}
        }
    },
    indexSelectedObjects: null,
    coordsTemp: [],
    dataTrackSelected: null,
    objMarkersOfGroupZone: {},
    objMarkersByFloorOfGroupZone: {},
    statusGrid: true,
    stepGrid: 0.3,
    modeMaps:'noDo',
    statusGoToGeomaping: false,
    statusHaveScale: false,
    mapsStatusIntersectionPolygons: false,
    statusSelectObjects: false,
    backend: {},
    statusDefaultPage: 'listPlanSet',
    listPlanSets: [],
    statusConflict: false,
    statusPressSave: false,
    dataConflict: [],
    dataCompareModifiedStateInInitialState: [],
    newPlanSetData: {
        name:'',
        latitude:'',
        longitude:'',
        pl_id:null,
        floors:[
            {
                floor_label:1,
                file_name:''
            }
        ]
    },
    filesData: [{}],
    statusFocusBox: false,
    checkedPlacesInputCreate: false,
    arrayEntrances: [],
    valueMarkerEntrances: '',
    countPointEntrances: 0,
    objDataByMarker: {},
    statusAddPointsEntrances: false,
    statusEditPointsEntrances: false,
    objDataByMarkerForValidate: {},
    dataPlansetInfo: null,
    isShowMark: true
};

export default initialState;