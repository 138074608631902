class hostForLocation {
    static getLocationForHost(hostLoction, backend) {
        // console.log('getLocationForHost', hostLoction)
        // if (hostLoction === 'localhost:8080') {
        //     hostLoction = 'client.getshopster.net';
        // }
        hostLoction = 'getshopster.net';
        const exportHost = 'getshopster.net';
        // console.log('>>>>>>>>>>>', hostLoction.split('.'))
        // this['hostDev'] = {
        //     analytics: `https://analytics-service-dev.${hostLoction}`
        // };
        // this['hostProd'] = {
        //     analytics: `https://analytics-service.${hostLoction}`
        // };
        // 'https://maps.getshopster.net'
        this['host'] = {
            // analytics: `https://analytics-service.${hostLoction}`,
            admin: `https://admin.${hostLoction}`,
            maps: `https://maps.${hostLoction}`,
            // api: `https://api.${hostLoction}`,
            // export: `https://export.cloud.${exportHost}`,
        };

        if (backend !== null && backend.checks && Object.keys(backend).length !== 0) {
            // console.log(backend);
            Object.keys(backend.checks).forEach((key) => {
                if (backend.checks && backend.checks[key]) {
                    this['host'][key] = backend[key];
                }
            });

            // this['host'].analytics = `${BackendDevMode}`;
            
        }
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>!!!!!12', this['host'])
        

        // if (BackendDevMode !== undefined && BackendDevMode !== 'null') {
        //     this['host'].analytics = `${BackendDevMode}`;
        // }

        // this['hostDev'] = {analytics: `http://0.0.0.0:7068`}
        // console.log('getLocationForHost', hostLoction)
        // if (hostLoction.split('.')[0] === 'client-dev') {
        //
        //     this.host.analytics = this.hostDev.analytics
        // } else {
        //     this.host.analytics = this.hostProd.analytics
        // }
    }

    static getHostName() {
        return this.host;
    }
    // static getHostNameAdmin () {
    //     return this.host.analytics;
    // }
    // static getHostNameMaps () {
    //     return this.host.maps;
    // }
    // static getHostNameApi () {
    //     return this.host.api;
    // }
}

export default hostForLocation;
