import tools from "./tools";
import toolsForTopPanel from "./components/top_panel/tools";
var polygonsIntersect = require('polygons-intersect');
var randomColor = require('randomcolor');
// import cookie from "react-cookies";

const getStatusShowPanelDetail = (state, action) =>{
    
    return {
        ...state,
        statusSelectedObjects: action.status
    }
};

const getModeDisplay = (state, action) =>{
    return {
        ...state,
        modeDisplay: action.mode
    }
};

const getToken = (state, action) =>{
    return {
        ...state,
        token: action.token
    }
};

const getUuid = (state, action) =>{
    return {
        ...state,
        uuidSet: action.uuidSet
    }
};
const getPlanSet = (state, action) =>{
    let dataPlanSetByFloor = {};

    // console.log('>>>>>>>>>>>>>>>>>>>>>>', action.data.plans)
    let floorsDataForSelect = [];
    let minFloor = 1;
    
    if (action.data.plans.length > 0) {
        minFloor = action.data.plans[0].floor;
    }

    let selectedGruopMarker = '';

    let selectedScaleType = tools.copy(state.selectedScaleType);
    let objMarkersOfGroupZone = tools.copy(state.objMarkersOfGroupZone);
    let objMarkersByFloorOfGroupZone = tools.copy(state.objMarkersByFloorOfGroupZone);
    let statusGoToGeomaping = tools.copy(state.statusGoToGeomaping);
    let arrayEntrances = [];
    let statusHaveScale = state.statusHaveScale;
    let _statusHaveScale = true;
    // console.log('>>>>>>>>>>>>>>!', action.data);
    action.data.plans.forEach((item, index) => {
        // console.log('>>>>>>>>>>>>>>!', Object.keys(item.layers.geo_mapping_layer).length);
        if (Object.keys(item.layers.geo_mapping_layer).length === 0) {
            statusGoToGeomaping = true;
        }
        let ObjPlanSetsPlaces = {
            markers: [],
            coords:[],
            isOpeneds:[],
            entrances:[]
        };
        let objPlanSetsZones = {

        };
        let ObjPlanSetsPerimeter = {
            coords:[],
        };
        let ObjPlanSetsPolygonIn = {
            coords:[],
        };
        let ObjPlanSetsPolygonOut = {
            coords:[],
        };
        let ObjPlanSetsScalePolygon = {
            param: '',
            coords:[],
        };
        let ObjPlanSetsScaleSegment = {
            param: '',
            coords:[],
        };
        let objPlansetIPoint = {
            coords: [],
            isOpeneds: [],
            markers: [],
            types: []
        };
        for (var key in item.layers) {
            // console.log('>>>>>>>>>>>>>>1',item ,key);
            if (key === 'place_layer') {
                item.layers[key].places.forEach((itemPlaces, indexPlaces) => {
                    let arrayCoords = [];
                    let _tempArr = []
                    itemPlaces.coords.forEach((itemTemp, indexTemp) => {
                        arrayCoords.push(tools.returnCoordsToObj(itemTemp))
                        _tempArr.push(null);
                    });
                    if (itemPlaces.entrances === undefined) {
                        itemPlaces['entrances'] = _tempArr
                    }
    
                    
                    arrayEntrances.push(itemPlaces.entrances);
                    ObjPlanSetsPlaces.markers.push(itemPlaces.marker);
                    ObjPlanSetsPlaces.coords.push(arrayCoords);
                    ObjPlanSetsPlaces.isOpeneds.push(itemPlaces.is_opened);
                    ObjPlanSetsPlaces.entrances.push(itemPlaces.entrances);
                });
            }
            if (key === 'zone_layer') {
                objMarkersByFloorOfGroupZone[item.floor] = {};
                item.layers[key].groups.forEach((itemZones, indexZones) => {
                    if (indexZones === 0) {
                        selectedGruopMarker = tools.copy(itemZones.group_marker);
                    }
                    objPlanSetsZones[itemZones.group_marker] = {
                        markers: [],
                        coords:[],
                        names:[],
                        nameGroup: itemZones.group_name
                    };

                    itemZones.zones.forEach((itemTempZone, indexTempZone) => {
                        let arrayItemZOne = [];
                        itemTempZone.coords.forEach((itemTempMin, indexTempMin) => {
                            arrayItemZOne.push(tools.returnCoordsToObj(itemTempMin))
                        });
                        objPlanSetsZones[itemZones.group_marker].markers.push(itemTempZone.zone_marker);
                        objPlanSetsZones[itemZones.group_marker].coords.push(arrayItemZOne);
                        objPlanSetsZones[itemZones.group_marker].names.push(itemTempZone.zone_name);
                        
                    });
                    objMarkersOfGroupZone[itemZones.group_marker] = objPlanSetsZones[itemZones.group_marker];
                    objMarkersByFloorOfGroupZone[item.floor] = {
                        ...objMarkersByFloorOfGroupZone[item.floor],
                        name:itemZones.group_name,
                        [itemZones.group_marker]: itemZones.group_marker
                    };

                });
            }
            if (key === 'appearance_layer') {

                item.layers[key].perimeters.forEach((itemPerimeters, indexPerimeter) => {
                    let arrayItemPer = [];
                    itemPerimeters.forEach((itemTempPer, indexTempPer) => {
                        arrayItemPer.push(tools.returnCoordsToObj(itemTempPer));
                    });
                    ObjPlanSetsPerimeter.coords.push(arrayItemPer);

                });

            }
            if (key === 'navigation_geometry_layer') {
                item.layers[key].polygons_in.forEach((itemIn, indexIn) => {
                    let arrayItemIn = [];
                    itemIn.forEach((itemTempIn, indexTempIn) => {
                        arrayItemIn.push(tools.returnCoordsToObj(itemTempIn));
                    });
                    ObjPlanSetsPolygonIn.coords.push(arrayItemIn)
                });
                item.layers[key].polygons_out.forEach((itemOut, indexOut) => {
                    let arrayItemOut = [];
                    itemOut.forEach((itemTempOut, indexTempOut) => {
                        arrayItemOut.push(tools.returnCoordsToObj(itemTempOut));
                    });
                    ObjPlanSetsPolygonOut.coords.push(arrayItemOut)
                });
            }
            if (key === 'scale_layer') {
                // console.log('>>>>>>>>>>>>>>>>>>>', item.layers[key].polygons.length, item.layers[key].segments.length)
                if (item.layers[key].polygons.length > 0) {
                    selectedScaleType = 'polygon';
                    let arrayCoordsObj = []
                    item.layers[key].polygons[0].coords.forEach((itemPolygonScale, indexPolygonScale) =>{
                        arrayCoordsObj.push(tools.returnCoordsToObj(itemPolygonScale));
                    });
                    ObjPlanSetsScalePolygon = {
                        param: item.layers[key].polygons[0].area_m2,
                        coords:arrayCoordsObj,
                    }
                    if (_statusHaveScale) {
                        statusHaveScale = true;
                    }

                }

                if (item.layers[key].segments.length > 0) {
                    let arrayCoordsObjSegment = [];
                    selectedScaleType = 'segment';
                    item.layers[key].segments[0].coords.forEach((itemSegmentScale, indexSegmentScale) =>{

                        arrayCoordsObjSegment.push(tools.returnCoordsToObj(itemSegmentScale));
                    });
                    ObjPlanSetsScaleSegment = {
                        param: item.layers[key].segments[0].length_m,
                        coords:arrayCoordsObjSegment,
                    }
                    if (_statusHaveScale) {
                        statusHaveScale = true;
                    }
                }

                if (item.layers[key].segments.length === 0 && item.layers[key].polygons.length === 0) {
                    _statusHaveScale = false;
                    statusHaveScale = false;
                }

            }
            if (key === 'shopster_ipoint_layer') {

                item.layers[key].ipoints.forEach((itemPoint, indexPoint) => {
                    // console.log('>>>>>>>>>>>>>>', itemPoint);
                    objPlansetIPoint.coords.push(tools.returnCoordsToObj(itemPoint.coords));
                    objPlansetIPoint.isOpeneds.push(itemPoint.is_opened);
                    objPlansetIPoint.markers.push(itemPoint.marker);
                    objPlansetIPoint.types.push(itemPoint.type);
                })

                // objPlansetIPoint = {
                //     coords: [],
                //     is_openeds: [],
                //     markers: [],
                //     types: []
                // };
            }
        }

        floorsDataForSelect.push({
            label:item.floor,
            value:item.floor,
            key: item.floor,
            text: item.floor
        });

        if (minFloor > item.floor) {
            minFloor = item.floor;
        }
        // console.log('>>>>>>>>>>>>>>111111', item.scale);
        dataPlanSetByFloor[item.floor] = {
            ...item,
            places: ObjPlanSetsPlaces,
            zones: objPlanSetsZones,
            perimeter:ObjPlanSetsPerimeter,
            polygonIn: ObjPlanSetsPolygonIn,
            polygonOut: ObjPlanSetsPolygonOut,
            scalePolygon:ObjPlanSetsScalePolygon,
            scaleSegment:ObjPlanSetsScaleSegment,
            scale: {
                segment: ObjPlanSetsScaleSegment,
                polygon: ObjPlanSetsScalePolygon
            },
            scalePlanSet: item.scale,
            IPoint:objPlansetIPoint
        };
        // console.log('>>>>>>>>>>>>>>', dataPlanSetByFloor[item.floor]);
    });

    let selectedFloorDataForSelect = {
        value: minFloor,
        label: minFloor
    };
    return {
        ...state,
        src: {
            ...state.src,
            dataPlanSet: action.data,
            initialDataPlanSetByFloor: dataPlanSetByFloor,
            remoteDataPlanSet: action.data,
            remoteDataPlanSetFloor: dataPlanSetByFloor,
        },
        dataPlanSetByFloor:dataPlanSetByFloor,
        floorsDataForSelect:floorsDataForSelect,
        selectedFloorDataForSelect:selectedFloorDataForSelect,
        selectedGruopMarker:selectedGruopMarker,
        selectedScaleType:selectedScaleType,
        // dataBySelectedObjects: null,
        // statusSelectedObjects: false,
        objMarkersOfGroupZone:objMarkersOfGroupZone,
        objMarkersByFloorOfGroupZone,
        statusGoToGeomaping:statusGoToGeomaping,
        statusHaveScale: statusHaveScale,
        statusPressSave: false,
        statusConflict:false,
        arrayEntrances
    }
};

const getSectionStatus = (state, action) =>{

    let sectionList = tools.copy(state.sectionList);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    
    let dataCkeckedSubsection = {
        scale: {
            statusBackground: false,
            data:{}
        },
        devices: {
            statusBackground: false,
            data:{}
        },
        places: {
            statusBackground: false,
            data:{}
        },
        zones: {
            statusBackground: false,
            data:{}
        },
        tracks: {
            statusBackground: false,
            data:{}
        },
        polygons: {
            statusBackground: false,
            data:{}
        },
        background: {
            statusBackground: false,
            data:{}
        }
    };
    
    if (action.status === 'polygons') {
        dataCkeckedSubsection['polygons'] = {
            statusBackground: false,
            data: {
                polygonIn:dataPlanSetByFloor[state.selectedFloor].polygonIn,
                polygonOut: dataPlanSetByFloor[state.selectedFloor].polygonOut
            }
        }
    }

    sectionList.forEach((item, index) => {
        // console.log('>>>>>>>>>>>>', item, action.status);

        item.statusSelect = item.name === action.status;
    });

    return {
        ...state,
        sectionList: sectionList,
        sectionStatus: action.status,
        statusSelectedObjects: false,
        dataBySelectedObjects: null,
        dataCkeckedSubsection
    }
};


const getTrack = (state, action) =>{

    let dataTrackForDraw = tools.copy(action.data);
    let _dataTracksData = tools.copy(action.data)
    for (var key in action.data) {
        let arrayTracks = [];
        let objTrackTracking = {
            data: [],
            coords: []
        };
        let objTrackNavigation = {
            data: [],
            coords: []
        };
        let objTrackWifi = {
            data: [],
            coords: []
        };
        
        

        action.data[key].forEach((item, index) => {
            // console.log('>>>>>>>>>>>>>>>', item);
            let coordsArray = [];
            item.points.forEach((itemTemp, indexTemp) => {

                coordsArray.push(tools.returnCoordsToObj(itemTemp));
            });
    
            _dataTracksData[key][index]['react_global_index'] = index;
            // globalIndex:+lastKey + 1,

            if (item.track_type === 'tracking') {
                objTrackTracking.data.push(tools.copy(item));
                objTrackTracking.coords.push(coordsArray);
            }
            else if (item.track_type === 'navigation') {
                objTrackNavigation.data.push(tools.copy(item));
                objTrackNavigation.coords.push(coordsArray);
            }
            else if (item.track_type === 'wifi') {
                objTrackWifi.data.push(tools.copy(item));
                objTrackWifi.coords.push(coordsArray);
            }


            arrayTracks.push(coordsArray);
        });
        dataTrackForDraw[key] = {
            ...tools.copy(action.data[key]),
            coords:arrayTracks,
            data: {
                navigation: objTrackNavigation,
                tracking: objTrackTracking,
                wifi: objTrackWifi
            }
        }
        for (var _key in dataTrackForDraw[key]) {
            if (_key !== 'coords' && _key !== 'data') {
                dataTrackForDraw[key][_key]['react_global_index'] = +_key;
            }
        }
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>', action.data)



    }

    return {
        ...state,
        src: {
            ...state.src,
            dataTrack: action.data,
            dataTrackModifiedForDrow: dataTrackForDraw
        },
        dataTrackForDraw: dataTrackForDraw,
        tracksData:_dataTracksData,
        statusPressSave:false
    }
};

const getSubSectionStatus = (state, action) =>{

    let selectedGruopMarker = tools.copy(state.selectedGruopMarker);
    let selectedNavigationGeometryType = tools.copy(state.selectedNavigationGeometryType);
    let selectedScaleType = tools.copy(state.selectedScaleType);
    let typeTrack = tools.copy(state.typeTrack);

    if (state.sectionStatus === 'zones') {
        selectedGruopMarker = action.status;
    }
    else if (state.sectionStatus === 'polygons') {
        selectedNavigationGeometryType = action.status;
    }
    else if (state.sectionStatus === 'scale') {
        selectedScaleType = action.status;
    }
    else if (state.sectionStatus === 'tracks') {
        typeTrack = action.status;
    }

    return {
        ...state,
        selectedGruopMarker:selectedGruopMarker,
        selectedNavigationGeometryType:selectedNavigationGeometryType,
        selectedScaleType:selectedScaleType,
        typeTrack:typeTrack,
        statusSelectedObjects: false,
        dataBySelectedObjects: null
    }
};

const getIndexObjects = (state, action) =>{

    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataBySelectedObjects = tools.copy(state.dataBySelectedObjects);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let selectedFloor = tools.copy(state.selectedFloor);
    let index = tools.copy(action.index);
    let floorsDataForSelect = tools.copy(state.floorsDataForSelect);
    let objDataByMarker = {};
    
    // console.log('>>>>>>>>>>>>>>>>', action.item.floor)
    if (action.item !== undefined) {
        if (action.item.floor !== undefined) {
            selectedFloor = +action.item.floor;
            // console.log('>>>>>>>>>>>>>>>>', action.item.key)
            index = +action.item._key;
            floorsDataForSelect.forEach((item, index) => {
                // console.log('>>>>>>>>>>>>>>>>>>>>>', item);
                item['isSelected'] = false;
                if (item.value === selectedFloor){
                    item['isSelected'] = true;
                }
            });
            // console.log('>>>>>>>>>>>>', floorsDataForSelect);
            // if ()
        }
    }


    if (state.sectionStatus === 'devices') {

        dataBySelectedObjects = {
            ...dataPlanSetByFloor[selectedFloor].layers.shopster_ipoint_layer.ipoints[index],
            coords: dataPlanSetByFloor[selectedFloor].IPoint.coords[index],
            isOpeneds: dataPlanSetByFloor[selectedFloor].IPoint.isOpeneds[index],
            markers: dataPlanSetByFloor[selectedFloor].IPoint.markers[index],
            types: dataPlanSetByFloor[selectedFloor].IPoint.types[index],
            index: index
        }
    }
    else if (state.sectionStatus === 'background') {
        // console.log('>>>>>>>>>>>>',  action.index, dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index]);
        dataBySelectedObjects = {
            coords: dataPlanSetByFloor[selectedFloor].perimeter.coords[index],
            index: index
        }
    }
    else if (state.sectionStatus === 'places') {
        // console.log('<<<<<<<<<<<<<<<<<1', dataPlanSetByFloor[selectedFloor].layers.place_layer.places[index]);
        dataPlanSetByFloor[selectedFloor].layers.place_layer.places[index].entrances.forEach((itemPlaces, indexPlaces) => {
            // console.log('<<<<<<<<<<<<<<<<<1', objDataByMarker, itemPlaces);
            
            if (itemPlaces) {
                
                objDataByMarker[itemPlaces] = {
                    count: 0
                }
            }
        });
    
        Object.keys(objDataByMarker).forEach((item, index) => {
            
            objDataByMarker[item]['color'] = randomColor();
        })
        
        dataBySelectedObjects = {
            ...dataPlanSetByFloor[selectedFloor].layers.place_layer.places[index],
            index: index
        }
    }
    else if (state.sectionStatus === 'polygons') {
        dataBySelectedObjects = {
            coords: dataPlanSetByFloor[selectedFloor][state.selectedNavigationGeometryType].coords[index],
            index: index
        }
    }
    else if (state.sectionStatus === 'zones') {
    
        dataPlanSetByFloor[selectedFloor].layers.zone_layer.groups.forEach((item,_index) => {
        
            if (item.group_marker === state.selectedGruopMarker) {
                dataBySelectedObjects = {
                    ...item.zones[index],
                    index: index
                }
            }
        });
    }
    else if (state.sectionStatus === 'tracks') {

        dataBySelectedObjects = {
            ...dataTrackForDraw[selectedFloor].data[state.typeTrack].data[index],
            index: index
        }
    }
    else if (state.sectionStatus === 'scale') {
        // console.log('>>>>>>>>>>>>', action.index, dataPlanSetByFloor[state.selectedFloor].layers);
        dataBySelectedObjects = {
            ...dataPlanSetByFloor[selectedFloor].scale[state.selectedScaleType],
            statusParam: state.selectedScaleType === 'segment' ? 'length m' : 'area m2',
            index: index
        }
    }
    
    
    
    
    return {
        ...state,
        statusSelectedObjects: true,
        dataBySelectedObjects: dataBySelectedObjects,
        selectedFloor:selectedFloor,
        floorsDataForSelect:floorsDataForSelect,
        statusSelectObjects:true,
        objDataByMarker
    }
};

const getDataAfterDraw = (state, action) =>{
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let tracksData = tools.copy(state.tracksData);
    
    let coords = tools.copy(action.coords);
    let arrayEntrances = tools.copy(state.arrayEntrances);
    if (state.sectionStatus === 'devices') {
        dataPlanSetByFloor[state.selectedFloor].IPoint.coords.push(action.coords);
        dataPlanSetByFloor[state.selectedFloor].IPoint.isOpeneds.push(true);
        dataPlanSetByFloor[state.selectedFloor].IPoint.types.push(null);
        dataPlanSetByFloor[state.selectedFloor].IPoint.markers.push(
            `IP-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].IPoint.coords.length}`
        );
        dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints.push({
            coords: action.coords,
            is_opened: true,
            marker: `IP-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].IPoint.coords.length}`,
            type: null,
        })
    }
    else if (state.sectionStatus === 'scale') {
        
        // let coords = tools.copy(action.coords);
        
        // coords.pop();
        // console.log('>>>>>>>>>>>>>', coords,  coords.pop());
        // dataPlanSetByFloor[state.selectedFloor].layers.scale_layer[state.selectedScaleType === 'polygon' ? 'polygons' : 'segments'] = [];
        // dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType] = {
        //     param: "",
        //     coords: []
        // };
        
        
        
        
        dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = coords;
    }
    else if (state.sectionStatus === 'places') {
        // let coords = tools.copy(action.coords);
	    
	    let tempEntrances = []
	    
        console.log('!!@@!!@!@!@!!@!@1')
	    
        coords.pop();
	
	    coords.forEach((itemCoords, indexCoords) => {
		    tempEntrances.push(null);
	    })
	    
	    arrayEntrances.push(tempEntrances);
	    // console.log('>>>>>>>>>>>>>>>', coords, tempEntrances)
	    
        dataPlanSetByFloor[state.selectedFloor].places.coords.push(coords);
        dataPlanSetByFloor[state.selectedFloor].places.isOpeneds.push(true);
        dataPlanSetByFloor[state.selectedFloor].places.markers.push(
            `P-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].places.coords.length}`
        );
	    dataPlanSetByFloor[state.selectedFloor].places.entrances.push(
		    tempEntrances
	    );
        dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places.push({
            coords: coords,
            is_opened: true,
            marker: `P-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].places.coords.length}`,
	        entrances: tempEntrances
        });
	    
    }
    else if (state.sectionStatus === 'zones') {
        // let coords = tools.copy(action.coords);
        coords.pop();
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.push(coords);
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].names.push(
            `name-${dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.length}`
        );
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers.push(
            `Z-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.length}`
        );
        dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
            if (item.group_marker === state.selectedGruopMarker) {
                // console.log('>>>>>>>>>>>>>', coords, item.zones);
                item.zones.push({
                    coords: coords,
                    zone_marker: `Z-${state.selectedFloor}-${dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.length}`,
                    zone_name: `name-${dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.length}`,
                })
            }
        });
    }
    else if (state.sectionStatus === 'polygons') {
        // let coords = tools.copy(action.coords);
        coords.pop();
        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.push(coords);
        // console.log('>>>>>>>>>>>>>', action.coords, dataPlanSetByFloor[state.selectedFloor].layers.navigation_geometry_layer, state.selectedNavigationGeometryType);
    }
    else if (state.sectionStatus === 'background') {
        // let coords = tools.copy(action.coords);
        coords.pop();
        // console.log('>>>>>>>>>>>>>', action.coords, dataPlanSetByFloor[state.selectedFloor].perimeter.coords, coords);
        dataPlanSetByFloor[state.selectedFloor].perimeter.coords.push(coords);
    }
    else if (state.sectionStatus === 'tracks') {
        // let coords = tools.copy(action.coords);
        coords.pop();
        let lastKey = '-1';
        for(var key in dataTrackForDraw[state.selectedFloor]) {
            // console.log('>>>>>>>>>>>>>', key);
            if (key !== 'coords' && key !== 'data') {
                // console.log('>>>>>>>>>>>>>', key);
                lastKey = key;
            }
        }

        // console.log('>>>>>>>>>>>>>', lastKey);
        let duration_mask = [];
        coords.forEach((itemCoords, indexCoords) => {
            // console.log('>>>>>>>>>>>>>', itemCoords);
            duration_mask.push(1);
        });
        
        // console.log('>>>>>>>>>>>>>1', dataTrackForDraw[state.selectedFloor]);
        dataTrackForDraw[state.selectedFloor][+lastKey + 1] = {
            background_image: "",
            dt: "",
            dt_finish: null,
            dt_start: null,
            duration_mask: duration_mask,
            id: null,
            points: coords,
            steps: [],
            steps_duration_mask: [],
            track_type: state.typeTrack,
            react_global_index:+lastKey + 1,
            uuid: ""
        };
        
        // console.log('>>>>>>>>>>>>>>>>>>>>',processCoords(coords))
        tracksData[state.selectedFloor][+lastKey + 1] = {
            background_image: "",
            dt: "",
            dt_finish: null,
            dt_start: null,
            duration_mask: duration_mask,
            id: null,
            points: processCoords(coords),
            steps: [],
            steps_duration_mask: [],
            track_type: state.typeTrack,
            react_global_index:+lastKey + 1,
            uuid: ""
        };
        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords.push(coords);
        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data.push({
            background_image: "",
            dt: "",
            dt_finish: null,
            dt_start: null,
            duration_mask: duration_mask,
            id: null,
            points: coords,
            steps: [],
            steps_duration_mask: [],
            react_global_index:+lastKey + 1,
            track_type: state.typeTrack,
            uuid: ""
        });

        // console.log('>>>>>>>>>>>>>', coords,  dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data);
    }

    return {
        ...state,
        dataPlanSetByFloor,
        dataTrackForDraw,
        tracksData,
	    arrayEntrances

    }
};


const processCoords = (coords) => {
    let _coords = []
    // console.log('>>>>>>>>>>>>>>>', coords);
    coords.forEach((item, index) => {
        // console.log('>>>>>>>>>>>>>>>', item, item.x);
        _coords.push([item.x, item.y])
    });
    return _coords;
};



const saveTemp = (state, action) =>{

    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let countPointEntrances = 0;
    
    if (state.sectionStatus === 'devices') {
        dataPlanSetByFloor[state.selectedFloor].IPoint.isOpeneds[action.data.index] = action.data.checked;
        dataPlanSetByFloor[state.selectedFloor].IPoint.markers[action.data.index] = action.data.marker;
        dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints[action.data.index].is_opened = action.data.checked;
        dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints[action.data.index].marker = action.data.marker;
        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].IPoint.coords[state.indexSelectedObjects] = state.coordsTemp;
        }

    }
    else if (state.sectionStatus === 'places') {
        console.log('!!@@!!@!@!@!!@!@2')
        dataPlanSetByFloor[state.selectedFloor].places.isOpeneds[action.data.index] = action.data.checked;
        dataPlanSetByFloor[state.selectedFloor].places.markers[action.data.index] = action.data.marker;
        dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.data.index].is_opened = action.data.checked;
        dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.data.index].marker = action.data.marker;

        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].places.coords[state.indexSelectedObjects] = state.coordsTemp;
        }
    
        // let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
        // let arrayEntrances = tools.copy(state.arrayEntrances);
        //
        // dataPlanSetByFloor[state.selectedFloor].places.entrances = arrayEntrances;

    }
    else if (state.sectionStatus === 'scale') {
        // console.log('>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords, state.coordsTemp)
        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = state.coordsTemp;
        }
        dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].param = +action.data.marker;
        // dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = state.coordsTemp;



    }
    else if (state.sectionStatus === 'zones') {

        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.push(coords);
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].names[action.data.index] = action.data.name;
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers[action.data.index] = action.data.marker;
        dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
            if (item.group_marker === state.selectedGruopMarker) {
                // console.log('>>>>>>>>>>>>>', coords, item.zones);
                item.zones[action.data.index].zone_marker = action.data.marker;
                item.zones[action.data.index].zone_name = action.data.name;

            }
        });
        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[state.indexSelectedObjects] = state.coordsTemp;
        }

    }
    else if (state.sectionStatus === 'tracks') {
        
        if (state.coordsTemp.length > 0) {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[state.indexSelectedObjects] = state.coordsTemp;
        }

        if (action.dataTrack !== null) {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[state.indexSelectedObjects] = state.dataTrack;
        }

    }
    else if (state.sectionStatus === 'polygons') {


        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[state.indexSelectedObjects] = state.coordsTemp;
        }
        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'background') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index]);

        if (state.coordsTemp.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].perimeter.coords[state.indexSelectedObjects] = state.coordsTemp;
        }
    }
    // else if (state.sectionStatus === 'polygons') {
    //     let coords = tools.copy(action.coords);
    //     coords.pop();
    //     dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.push(coords);
    //     // console.log('>>>>>>>>>>>>>', action.coords, dataPlanSetByFloor[state.selectedFloor].layers.navigation_geometry_layer, state.selectedNavigationGeometryType);
    // }
    
    
    
    
    
    return {
        ...state,
        dataPlanSetByFloor,
        statusSelectedObjects: false,
        dataBySelectedObjects: null,
        statusSelectObjects: false,
        countPointEntrances,
        checkedPlacesInputCreate: false
    }
};


const onCheckedSubsection = (state, action) =>{

    let dataCkeckedSubsection = tools.copy(state.dataCkeckedSubsection);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    // console.log('>>>>>>>>>>>>', dataCkeckedSubsection, action.keyName, action.status);
    if (action.keyName === 'devices') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data:dataPlanSetByFloor[state.selectedFloor].IPoint
        }
    }
    else if (action.keyName === 'places') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: dataPlanSetByFloor[state.selectedFloor].places
        }
    }
    else if (action.keyName === 'zones') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: dataPlanSetByFloor[state.selectedFloor].zones
        }
    }
    else if (action.keyName === 'tracks') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: dataTrackForDraw[state.selectedFloor].data
        }
    }
    else if (action.keyName === 'polygons') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: {
                polygonIn:dataPlanSetByFloor[state.selectedFloor].polygonIn,
                polygonOut: dataPlanSetByFloor[state.selectedFloor].polygonOut
            }
        }
    }
    else if (action.keyName === 'background') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: dataPlanSetByFloor[state.selectedFloor].perimeter
        }
    }
    else if (action.keyName === 'scale') {
        dataCkeckedSubsection[action.keyName] = {
            statusBackground: action.status,
            data: dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType]
        }
    }

    return {
        ...state,
        dataCkeckedSubsection
    }
};


const trashObjects = (state, action) =>{

    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let tracksData = tools.copy(state.tracksData);
    let _tracksData = tools.copy(state.tracksData);;
    
    const getStatusPolyIntersection = ( arrayGlobalPoints) => {
        let statusIntersectionPolygons = false;
        arrayGlobalPoints.forEach((itemCoords, indexCoords) => {
            // polygonsIntersect
            arrayGlobalPoints.forEach((item, index) => {
                if (indexCoords !== index) {
                    if (polygonsIntersect(item, itemCoords).length > 0) {
                        statusIntersectionPolygons = true;
                    }
                }
                
            });
            // console.log('>>>>>>>>>>111111', polygonsIntersect(arrayCoordsForDraw, itemCoords))
            
        });
        return statusIntersectionPolygons;
    }
    let _statusIntersection = false;
    if (state.statusSelectedObjects) {
        // console.log('>>>>>>>>>>>', state.dataBySelectedObjects, state.sectionStatus);
        if (state.sectionStatus === 'devices') {
            // console.log('>>>>>>>>>>>', state.dataBySelectedObjects.index, dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints);
            dataPlanSetByFloor[state.selectedFloor].IPoint.coords.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].IPoint.isOpeneds.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].IPoint.markers.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].IPoint.types.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints.splice(state.dataBySelectedObjects.index, 1);
        }
        else if (state.sectionStatus === 'scale') {
            
            dataPlanSetByFloor[state.selectedFloor].layers.scale_layer[state.selectedScaleType === 'polygon' ? 'polygons' : 'segments'] = [];
            dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType] = {
                param: "",
                coords: []
            };
            // console.log('>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType]);
        }
        else if (state.sectionStatus === 'places') {
            // console.log('>>>>>>>>>>>', state.dataBySelectedObjects.index, dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places);
            dataPlanSetByFloor[state.selectedFloor].places.coords.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].places.isOpeneds.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].places.markers.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places.splice(state.dataBySelectedObjects.index, 1);
            _statusIntersection = getStatusPolyIntersection(dataPlanSetByFloor[state.selectedFloor].places.coords);
            // console.log('>>>>>>>>>>>>>>>>>.', _statusIntersection)
        }
        else if (state.sectionStatus === 'background') {
            // console.log('>>>>>>>>>>>', state.dataBySelectedObjects.index, dataPlanSetByFloor[state.selectedFloor].perimeter.coords);
            dataPlanSetByFloor[state.selectedFloor].perimeter.coords.splice(state.dataBySelectedObjects.index, 1);
            _statusIntersection = getStatusPolyIntersection(dataPlanSetByFloor[state.selectedFloor].perimeter.coords);
        }
        else if (state.sectionStatus === 'polygons') {
            // console.log('>>>>>>>>>>>', state.dataBySelectedObjects.index, dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords);
            dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.splice(state.dataBySelectedObjects.index, 1);
            _statusIntersection = getStatusPolyIntersection(dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords);
        }
        else if (state.sectionStatus === 'zones') {

            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].names.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers.splice(state.dataBySelectedObjects.index, 1);
            dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {

                if (item.group_marker === state.selectedGruopMarker) {
                    item.zones.splice(state.dataBySelectedObjects.index, 1);
                }
            });
            _statusIntersection = getStatusPolyIntersection(dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords);
        }
        else if (state.sectionStatus === 'tracks') {

            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data.splice(state.dataBySelectedObjects.index, 1);
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords.splice(state.dataBySelectedObjects.index, 1);

            // delete tracksData[state.selectedFloor][state.dataBySelectedObjects.globalIndex];
            // console.log('>>>>>>>!!!!!', tracksData[state.selectedFloor])
            _tracksData[state.selectedFloor] = [];
            tracksData[state.selectedFloor].forEach((itemTrack, indexTrack) => {
                // console.log('>>>>>>>>>>>>>>>>>', itemTrack.react_global_index, state.dataBySelectedObjects.react_global_index)
                if (itemTrack.react_global_index !== state.dataBySelectedObjects.react_global_index) {
                    _tracksData[state.selectedFloor].push(itemTrack);
                }
            });
            // for (var _key in tracksData[state.selectedFloor]) {
            //     if (+_key !== state.dataBySelectedObjects.globalIndex) {
            //         _tracksData[state.selectedFloor][_key] = tracksData[state.selectedFloor][_key];
            //     }
            // }
        }




        return {
            ...state,
            dataPlanSetByFloor,
            dataBySelectedObjects: null,
            statusSelectedObjects: false,
            dataTrackForDraw,
            tracksData:_tracksData,
            statusSelectObjects:false,
            mapsStatusIntersectionPolygons:_statusIntersection
        }

    }


    return {
        ...state,

    }
};



const getNewCoordsAfterMouseMove = (state, action) =>{


    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let tracksData = tools.copy(state.tracksData);


    if (state.sectionStatus === 'devices') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', action.coords, action.index);
        dataPlanSetByFloor[state.selectedFloor].IPoint.coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'places') {
        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'zones') {
        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'tracks') {
        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index] = action.coords;
        // console.log('>>>>>>>>>>>>>>>>', tracksData[state.selectedFloor], state.dataBySelectedObjects.react_global_index);
        if (action.dataTrack !== null) {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index] = action.dataTrack;
        }

        tracksData[state.selectedFloor].forEach((itemTrack, indexTrack) => {
            
            if (itemTrack.react_global_index === state.dataBySelectedObjects.react_global_index) {
                tracksData[state.selectedFloor][indexTrack] = {
                    ...dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index],
                    points: toolsForTopPanel.processCoords(dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index])
                };
                // console.log('>>>>>>>>>>>>>>>>>>>>>', tracksData[state.selectedFloor][indexTrack]);
            }
        });

        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'polygons') {

        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index] = action.coords;

        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'background') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index]);
        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'scale') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords);
        dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = action.coords;
    }

    return {
        ...state,
        dataPlanSetByFloor,
        dataTrackForDraw,
        tracksData
    }
};

const getDataTempMove = (state, action) =>{



    return {
        ...state,

    }
};

const clearSelectedData = (state, action) =>{
    
    let arrayEntrances = tools.copy(state.arrayEntrances);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let countPointEntrances = 0;
    
    
    let tempEntrances = [];
    // console.log('>>>>>>>>>>>>>!!!!!!!!!!!!',dataPlanSetByFloor[state.selectedFloor]);
    dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places.forEach((item, index) => {
        // console.log('>>>>>>>>>>>>>!!!!!!!!!!!!',item.entrances);
        tempEntrances.push(item.entrances);
    });
    arrayEntrances = tempEntrances;
    // console.log('>>>>>>>>>>>>>!!!!!!!!!!!!');
    
    return {
        ...state,
        dataBySelectedObjects: null,
        statusSelectedObjects: false,
        indexSelectedObjects: null,
        coordsTemp: [],
        dataTrackSelected: null,
        statusSelectObjects: false,
        arrayEntrances,
        countPointEntrances,
        checkedPlacesInputCreate: false
    }
};

const saveTempCoords = (state, action) =>{
    
    
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    
    if (state.sectionStatus === 'devices') {
        // dataPlanSetByFloor[state.selectedFloor].IPoint.isOpeneds[action.data.index] = action.data.checked;
        // dataPlanSetByFloor[state.selectedFloor].IPoint.markers[action.data.index] = action.data.marker;
        // dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints[action.data.index].is_opened = action.data.checked;
        // dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints[action.data.index].marker = action.data.marker;
        // console.log('>>>>>>>>>>>>>>>>>3', action.index, action.coords)
        dataPlanSetByFloor[state.selectedFloor].IPoint.coords[action.index] = action.coords;
        
    }
    else if (state.sectionStatus === 'places') {
        // dataPlanSetByFloor[state.selectedFloor].places.isOpeneds[action.data.index] = action.data.checked;
        // dataPlanSetByFloor[state.selectedFloor].places.markers[action.data.index] = action.data.marker;
        // dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.data.index].is_opened = action.data.checked;
        // dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.data.index].marker = action.data.marker;
        // console.log('!!@@!!@!@!@!!@!@4')
        if (action.coords.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].places.coords[action.index] = action.coords;
        }
        
    }
    else if (state.sectionStatus === 'scale') {
        // console.log('>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords, state.coordsTemp)
        if (action.coords.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = action.coords;
        }
        // dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].param = +action.data.marker;
        // dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords = state.coordsTemp;
        
        
        
    }
    else if (state.sectionStatus === 'zones') {
        
        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords.push(coords);
        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].names[action.data.index] = action.data.name;
        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers[action.data.index] = action.data.marker;
        // dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
        //     if (item.group_marker === state.selectedGruopMarker) {
        //         // console.log('>>>>>>>>>>>>>', coords, item.zones);
        //         item.zones[action.data.index].zone_marker = action.data.marker;
        //         item.zones[action.data.index].zone_name = action.data.name;
        //
        //     }
        // });
        if (action.coords.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
        }
        
    }
    else if (state.sectionStatus === 'tracks') {
        
        if (action.coords.length > 0) {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index] = action.coords;
        }
        
        if (action.dataTrack !== null) {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index] = action.dataTrack;
        }
        
        // state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[indexTracks]
        
    }
    else if (state.sectionStatus === 'polygons') {
        
        
        if (action.coords.length > 0) {
            dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index] = action.coords;
        }
        // dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index] = action.coords;
    }
    else if (state.sectionStatus === 'background') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index]);
        
        if (action.coords.length > 0) {
            dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index] = action.coords;
        }
    }
    // else if (state.sectionStatus === 'polygons') {
    //     let coords = tools.copy(action.coords);
    //     coords.pop();
    //     dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.push(coords);
    //     // console.log('>>>>>>>>>>>>>', action.coords, dataPlanSetByFloor[state.selectedFloor].layers.navigation_geometry_layer, state.selectedNavigationGeometryType);
    // }
    // return {
    //     ...state,
    //     dataPlanSetByFloor,
    //     statusSelectedObjects: false,
    //     dataBySelectedObjects: null,
    //     statusSelectObjects: false
    // }
    // console.log('>>>>>>>>>>>>>>>!!!!!!!!!!!!312', dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index]);
    return {
        ...state,
        dataPlanSetByFloor,
        indexSelectedObjects: action.index,
        coordsTemp: action.coords,
        dataTrackSelected: action.dataTrack,
        dataTrackForDraw
    }
};


const onChangeFloor = (state, action) =>{

    // console.log('>>>>>>>>>>>>>>>>>>>>>', action.optionFloor.value, state.selectedFloor);

    let floorsDataForSelect = tools.copy(state.floorsDataForSelect);
    let dataPlanSetByFloor =  tools.copy(state.dataPlanSetByFloor);
    let dataCkeckedSubsection =  tools.copy(state.dataCkeckedSubsection);
    let arrayEntrances = [];
    
    // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[action.optionFloor.value].places.entrances);
    arrayEntrances = dataPlanSetByFloor[action.optionFloor.value].places.entrances;
    
    floorsDataForSelect.forEach((item, index) => {
        
        item['isSelected'] = false;
        if (item.value === action.optionFloor.value){
            item['isSelected'] = true;
        }
    });
    
    if (state.sectionStatus === 'polygons') {
        dataCkeckedSubsection['polygons'] = {
            statusBackground: false,
            data: {
                polygonIn:dataPlanSetByFloor[action.optionFloor.value].polygonIn,
                polygonOut: dataPlanSetByFloor[action.optionFloor.value].polygonOut
            }
        }
    }
    
    return {
        ...state,
        selectedFloor: action.optionFloor.value,
        dataBySelectedObjects: null,
        statusSelectedObjects: false,
        indexSelectedObjects: null,
        coordsTemp: [],
        dataTrackSelected: null,
        floorsDataForSelect:floorsDataForSelect,
        arrayEntrances,
        dataCkeckedSubsection

    }
};

const createNewGroupZone = (state, action) =>{

    // console.log('>>>>>>>>>>>>>>>>>>>>>', action.optionFloor.value, state.selectedFloor);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let objMarkersOfGroupZone = tools.copy(state.objMarkersOfGroupZone);
    let objMarkersByFloorOfGroupZone = tools.copy(state.objMarkersByFloorOfGroupZone);
    // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups);
    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.push({
        zones: [],
        group_marker: action.marker,
        group_name: action.name
    });

    dataPlanSetByFloor[state.selectedFloor].zones[action.marker] = {
        markers: [],
        coords: [],
        names: [],
        nameGroup: action.name
    };
    objMarkersOfGroupZone[action.marker] = {
        markers: [],
        coords: [],
        names: [],
        nameGroup: action.name
    };
    objMarkersByFloorOfGroupZone[state.selectedFloor] = {
        ...objMarkersByFloorOfGroupZone[state.selectedFloor],
        [action.marker]: action.marker
    };


    return {
        ...state,
        dataPlanSetByFloor,
        objMarkersOfGroupZone,
        objMarkersByFloorOfGroupZone

    }
};

const deleteGroupZones = (state, action) =>{


    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let objMarkersOfGroupZone = tools.copy(state.objMarkersOfGroupZone);
    let selectedGruopMarker = tools.copy(state.selectedGruopMarker);
    let objMarkersByFloorOfGroupZone = tools.copy(state.objMarkersByFloorOfGroupZone);
    let indexDelete = 0;

    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
        if (item.group_marker === action.keyGroupZone) {
            indexDelete = index;
        }
    });

    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.splice(indexDelete, 1);
    delete dataPlanSetByFloor[state.selectedFloor].zones[action.keyGroupZone];
    delete objMarkersOfGroupZone[action.marker];
    delete objMarkersByFloorOfGroupZone[state.selectedFloor][action.marker];
    
    if (selectedGruopMarker === action.keyGroupZone) {
        if (dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.length === 0) {
            selectedGruopMarker = '';
        }
        else {
            selectedGruopMarker = dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups[0].group_marker;
        }
    }


    return {
        ...state,
        dataPlanSetByFloor,
        objMarkersOfGroupZone,
        selectedGruopMarker,
        objMarkersByFloorOfGroupZone

    }
};

const getGridToggle = (state, action) =>{


    return {
        ...state,
        statusGrid: action.status

    }
};

const getGridStep = (state, action) =>{


    return {
        ...state,
        stepGrid: action.step

    }
};


const getCookieSettings = (state, action) =>{

    return {
        ...state,
        statusGrid: action.objCookieForSettings.statusGrid === null ? state.statusGrid : action.objCookieForSettings.statusGrid === 'true' ? true: false,
        stepGrid: action.objCookieForSettings.stepGrid === null ? state.stepGrid : +action.objCookieForSettings.stepGrid,
        modeDisplay: action.objCookieForSettings.colorScheme === null ? state.modeDisplay : action.objCookieForSettings.colorScheme

    }
};

const getCoordsNewAfterPoint = (point, afterPoint) => {

    let x;
    let y;
    let tempDX;
    let tempDY;
    if (point.x > afterPoint.x) {
        tempDX = (point.x - afterPoint.x);
        x = point.x - tempDX / 3;
    }
    if (point.y > afterPoint.y) {
        tempDY = (point.y - afterPoint.y);
        y = point.y - tempDY / 3;

    }
    if (point.x < afterPoint.x) {
        tempDX = (afterPoint.x - point.x);
        x = point.x + tempDX / 3;
    }
    if (point.y < afterPoint.y) {
        tempDY = (afterPoint.y - point.y);
        y = point.y + tempDY / 3;

    }
    if (point.x === afterPoint.x) {
        x = point.x;
    }
    if (point.y === afterPoint.y) {
        y = point.y;
    }
    // console.log(
    //     '>>>>>>>>>>>>>>>>>>>>>',
    //     x,
    //     y
    // );

    return {
        x,
        y
    }
}

const getCoordsNewBeforePoint = (point, beforePoint) => {

    let x;
    let y;
    let tempDX;
    let tempDY;
    if (point.x > beforePoint.x) {
        tempDX = (point.x - beforePoint.x);
        x = point.x - tempDX / 3;
    }
    if (point.y > beforePoint.y) {
        tempDY = (point.y - beforePoint.y);
        y = point.y - tempDY / 3;

    }
    if (point.x < beforePoint.x) {
        tempDX = (beforePoint.x - point.x);
        x = point.x + tempDX / 3;
    }
    if (point.y < beforePoint.y) {
        tempDY = (beforePoint.y - point.y);
        y = point.y + tempDY / 3;

    }
    if (point.x === beforePoint.x) {
        x = point.x;
    }
    if (point.y === beforePoint.y) {
        y = point.y;
    }
    // console.log(
    //     '>>>>>>>>>>>>>>>>>>>>>',
    //     x,
    //     y
    // );

    return {
        x,
        y
    }
}


const onChangeContextMenu = (state, action) =>{

    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let dataTrackForDraw = tools.copy(state.dataTrackForDraw);
    let arrayEntrances = tools.copy(state.arrayEntrances);


    if (state.sectionStatus === 'places') {
        
        if (action.status === 'newPointAfter') {


            if (dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index + 1] !== undefined) {

                
                dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index + 1]
                    )
                );
    
                dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].splice(
                    action._index + 1,
                    0,
                    null
                );
    
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].coords.splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index + 1]
                    )
                )
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].entrances.splice(
                    action._index + 1,
                    0,
                    null
                )
	
	            arrayEntrances[action.index].splice(
		            action._index + 1,
		            0,
		            null
	            );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][0]
                    )
                );
    
                dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].splice(
                    0,
                    0,
                    null
                );
    
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].coords.splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][0]
                    )
                )
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].entrances.splice(
                    0,
                    0,
                    null
                )
                
                
	            arrayEntrances[action.index].splice(
		            0,
		            0,
		            null
	            );
            }

        }
        else if (action.status === 'newPointBefore') {
    
            
            if (action._index > 0) {

                dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index - 1]
                    )
                );
    
                dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].splice(
                    action._index,
                    0,
                    null
                );
    
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.index].coords.splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index - 1]
                    )
                )
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.index].entrances.splice(
                    action._index,
                    0,
                    null
                )
                
	            arrayEntrances[action.index].splice(
		            action._index,
		            0,
		            null
	            );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].push(
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].length - 1]
                    )
                );
                dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].push(
                    null
                );
	            arrayEntrances[action.index].push(
		            null
	            );
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].coords.push(
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].places.coords[action.index][dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].length - 1]
                    )
                )
                dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[action.index].entrances.push(
                    null
                )
            }
        }
        else if (action.status === 'deletePoint') {
            dataPlanSetByFloor[state.selectedFloor].places.coords[action.index].splice(
                action._index,
                1
            );
	        arrayEntrances[action.index].splice(
		        action._index,
		        1
	        );
            dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].splice(
                action._index,
                1
            );
            dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].coords.splice(
                action._index,
                1
            )
            dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places[[action.index]].entrances.splice(
                action._index,
                1
            )
        }
        
       
        // else if (action.status === 'addPointForEntrances') {
        //     // state.valueMarkerEntrances
        //     // let countPointEntrances = tools.copy(state.countPointEntrances);
        //     // countPointEntrances = countPointEntrances + 1;
        //     // // console.log('<<<<<<<<<<<<<<<<<', state.arrayEntrances, dataPlanSetByFloor[state.selectedFloor].places.entrances)
        //     // if ( countPointEntrances === dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index].length) {
        //     //     countPointEntrances = countPointEntrances - 1;
        //     //     alert('Все точки переиметра входят во вход, эта резметка неправильна. Надо что бы хотя бы одна точка переиметра не вхордила во вход')
        //     // }
        //     // else {
        //     //     // dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index][action._index] = state.valueMarkerEntrances;
        //     //     arrayEntrances[action.index][action._index] = state.valueMarkerEntrances;
        //     // }
        //
        // }
    }
    else if (state.sectionStatus === 'zones') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index], action._index, action.index, action.status);
        if (action.status === 'newPointAfter') {


            if (dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index + 1] !== undefined) {

                dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index + 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][0]
                    )
                );
            }

        }
        else if (action.status === 'newPointBefore') {
            if (action._index > 0) {

                dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index - 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].push(
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index][dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].length - 1]
                    )
                );
            }
        }
        else if (action.status === 'deletePoint') {
            dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].splice(
                action._index,
                1
            );
        }
    }
    else if (state.sectionStatus === 'tracks') {
        
        if (action.status === 'newPointAfter') {


            if (dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index + 1] !== undefined) {

                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index].splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index],
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index + 1]
                    )
                );
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index].duration_mask.splice(
                    action._index + 1,
                    0,
                    1
                )
            }
            else {
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index].splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index],
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][0]
                    )
                );
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index].duration_mask.splice(
                    0,
                    0,
                    1
                )
            }

        }
        else if (action.status === 'newPointBefore') {
            if (action._index > 0) {

                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index].splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index],
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index - 1]
                    )
                );
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index].duration_mask.splice(
                    action._index,
                    0,
                    1
                )
            }
            else {
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index].push(
                    getCoordsNewBeforePoint(
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][action._index],
                        dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].length - 1]
                    )
                );
                dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index].duration_mask.push(
                    1
                )
            }
        }
        else if (action.status === 'deletePoint') {
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index].splice(
                action._index,
                1
            );
            dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[action.index].duration_mask.splice(
                action._index,
                0,
                1
            )
        }
    }
    else if (state.sectionStatus === 'polygons') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index], action._index, action.index, action.status);
        if (action.status === 'newPointAfter') {


            if (dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index + 1] !== undefined) {

                dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index].splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index + 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index].splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][0]
                    )
                );
            }

        }
        else if (action.status === 'newPointBefore') {
            if (action._index > 0) {

                dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index].splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index - 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index].push(
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index][dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].coords[action.index].length - 1]
                    )
                );
            }
        }
        else if (action.status === 'deletePoint') {
            dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords[action.index].splice(
                action._index,
                1
            );
        }

    }
    else if (state.sectionStatus === 'background') {
        // console.log('>>>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index], action._index, action.index, action.status);
        if (action.status === 'newPointAfter') {


            if (dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index + 1] !== undefined) {

                dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].splice(
                    action._index + 1,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index + 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].splice(
                    0,
                    0,
                    getCoordsNewAfterPoint(
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][0]
                    )
                );
            }

        }
        else if (action.status === 'newPointBefore') {
            if (action._index > 0) {

                dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].splice(
                    action._index,
                    0,
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index - 1]
                    )
                );
            }
            else {
                dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].push(
                    getCoordsNewBeforePoint(
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][action._index],
                        dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index][dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].length - 1]
                    )
                );
            }
        }
        else if (action.status === 'deletePoint') {
            dataPlanSetByFloor[state.selectedFloor].perimeter.coords[action.index].splice(
                action._index,
                1
            );
        }
    }
    else if (state.sectionStatus === 'scale') {

    }
    
    console.log('!!@@!!@!@!@!!@!@5', dataPlanSetByFloor, arrayEntrances)

    return {
        ...state,
        dataPlanSetByFloor,
        dataTrackForDraw,
        // countPointEntrances,
        arrayEntrances

    }
};

const getModeMaps = (state, action) =>{
    // console.log('>>>>>>>>>>>>>>>>1', action.mode)
    return {
        ...state,
        modeMaps: action.mode

    }
};
const getMapsStatusIntersectionPolygons = (state, action) =>{
    // console.log('>>>>>>>>>>>>>>>>1', action.mode)
    return {
        ...state,
        mapsStatusIntersectionPolygons: action.status
        
    }
};


const fillBackend_EngineeringMenu = (state, action) => {
    const { backend } = action;
    return {
        ...state,
        backend
    };
};

const getStatusDefaultPage = (state, action) => {
    const { statusDefaultPage } = action;
    return {
        ...state,
        statusDefaultPage
    };
};

const getListPlanSets = (state, action) => {
    const { data } = action;
    // console.log('>>>>>>>>>>>>>>>>>>>', data)
    return {
        ...state,
        listPlanSets: data
    };
};

const getRemotePlanSet = (state, action) => {
    const { data, dataTracks } = action;

    let dataPlanSetByFloor = {};
    
    // console.log('>>>>>>>>>>>>>>>>>>>>>>', action)
    let floorsDataForSelect = [];
    let minFloor = 1;
    
    if (action.data.plans.length > 0) {
        minFloor = action.data.plans[0].floor;
    }
    
    let selectedGruopMarker = '';
    
    let selectedScaleType = tools.copy(state.selectedScaleType);
    let objMarkersOfGroupZone = tools.copy(state.objMarkersOfGroupZone);
    let objMarkersByFloorOfGroupZone = tools.copy(state.objMarkersByFloorOfGroupZone);
    let statusGoToGeomaping = tools.copy(state.statusGoToGeomaping);
    let statusHaveScale = state.statusHaveScale;
    let _statusHaveScale = true;
    let dataConflict = [];
    let dataCompareModifiedStateInInitialState = []
    // console.log('>>>>>>>>>>>>>>!', action.data);
    action.data.plans.forEach((item, index) => {
        // console.log('>>>>>>>>>>>>>>!', Object.keys(item.layers.geo_mapping_layer).length);
        
    
        let tempDataConflict = {};
    
        let tempDataCompareModifiedStateInInitialState = {};
        
        
        
        if (Object.keys(item.layers.geo_mapping_layer).length === 0) {
            statusGoToGeomaping = true;
        }
        let ObjPlanSetsPlaces = {
            markers: [],
            coords:[],
            isOpeneds:[]
        };
        let objPlanSetsZones = {
        
        };
        let ObjPlanSetsPerimeter = {
            coords:[],
        };
        let ObjPlanSetsPolygonIn = {
            coords:[],
        };
        let ObjPlanSetsPolygonOut = {
            coords:[],
        };
        let ObjPlanSetsScalePolygon = {
            param: '',
            coords:[],
        };
        let ObjPlanSetsScaleSegment = {
            param: '',
            coords:[],
        };
        let objPlansetIPoint = {
            coords: [],
            isOpeneds: [],
            markers: [],
            types: []
        };
        for (var key in item.layers) {
            // console.log('>>>>>>>>>>>>>>1', state.src.initialDataPlanSetByFloor[item.floor], state.dataPlanSetByFloor[item.floor]);
            if (key === 'place_layer') {
                // console.log('>>>>>>>>>>>>>>!', item.layers[key].places, state.src.dataPlanSet.plans[index].layers[key].places);


                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].places, state.dataPlanSetByFloor[item.floor].places),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key].places, state.src.dataPlanSet.plans[index].layers[key].places),
                    floor: item.floor
                }
                item.layers[key].places.forEach((itemPlaces, indexPlaces) => {
                    let arrayCoords = [];
                    itemPlaces.coords.forEach((itemTemp, indexTemp) => {
                        arrayCoords.push(tools.returnCoordsToObj(itemTemp))
                    });
                    ObjPlanSetsPlaces.markers.push(itemPlaces.marker);
                    ObjPlanSetsPlaces.coords.push(arrayCoords);
                    ObjPlanSetsPlaces.isOpeneds.push(itemPlaces.is_opened);
                });
            }
            if (key === 'zone_layer') {
                // console.log('>>>>>>>>>>>>>>!', item.layers[key].groups, state.src.dataPlanSet.plans[index].layers[key].groups);
                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].zones, state.dataPlanSetByFloor[item.floor].zones),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key].groups, state.src.dataPlanSet.plans[index].layers[key].groups),
                    floor: item.floor
                }
                item.layers[key].groups.forEach((itemZones, indexZones) => {
                    if (indexZones === 0) {
                        selectedGruopMarker = tools.copy(itemZones.group_marker);
                    }
                    objPlanSetsZones[itemZones.group_marker] = {
                        markers: [],
                        coords:[],
                        names:[],
                        nameGroup: itemZones.group_name
                    };
                    
                    itemZones.zones.forEach((itemTempZone, indexTempZone) => {
                        let arrayItemZOne = [];
                        itemTempZone.coords.forEach((itemTempMin, indexTempMin) => {
                            arrayItemZOne.push(tools.returnCoordsToObj(itemTempMin))
                        });
                        objPlanSetsZones[itemZones.group_marker].markers.push(itemTempZone.zone_marker);
                        objPlanSetsZones[itemZones.group_marker].coords.push(arrayItemZOne);
                        objPlanSetsZones[itemZones.group_marker].names.push(itemTempZone.zone_name);
                    });
                    objMarkersOfGroupZone[itemZones.group_marker] = objPlanSetsZones[itemZones.group_marker];
                    objMarkersByFloorOfGroupZone[itemZones.group_marker] = item.floor;
                    objMarkersByFloorOfGroupZone[item.floor] = {
                        ...objMarkersByFloorOfGroupZone[item.floor],
                        [itemZones.group_marker]: itemZones.group_marker
                    };
                    
                });
            }
            if (key === 'appearance_layer') {
                // console.log('>>>>>>>>>>>>>>!', item.layers[key].perimeters, state.src.dataPlanSet.plans[index].layers[key].perimeters);
                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].perimeter, state.dataPlanSetByFloor[item.floor].perimeter),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key].perimeters, state.src.dataPlanSet.plans[index].layers[key].perimeters),
                    floor: item.floor
                }
                item.layers[key].perimeters.forEach((itemPerimeters, indexPerimeter) => {
                    let arrayItemPer = [];
                    itemPerimeters.forEach((itemTempPer, indexTempPer) => {
                        arrayItemPer.push(tools.returnCoordsToObj(itemTempPer));
                    });
                    ObjPlanSetsPerimeter.coords.push(arrayItemPer);
                    
                });
                
            }
            if (key === 'navigation_geometry_layer') {
                // console.log('>>>>>>>>>>>>>>!', item.layers[key].polygons_in, state.src.dataPlanSet.plans[index].layers[key].polygons_in);
                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].polygonIn, state.dataPlanSetByFloor[item.floor].polygonIn) &&
                        tools.compare(state.src.initialDataPlanSetByFloor[item.floor].polygonOut, state.dataPlanSetByFloor[item.floor].polygonOut),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key], state.src.dataPlanSet.plans[index].layers[key]),
                    floor: item.floor
                }
                // console.log('>>>>>>>>>>>>>>!', item.layers[key].polygons_out, state.src.dataPlanSet.plans[index].layers[key].polygons_out);
               
                item.layers[key].polygons_in.forEach((itemIn, indexIn) => {
                    let arrayItemIn = [];
                    itemIn.forEach((itemTempIn, indexTempIn) => {
                        arrayItemIn.push(tools.returnCoordsToObj(itemTempIn));
                    });
                    ObjPlanSetsPolygonIn.coords.push(arrayItemIn)
                });
                item.layers[key].polygons_out.forEach((itemOut, indexOut) => {
                    let arrayItemOut = [];
                    itemOut.forEach((itemTempOut, indexTempOut) => {
                        arrayItemOut.push(tools.returnCoordsToObj(itemTempOut));
                    });
                    ObjPlanSetsPolygonOut.coords.push(arrayItemOut)
                });
            }
            if (key === 'scale_layer') {
                // console.log('>>>>>>>>>>>>>>>>>>>', item.layers[key].polygons.length, item.layers[key].segments.length)
                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].scale, state.dataPlanSetByFloor[item.floor].scale),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key], state.src.dataPlanSet.plans[index].layers[key]),
                    floor: item.floor
                    
                };
    
                
                if (item.layers[key].polygons.length > 0) {
                    selectedScaleType = 'polygon';
                    let arrayCoordsObj = []
                    item.layers[key].polygons[0].coords.forEach((itemPolygonScale, indexPolygonScale) =>{
                        arrayCoordsObj.push(tools.returnCoordsToObj(itemPolygonScale));
                    });
                    ObjPlanSetsScalePolygon = {
                        param: item.layers[key].polygons[0].area_m2,
                        coords:arrayCoordsObj,
                    }
                    if (_statusHaveScale) {
                        statusHaveScale = true;
                    }
                    
                }
                
                if (item.layers[key].segments.length > 0) {
                    let arrayCoordsObjSegment = [];
                    selectedScaleType = 'segment';
                    item.layers[key].segments[0].coords.forEach((itemSegmentScale, indexSegmentScale) =>{
                        
                        arrayCoordsObjSegment.push(tools.returnCoordsToObj(itemSegmentScale));
                    });
                    ObjPlanSetsScaleSegment = {
                        param: item.layers[key].segments[0].length_m,
                        coords:arrayCoordsObjSegment,
                    }
                    if (_statusHaveScale) {
                        statusHaveScale = true;
                    }
                }
                
                if (item.layers[key].segments.length === 0 && item.layers[key].polygons.length === 0) {
                    _statusHaveScale = false;
                    statusHaveScale = false;
                }
                
            }
            if (key === 'shopster_ipoint_layer') {
                tempDataCompareModifiedStateInInitialState[key] = {
                    statusConflict: tools.compare(state.src.initialDataPlanSetByFloor[item.floor].IPoint, state.dataPlanSetByFloor[item.floor].IPoint),
                    floor: item.floor
                }
                tempDataConflict[key] = {
                    statusConflict: tools.compare(item.layers[key].ipoints, state.src.dataPlanSet.plans[index].layers[key].ipoints),
                    floor: item.floor
                }
                item.layers[key].ipoints.forEach((itemPoint, indexPoint) => {
                    // console.log('>>>>>>>>>>>>>>', itemPoint);
                    objPlansetIPoint.coords.push(tools.returnCoordsToObj(itemPoint.coords));
                    objPlansetIPoint.isOpeneds.push(itemPoint.is_opened);
                    objPlansetIPoint.markers.push(itemPoint.marker);
                    objPlansetIPoint.types.push(itemPoint.type);
                })
                
                // objPlansetIPoint = {
                //     coords: [],
                //     is_openeds: [],
                //     markers: [],
                //     types: []
                // };
            }
    
            
        }
    
        // console.log('>>>>>>>>>>>>>>', state.src.dataTrackModifiedForDrow, state.dataTrackForDraw);
        if (state.src.dataTrackModifiedForDrow) {
            tempDataCompareModifiedStateInInitialState['tracks'] = {
                statusConflict: tools.compare(state.src.dataTrackModifiedForDrow[item.floor], state.dataTrackForDraw[item.floor]),
                floor: item.floor
            }
            tempDataConflict['tracks'] = {
                statusConflict: tools.compare(dataTracks[item.floor], state.src.dataTrack[item.floor]),
                floor: item.floor
            }
        }
        
        floorsDataForSelect.push({
            label:item.floor,
            value:item.floor,
            key: item.floor,
            text: item.floor
        });
        
        if (minFloor > item.floor) {
            minFloor = item.floor;
        }
        dataCompareModifiedStateInInitialState.push(tempDataCompareModifiedStateInInitialState);
        dataConflict.push(tempDataConflict);
        // console.log('>>>>>>>>>>>>>>111111', item.scale);
        dataPlanSetByFloor[item.floor] = {
            ...item,
            places: ObjPlanSetsPlaces,
            zones: objPlanSetsZones,
            perimeter:ObjPlanSetsPerimeter,
            polygonIn: ObjPlanSetsPolygonIn,
            polygonOut: ObjPlanSetsPolygonOut,
            scalePolygon:ObjPlanSetsScalePolygon,
            scaleSegment:ObjPlanSetsScaleSegment,
            scale: {
                segment: ObjPlanSetsScaleSegment,
                polygon: ObjPlanSetsScalePolygon
            },
            scalePlanSet: item.scale,
            IPoint:objPlansetIPoint,
            objMarkersByFloorOfGroupZone
        };
        
    });
    
    
    // console.log('>>>>>>>>>>>>>>1',tools.compare(data, state.src.dataPlanSet), tools.compare(dataTracks, state.src.dataTrack));
    // tools.compare(dataTracks, state.src.dataTrack)
    let statusConflict = false;
    // console.log('>>>>>>>>>>>>>>>122', data);

    if (tools.compare({
        ...data,
        updated: null
    }, {
        ...state.src.dataPlanSet,
        updated: null
    }) === false) {
        statusConflict = true;
    }
    if (tools.compare(dataTracks, state.src.dataTrack) === false) {
        statusConflict = true;
    }


    
    return {
        ...state,
        src: {
            ...state.src,
            remoteDataPlanSet: data,
            remoteDataPlanSetFloor: dataPlanSetByFloor,
            remoteTracks: dataTracks
        },
        statusConflict: statusConflict,
        dataConflict:dataConflict,
        dataCompareModifiedStateInInitialState:dataCompareModifiedStateInInitialState
        
    };
};

const getStatusPressSave = (state, action) => {
    const { status } = action;
    // console.log('>>>>>>>>>>>>>>>>>>>', data)
    return {
        ...state,
        statusPressSave: status
    };
};


const getNewPlanSetFloors = (state, action) => {
    const { countFloors } = action;
    let newPlanSetData = tools.copy(state.newPlanSetData);
    let filesData = tools.copy(state.filesData);
    let _filesData = [];
    // newPlanSetData = {};
    newPlanSetData['floors'] = [];
    
    for (var i = 0; i < countFloors; i++) {
        // console.log('>>>>>>>>>>>>>>>>>>>', i)
        newPlanSetData['floors'].push({
            floor_label: i + 1,
            file_name:''
        });
        if (filesData[i] !== undefined) {
            _filesData.push(filesData[i]);
        }
        else {
            _filesData.push({});
        }
    }
    
    // console.log('>>>>>>>>>>>>>>>>>>>', newPlanSetData)
    // console.log('>>>>>>>>>>>>>>>>>>>', data)
    return {
        ...state,
        newPlanSetData,
        filesData: _filesData
    };
};

const getNewPlanSetLabelOfItemFloors = (state, action) => {
    const { status, index } = action;
    let newPlanSetData = tools.copy(state.newPlanSetData);
    newPlanSetData.floors[index].floor_label = status === 'increment' ?
        +newPlanSetData.floors[index].floor_label + 1 : +newPlanSetData.floors[index].floor_label - 1;
    
    
    return {
        ...state,
        newPlanSetData
    };
};

const getNewPlanSetLabelOfItemFloorsBlur = (state, action) => {
    const { value, index } = action;
    let newPlanSetData = tools.copy(state.newPlanSetData);
    newPlanSetData.floors[index].floor_label = +value;
    
    
    return {
        ...state,
        newPlanSetData
    };
};


const getNewPlanSetImageOfFloors = (state, action) => {
    const { picture, index } = action;
    let newPlanSetData = tools.copy(state.newPlanSetData);
    let filesData = state.filesData
    let _filesData = [];
    // newPlanSetData = {};
    
    if (picture[0] === undefined) {
        // console.log('>>>>>>>>>>>>>>>>>>>3');
        newPlanSetData.floors[index].file_name = ''
        filesData[index] = {};
    }
    else {
        
        newPlanSetData.floors[index].file_name = picture[0].name
        filesData.forEach((item, index2) => {
            if (index2 === index) {
                _filesData.push(picture[0])
            }
            else {
                // console.log('>>>>>>>>>>>>>>>>>>>',item);
                _filesData.push(item)
            }
        });
        
        
        // filesData[index] = picture[0]
    }
    console.log('>>>>>>>>>>>>>>>>>>>2', _filesData);
    // console.log('>>>>>>>>>>>>>>>>>>>2', _filesData);
    // console.log('>>>>>>>>>>>>>>>>>>>', picture[0].name);
    
    // console.log('>>>>>>>>>>>>>>>>>>>', data)
    return {
        ...state,
        newPlanSetData,
        filesData:_filesData
    };
};




const getNewPlanSetDetailInfo = (state, action) => {
    const { status, data } = action;
    let newPlanSetData = tools.copy(state.newPlanSetData);
    // newPlanSetData = {};
    if (status === 'name') {
        newPlanSetData.name = data
    }
    if (status === 'coords') {
        newPlanSetData.latitude = data[0]
        newPlanSetData.longitude = data[1]
    }
    if (status === 'plId') {
        newPlanSetData.pl_id = data
    }
    
    
    // console.log('>>>>>>>>>>>>>>>>>>>', picture[0].name);
    
    // console.log('>>>>>>>>>>>>>>>>>>>', data)
    return {
        ...state,
        newPlanSetData
    };
};

const clearDataNewPlanSet = (state, action) => {
    
    return {
        ...state,
        newPlanSetData: {
            name:'',
            latitude:'',
            longitude:'',
            pl_id:null,
            floors:[
                {
                    floor_label:1,
                    file_name:''
                }
            ]
        },
        filesData: [{}]
    };
};

const getFocusBoxDerail = (state, action) => {
    return {
        ...state,
        statusFocusBox:true
    };
};

const getBlurBoxDerail = (state, action) => {
    return {
        ...state,
        statusFocusBox:false
    };
};


const onCheckPlacesInput = (state, action) => {
    const { checked } = action;
   
    
    
    return {
        ...state,
        checkedPlacesInputCreate: checked
    };
};

const getEntrances = (state, action) => {
    const { arrayEntrances } = action;
    
    // console.log('>><<<<<<<<<<<<<<<<', arrayEntrances)
    
    return {
        ...state,
        arrayEntrances
    };
};

const getValueMarkerEntrances = (state, action) => {
    const { value } = action;
    
    
    
    return {
        ...state,
        valueMarkerEntrances: value
    };
};

const getNewMarkerEntrances = (state, action) => {
    // const { value } = action;
    let objDataByMarker = tools.copy(state.objDataByMarker);
    // state.valueMarkerEntrances;
    if (objDataByMarker[state.valueMarkerEntrances] !== undefined) {
        alert('В этом помещении уже существует вход с таким маркером. Измените маркер');
        return {
            ...state,
        };
    }
    
    let statusUndefined = false;
    if (objDataByMarker[state.valueMarkerEntrances] === undefined) {
        statusUndefined = true;
    }
    objDataByMarker[state.valueMarkerEntrances] = {
        count: state.countPointEntrances
    }
    if (statusUndefined) {
        objDataByMarker[state.valueMarkerEntrances]['color'] = randomColor();
    }
    
    
    return {
        ...state,
        objDataByMarker,
        // valueMarkerEntrances: '',
        // countPointEntrances: 0,
        statusAddPointsEntrances: false,
        statusEditPointsEntrances:true
    };
};

const getNewPointEntrances = (state, action) => {
    const { _index, index } = action;
    
    let countPointEntrances = tools.copy(state.countPointEntrances);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let arrayEntrances = tools.copy(state.arrayEntrances);
    
    console.log('!!@@!!@!@!@!!@!@6')
    // console.log('<<<<<<<<<<<<<<<<<', state.arrayEntrances, dataPlanSetByFloor[state.selectedFloor].places.entrances)
    if ( countPointEntrances === dataPlanSetByFloor[state.selectedFloor].places.entrances[state.dataBySelectedObjects.index].length - 1) {
        countPointEntrances = countPointEntrances - 1;
        alert('Все точки переиметра входят во вход, эта резметка неправильна. Надо что бы хотя бы одна точка переиметра не вхордила во вход')
    }
    else {
        // dataPlanSetByFloor[state.selectedFloor].places.entrances[action.index][action._index] = state.valueMarkerEntrances;
        // console.log('<<<<<<<<<<<<<<<<<', countPointEntrances, arrayEntrances[state.dataBySelectedObjects.index][_index], arrayEntrances[state.dataBySelectedObjects.index][_index - 1],
        // arrayEntrances[state.dataBySelectedObjects.index][_index + 1]);
        if (countPointEntrances > 0) {
            if (_index === 0) {
                if (arrayEntrances[state.dataBySelectedObjects.index][_index + 1] !== null ||
                    arrayEntrances[state.dataBySelectedObjects.index][arrayEntrances[state.dataBySelectedObjects.index].length - 1] !== null) {
                    arrayEntrances[state.dataBySelectedObjects.index][_index] = state.valueMarkerEntrances;
                    countPointEntrances = countPointEntrances + 1;
                }
            }
            else if (_index === arrayEntrances[state.dataBySelectedObjects.index].length - 1) {
                if (arrayEntrances[state.dataBySelectedObjects.index][_index - 1] !== null ||
                    arrayEntrances[state.dataBySelectedObjects.index][0] !== null) {
                    arrayEntrances[state.dataBySelectedObjects.index][_index] = state.valueMarkerEntrances;
                    countPointEntrances = countPointEntrances + 1;
                }
            }
            else {
                if (arrayEntrances[state.dataBySelectedObjects.index][_index - 1] !== null ||
                    arrayEntrances[state.dataBySelectedObjects.index][_index + 1] !== null) {
                    arrayEntrances[state.dataBySelectedObjects.index][_index] = state.valueMarkerEntrances;
                    countPointEntrances = countPointEntrances + 1;
                }
            }
        }
        else if (countPointEntrances === 0) {
            arrayEntrances[state.dataBySelectedObjects.index][_index] = state.valueMarkerEntrances;
            countPointEntrances = countPointEntrances + 1;
        }
        
        
       
    }
    // console.log('<<<<<<<<<<<<<<<<<', arrayEntrances[state.dataBySelectedObjects.index])
    return {
        ...state,
        countPointEntrances,
        arrayEntrances
    };
};


const getStatusAddPointEntrances = (state, action) => {
    const { status } = action;
    
    // let objDataByMarker = tools.copy(state.objDataByMarker);
   
    // objDataByMarker[state.valueMarkerEntrances] = {
    //     count: state.countPointEntrances
    // }
    
    return {
        ...state,
        statusAddPointsEntrances: status
    };
};

const saveTempEntrances = (state, action) => {
    const { status } = action;
    
    // let objDataByMarker = tools.copy(state.objDataByMarker);
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    
    // objDataByMarker[state.valueMarkerEntrances] = {
    //     count: state.countPointEntrances
    // }
    console.log('!!@@!!@!@!@!!@!@7')
    
    let arrayEntrances = tools.copy(state.arrayEntrances);
    // console.log('$$###########', arrayEntrances)
    dataPlanSetByFloor[state.selectedFloor].places.entrances = arrayEntrances;
    
    return {
        ...state,
        valueMarkerEntrances: '',
        countPointEntrances: 0,
        // objDataByMarker,
        dataPlanSetByFloor,
        statusAddPointsEntrances: false,
        statusEditPointsEntrances: false
    };
};


const clearTempEntrances = (state, action) => {
    const { status } = action;
    
    // let objDataByMarker = tools.copy(state.objDataByMarker);
    console.log('!!@@!!@!@!@!!@!@8')
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let objDataByMarker = tools.copy(state.objDataByMarker)
    // objDataByMarker[state.valueMarkerEntrances] = {
    //     count: state.countPointEntrances
    // }
    
    let arrayEntrances = tools.copy(state.arrayEntrances);
    // console.log('>>>>>>>>>>>>>>>.', arrayEntrances[state.dataBySelectedObjects.index])
    arrayEntrances[state.dataBySelectedObjects.index].forEach((item, index) => {
        
        if (item === state.valueMarkerEntrances) {
            arrayEntrances[state.dataBySelectedObjects.index][index] = null;
        }
    });
    
    delete objDataByMarker[state.valueMarkerEntrances];
    // dataPlanSetByFloor[state.selectedFloor].places.entrances = arrayEntrances;
    // console.log('>>>>>>>>>>>>>>>.2', arrayEntrances[state.dataBySelectedObjects.index])
    return {
        ...state,
        valueMarkerEntrances: '',
        countPointEntrances: 0,
        // objDataByMarker,
        dataPlanSetByFloor,
        statusAddPointsEntrances: false,
        statusEditPointsEntrances: false,
        arrayEntrances,
        objDataByMarker
    };
};


const deleteLastObject = (state, action) => {
    // const { status } = action;
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    
    console.log('>>>>>>>>><<<<<<<<<', dataPlanSetByFloor[state.selectedFloor][state.sectionStatus])
    // let objDataByMarker = tools.copy(state.objDataByMarker);
    
    // objDataByMarker[state.valueMarkerEntrances] = {
    //     count: state.countPointEntrances
    // }
    
    return {
        ...state,
    };
};

const trashMarkerInput = (state, action) => {
    const { itemMarker } = action;
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let objDataByMarker = tools.copy(state.objDataByMarker);
    let arrayEntrances = tools.copy(state.arrayEntrances);
    
    
    // let objDataByMarker = tools.copy(state.objDataByMarker);
    dataPlanSetByFloor[state.selectedFloor].places.entrances[state.dataBySelectedObjects.index].forEach((item, index) => {
        
        if (item === itemMarker) {
            dataPlanSetByFloor[state.selectedFloor].places.entrances[state.dataBySelectedObjects.index][index] = null;
            arrayEntrances[state.dataBySelectedObjects.index][index] = null;
        }
    });
    
    // console.log('>>>>>>>>><<<<<<<<<', arrayEntrances[state.dataBySelectedObjects.index])
    delete objDataByMarker[itemMarker];
    // objDataByMarker[state.valueMarkerEntrances] = {
    //     count: state.countPointEntrances
    // }
    
    return {
        ...state,
        dataPlanSetByFloor,
        objDataByMarker,
        arrayEntrances
    };
};


const getPlansetImfo = (state, action) => {
    
    
    return {
        ...state,
        dataPlansetInfo: action.data
    };
};

const clearPlansetInfo = (state, action) => {
    
    
    return {
        ...state,
        dataPlansetInfo: null
    };
};


const getFloorAfterEditForPlanSetEdit = (state, action) => {
    
    const { index, value  } = action;
    let dataPlansetInfo = tools.copy(state.dataPlansetInfo);
    
    // console.log('><>>>>>>>>>>>>', index, value.itemFloor.floor, dataPlansetInfo.plans[index].floor);
    dataPlansetInfo.plans[index].floor = value.itemFloor.floor;
    return {
        ...state,
        dataPlansetInfo
    };
};





const addNewFloorForPlanSetedit = (state, action) => {
    
    const { value } = action;
    let dataPlansetInfo = tools.copy(state.dataPlansetInfo);
    
    // console.log('><>>>>>>>>>>>>', dataPlansetInfo.plans);
    let tempObj = {
        floor: value,
        geo_mapping: null,
        height: 724,
        image: null,
        layers: {
            appearance_layer: {perimeters: []},
            geo_mapping_layer: {},
            navigation_geometry_layer: {polygons_out: [], polygons_in: []},
            place_layer: {places: []},
            scale_layer: {segments: [], polygons: []},
            shopster_ipoint_layer: {ipoints: []},
            zone_layer: {groups: []}
        },
        scale: 11.5631756559347,
        uuid: null,
        width: 1024,
    };
    
    dataPlansetInfo.plans.push(tempObj);
    // dataPlansetInfo.plans[index].floor = value.itemFloor.floor;
    return {
        ...state,
        dataPlansetInfo
    };
};

const getFloorCopied = (state, action) => {
    
    const { value, indexSelectFloor } = action;
    let dataPlansetInfo = tools.copy(state.dataPlansetInfo);
    
    
    let tempObj = tools.copy(dataPlansetInfo.plans[indexSelectFloor]);
    let _index = 0;
    tempObj.floor = value.key;
    dataPlansetInfo.plans.forEach((item, index) => {
        console.log('><>>>>>>>>>>>>', value.key, item.floor);
        if (item.floor === value.key) {
            _index = index;
        }
    });
    dataPlansetInfo.plans[_index] = tempObj;
    console.log('><>>>>>>>>>>>>', dataPlansetInfo);
    // dataPlansetInfo.plans.push(tempObj);
    // dataPlansetInfo.plans[index].floor = value.itemFloor.floor;
    return {
        ...state,
        dataPlansetInfo
    };
};


const saveNewNameGroupZone = (state, action) =>{
    
    let {_selectedKeyState, nameGroupZones, markerGroupZones} = action;
    let dataPlanSetByFloor = tools.copy(state.dataPlanSetByFloor);
    let objMarkersOfGroupZone = tools.copy(state.objMarkersOfGroupZone);
    // let objMarkersByFloorOfGroupZone = tools.copy(state.objMarkersByFloorOfGroupZone);
    let selectedGruopMarker = tools.copy(state.selectedGruopMarker);
    let indexEdit = 0;
    // console.log('!<><>!>>>>>>>>>>', nameGroupZones);
    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
        if (item.group_marker === _selectedKeyState) {
            indexEdit = index;
        }
    });
    
    
    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups[indexEdit].group_name = nameGroupZones;
    dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups[indexEdit].group_marker = markerGroupZones;
    dataPlanSetByFloor[state.selectedFloor].zones[_selectedKeyState].nameGroup = nameGroupZones;
    objMarkersOfGroupZone[_selectedKeyState].nameGroup = nameGroupZones;
    if (_selectedKeyState !== markerGroupZones) {
        dataPlanSetByFloor[state.selectedFloor].zones[markerGroupZones] = tools.copy(dataPlanSetByFloor[state.selectedFloor].zones[_selectedKeyState]);
        delete dataPlanSetByFloor[state.selectedFloor].zones[_selectedKeyState];
        objMarkersOfGroupZone[markerGroupZones] = objMarkersOfGroupZone[_selectedKeyState];
        delete objMarkersOfGroupZone[_selectedKeyState];
    }
    
    // objMarkersByFloorOfGroupZone[_selectedKeyState].nameGroup = nameGroupZones;
    // console.log('!<><>!>>>>>>>>>>', nameGroupZones);
    if (selectedGruopMarker === action.keyGroupZone) {
        if (dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.length === 0) {
            selectedGruopMarker = '';
        }
        else {
            selectedGruopMarker = dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups[0].group_marker;
        }
    }
    
    
    return {
        ...state,
        dataPlanSetByFloor,
        objMarkersOfGroupZone,
        // selectedGruopMarker
        
    }
};

const getMarkStatusShow = (state, action) => {
    return {
        ...state,
        isShowMark: action.status
        // selectedGruopMarker

    }
}


function reducer(state, action) {
    switch (action.type) {

        case 'GET_STATUS_SHOW_PANEL_DETAIL':
            return getStatusShowPanelDetail(state, action);
        case 'GET_MODE_DISPLAY':
            return getModeDisplay(state, action);
        case 'GET_TOKEN':
            return getToken(state, action);
        case 'GET_UUID':
            return getUuid(state, action);
        case 'GET_PLAN_SET':
            return getPlanSet(state, action);
        case 'GET_TRACK':
            return getTrack(state, action);
        case 'GET_SECTION_STATUS':
            return getSectionStatus(state, action);
        case 'GET_SUB_SECTION_STATUS':
            return getSubSectionStatus(state, action);
        case 'GET_INDEX_OBJECTS':
            return getIndexObjects(state, action);
        case 'GET_DATA_AFTER_DRAW':
            return getDataAfterDraw(state, action);
        case 'SAVE_TEMP_DATA':
            return saveTemp(state, action);
        case 'ON_CHECKED_SUBSECTION':
            return onCheckedSubsection(state, action);
        case 'TRASH_OBJECTS':
            return trashObjects(state, action);
        case 'GET_NEW_COORDS_AFTER_MOUSE_MOVE':
            return getNewCoordsAfterMouseMove(state, action);
        case 'GET_DATA_TEMP_MOVE':
            return getDataTempMove(state, action);
        case 'CLEAR_SELECTED_DATA':
            return clearSelectedData(state, action);
        case 'SAVE_TEMP_COORDS':
            return saveTempCoords(state, action);
        case 'ON_CHANGE_FLOOR':
            return onChangeFloor(state, action);
        case 'CREATE_NEW_GROUP_ZONE':
            return createNewGroupZone(state, action);
        case 'DELETE_GROUP_ZONES':
            return deleteGroupZones(state, action);
        case 'GET_GRID_TOGGLE':
            return getGridToggle(state, action);
        case 'GET_GRID_STEP':
            return getGridStep(state, action);
        case 'GET_COOKIE_SETTINGS':
            return getCookieSettings(state, action);
        case 'ON_CHANGE_CONTEXT_MENU':
            return onChangeContextMenu(state, action);
        case 'GET_MODE_MAPS':
            return getModeMaps(state, action);
        case 'GET_MAPS_STATUS_INTERSECTION_POLYGONS':
            return getMapsStatusIntersectionPolygons(state, action);
        case 'FILL_BACKEND_ENGINEERING':
            return fillBackend_EngineeringMenu(state, action);
        case 'GET_STATUS_DEFAULT_PAGE':
            return getStatusDefaultPage(state, action);
        case 'GET_LIST_PLAN_SETS':
            return getListPlanSets(state, action);
        case 'GET_REMOTE_PLAN_SET':
            return getRemotePlanSet(state, action);
        case 'GET_STATUS_PRESS_SAVE':
            return getStatusPressSave(state, action);
        case 'GET_NEW_PLAN_SET_FLOORS':
            return getNewPlanSetFloors(state, action);
        case 'GET_NEW_PLAN_LABEL_OF_ITEM_FLOORS':
            return getNewPlanSetLabelOfItemFloors(state, action);
        case 'GET_NEW_PLAN_IMAGE_OF_FLOORS':
            return getNewPlanSetImageOfFloors(state, action);
        case 'GET_NEW_PLAN_DETAIL_INFO':
            return getNewPlanSetDetailInfo(state, action);
        case 'CLEAR_NEW_PLAN_SET':
            return clearDataNewPlanSet(state, action);
        case 'GET_NEW_PLAN_LABEL_OF_ITEM_FLOORS_BLUR':
            return getNewPlanSetLabelOfItemFloorsBlur(state, action);
        case 'FOCUS_DETAIL_BOX':
            return getFocusBoxDerail(state, action);
        case 'BLUR_DETAIL_BOX':
            return getBlurBoxDerail(state, action);
        case 'ON_CHECK_PLACES_INPUT':
            return onCheckPlacesInput(state, action);
        case 'GET_ENTRANCES':
            return getEntrances(state, action);
        case 'ON_CHANGE_MARKER_ENTRANCES':
            return getValueMarkerEntrances(state, action);
        case 'GET_NEW_MARKER_ENTRANCES':
            return getNewMarkerEntrances(state, action);
        case 'GET_NEW_POINT_ENTRANCES':
            return getNewPointEntrances(state, action);
        case 'GET_STATUS_ADD_POINTS':
            return getStatusAddPointEntrances(state, action);
        case 'SAVE_TEMP_ENTRANCES':
            return saveTempEntrances(state, action);
        case 'CLEAR_TEMP_ENTRANCES':
            return clearTempEntrances(state, action);
        case 'DELETE_LAST_OBJECT':
            return deleteLastObject(state, action);
        case 'TRASH_MARKER_INPUT':
            return trashMarkerInput(state, action);
        case 'GET_PLAN_SET_INFO':
            return getPlansetImfo(state, action);
        case 'CLEAR_PLAN_SET_INFO':
            return clearPlansetInfo(state, action);
        case 'GET_FLOOR_AFTER_EDIT':
            return getFloorAfterEditForPlanSetEdit(state, action);
        case 'ADD_NEW_FLOOR':
            return addNewFloorForPlanSetedit(state, action);
        case 'GET_FLOOR_COPIED':
            return getFloorCopied(state, action);
        case 'SAVE_NEW_NAME_GROUP_ZONE':
            return saveNewNameGroupZone(state, action);
        case 'GET_MARK_TOGGLE':
            return getMarkStatusShow(state, action);
        default:
            throw new Error();
    }
};


export default reducer;