import cloneDeep from 'lodash/cloneDeep';

const tools = {
    copy: (state) => {
        // console.log('>>>>>>>>>>>>>>', state)
        if (state === undefined) {
            return state;
        }
        return JSON.parse(JSON.stringify(state));
        // if (state === undefined) {
        //     return state;
        // }
        // return cloneDeep(state);
        
    },
    returnCoordsToObj: (coords) => {
        return{
            x:coords[0],
            y:coords[1]
        }
    },
    correctScaleMapsContainer: (widthPlan, heightPlan) => {
        const objWindow = {
            height:window.innerHeight - 50,
            width:window.innerWidth - 206
        }
        var scaleCreate = 0;
        if ((widthPlan / heightPlan) > (objWindow.width / objWindow.height)) {
            scaleCreate = objWindow.width / widthPlan * 0.9;
        }
        else if ((widthPlan / heightPlan) <= (objWindow.width / objWindow.height)) {
            scaleCreate =  objWindow.height / heightPlan * 0.9;
        }
        // console.log('>>>>>>>>->>>>>>>>', scaleCreate)
        return scaleCreate;
    },
    correctText: (text, widthBox) => {
        let countWord = widthBox / 10;
        // console.log('>>>>>>>>>>>>>>>>>11111', text, text.substr(0, countWord / 2 - 1) + '...' + text.substring(text.length - (countWord / 2 - 1), text.length), widthBox / 10)
        let newText = text.substr(0, countWord / 2 - 1) + '...' + text.substring(text.length - (countWord / 2 - 1), text.length)
        return newText;
    },
    compare: (state, state2) => {
        return JSON.stringify(state) === JSON.stringify(state2);
    }

};

export default tools;