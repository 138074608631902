const toolsLeftPanel = {
	createListObjects: (dataPlanSetByFloor, sectionStatus, selectedKey, selectedNavigationGeometryType, selectedGruopMarker, selectedScaleType, modeMaps,
	                    dataTrackForDraw,
	                    typeTrack, mapsStatusIntersectionPolygons) => {
		let componentsList = [];
		// console.log('>>>>>>>>>>>>>>>>>>>');
		for (let key in dataPlanSetByFloor) {
			// console.log('>>>>>>>>>>>>>>>>>>>', dataPlanSetByFloor[key]);
			if (sectionStatus === 'devices') {
				dataPlanSetByFloor[key].layers.shopster_ipoint_layer.ipoints.forEach((item, index) => {

					componentsList.push(
						{
							name: item.marker,
							url: '',
							key: index + '-' + key,
							_key: index,
							isExpanded: true,
							selectedKey: selectedKey === index,
							is_opened:item.is_opened,
							floor: key,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);


				})
			}
			else if (sectionStatus === 'background') {
				// console.log('>>>>>>>>>>>>>>>',  state.dataPlanSetByFloor[state.selectedFloor].perimeter.coords);
				dataPlanSetByFloor[key].perimeter.coords.forEach((item, index) => {

					componentsList.push(
						{
							name: index,
							url: '',
							key: index + '-' + key,
							_key: index,
							isExpanded: true,
							selectedKey: selectedKey === index,
							floor: key,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);


				})
			}
			else if (sectionStatus === 'places') {

				dataPlanSetByFloor[key].layers.place_layer.places.forEach((item, index) => {
					componentsList.push(
						{
							name: item.marker,
							url: '',
							key: index + '-' + key,
							_key: index,
							isExpanded: true,
							selectedKey: selectedKey === index,
							is_opened: item.is_opened,
							floor: key,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);

				})
			}
			else if (sectionStatus === 'polygons') {


				dataPlanSetByFloor[key][selectedNavigationGeometryType].coords.forEach((item, index) => {
					componentsList.push(
						{
							name: index,
							url: '',
							key: index + '-' + key,
							_key: index,
							isExpanded: true,
							selectedKey: selectedKey === index,
							floor: key,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);

				})
			}
			else if (sectionStatus === 'zones') {
				if (dataPlanSetByFloor[key].zones[selectedGruopMarker] !== undefined) {
					dataPlanSetByFloor[key].zones[selectedGruopMarker].markers.forEach((item, index) => {
						// console.log('<<<<<<<<<<<<<<<<<,', state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers[index])
						componentsList.push(
							{
								name: dataPlanSetByFloor[key].zones[selectedGruopMarker].names[index],
								url: '',
								key: index + '-' + key,
								_key: index,
								isExpanded: true,
								selectedKey: selectedKey === index,
								marker: dataPlanSetByFloor[key].zones[selectedGruopMarker].markers[index],
								floor: key,
								modeMaps:modeMaps,
								disabled:mapsStatusIntersectionPolygons
							}
						);

					})
				}

			}
			else if (sectionStatus === 'scale') {
				if (dataPlanSetByFloor[key].scale[selectedScaleType].coords.length > 0 &&
					dataPlanSetByFloor[key].scale[selectedScaleType].coords !== undefined) {
					componentsList.push(
						{
							name: dataPlanSetByFloor[key].scale[selectedScaleType].param,
							url: '',
							key: 0 + '-' + key,
							_key: 0,
							isExpanded: true,
							selectedKey: selectedKey === 0,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);

				}

			}
		}
		// console.log('>>>>>>>>>>>>>>>>>>>', dataTrackForDraw);
		if (sectionStatus === 'tracks') {

			for (var key in dataTrackForDraw) {
				dataTrackForDraw[key].data[typeTrack].data.forEach((item, index) => {
					// console.log('>>>>>>>>>>>>>>>>>', item.id);
					componentsList.push(
						{
							name: item.id,
							url: '',
							key: index + '-' + key,
							isExpanded: true,
							selectedKey: selectedKey === index,
							floor: key,
							modeMaps:modeMaps,
							disabled:mapsStatusIntersectionPolygons
						}
					);

				});
			}

		}
		return componentsList;
	}

};

export default toolsLeftPanel;