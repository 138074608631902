import React from 'react';
import './style/main_maps_style.less';
import Maps from "./components/maps";

const MainMaps = (props) => {

    return (
        <>
            <Maps
                getStatusContextMenu={props.getStatusContextMenu}
            />
        </>
    );
};

export default MainMaps;