import React, {useState, useContext, useEffect } from 'react';
import SvgBox from "./svg_box";
import GlobalDispatchContext from "../../../global_dispatch_context";
import styled from 'styled-components';
import tools from "../../../tools";
import SvgBoxDrow from "./svg_box_drow";

import { ContextualMenu, ContextualMenuItemType} from 'office-ui-fabric-react/lib/ContextualMenu';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import {getTheme} from 'office-ui-fabric-react/lib/Styling';
import {Stack} from "office-ui-fabric-react/lib/Stack";
import {

    Modal,
    IconButton,
    mergeStyleSets,
    FontWeights, PrimaryButton,

} from 'office-ui-fabric-react';
import { FontSizes } from '@uifabric/styling';
var polygonsIntersect = require('polygons-intersect');
// import {TextField} from "office-ui-fabric-react/lib/TextField";

const theme = getTheme();
// const { fonts } = theme;


const correctCoords = (coords, elemCoord) => {
    return ({x: coords.x - elemCoord.x,  y: coords.y - elemCoord.y})
};

const sectionStatusByNameLayer = {
    scale:'scalePolygon, scaleSegment',
    devices:'IPoint',
    places:'places',
    zones:'zones',
    tracks:'dataTrackForDraw',
    polygons:'polygonIn, polygonOut',
    background:'perimeter'
};

const MapsContainer = styled.div`

  width: ${props => props.box.width * props.scale}px;
  height: ${props => props.box.height * props.scale}px;
  background: ${props => props.imageBack === '' ? null : `url(https://maps.getshopster.net/${props.imageBack})`} no-repeat;
  background-size: contain;
 
`;

const SCBoxContextMenu = styled.div`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
`;


const Maps = (props) => {

    const [coords, getCoords] = useState({x:0,y:0});
    const [arrayCoords, getArrayCoords] = useState([]);
    const [endCoords, getEndCoords] = useState({x:0,y:0});
    const [statusCreatedPolygon, getStatusCreatedPolygon] = useState(false);
    const [arrayPlanCoords, getArrayPlanCoords] = useState([]);
    const [scale, getScale] = useState(0);
    const [imageBack, getImageBack] = useState('');
    const {state, dispatch} = useContext(GlobalDispatchContext);
    const [dataNamesForMaps, geDataNamesForMaps] = useState({
        markers:[],
        names:[],
        isOpeneds:[]
    });
    const [statusMouseDown, getStatusMouseDown] = useState(false);
    const [indexObjectAfterMouseDown, getIndexObjectAfterMouseDown] = useState(null);
    const [arrayCoordsForDraw, getArrayCoordsForDraw] = useState([]);

    const [arrayTrackData, getArrayTrackData] = useState([]);
    const [arrayTrackDataForDraw, getArrayTrackDataForDraw] = useState(null);
    const [objIndexPointsTrack, getObjIndexPointsTrack] = useState(null);
    const [coordsContextMenu, getCoordsContextMenu] = useState(null);
    const [scaleGrid, setScaleGrid] = useState(0.5);
    const [gridObj, setGridObj] = useState(null);
    const [modeMap, setModeMap] = useState('noDo');
    const [statusGoToGeomaping, setStatusGoToGeomaping] = useState(false);
    const [statusShowModalInfoIntersection, setStatusShowModalInfoIntersection] = useState(false);
    const [arrayEntrances, getArrayEntrances] = useState([]);
    const [_arrayEntrances, getArrayEntrancesTemp] = useState([]);
    const [arrayEntrancesForDraw, getArrayEntrancesForDraw] = useState(null);


    useEffect(() => {

        setStatusGoToGeomaping(state.statusGoToGeomaping)
    }, [
        state.statusGoToGeomaping,
    ]);
    
    useEffect(() => {
    
        setStatusShowModalInfoIntersection(state.mapsStatusIntersectionPolygons)
    }, [
        state.mapsStatusIntersectionPolygons,
    ]);

    useEffect(() => {
        let _arrayCoords = tools.copy(arrayCoords);
        let _arrayPlanCoords = tools.copy(arrayPlanCoords);
        _arrayCoords = [];
        _arrayPlanCoords[_arrayPlanCoords.length - 1] = _arrayCoords;
        getArrayCoords(_arrayCoords);
        getArrayPlanCoords(_arrayPlanCoords);
        getStatusCreatedPolygon(false);
    }, [
        state.sectionStatus,
        // arrayCoords,
        // arrayPlanCoords
    ]);

    useEffect(() => {
        if (state.dataPlanSetByFloor[state.selectedFloor] !== undefined) {
            getImageBack(state.dataPlanSetByFloor[state.selectedFloor].image);
            if (sectionStatusByNameLayer[state.sectionStatus] === 'scalePolygon, scaleSegment') {


                if (state.dataPlanSetByFloor[state.selectedFloor][state.sectionStatus][state.selectedScaleType].coords === undefined) {
                    getArrayPlanCoords([]);
                    geDataNamesForMaps({
                        markers:[],
                        names:[],
                        isOpeneds:[]
                    })
                }
                else {
                    getArrayPlanCoords([state.dataPlanSetByFloor[state.selectedFloor][state.sectionStatus][state.selectedScaleType].coords]);
                    geDataNamesForMaps({
                        markers:[],
                        names:[state.dataPlanSetByFloor[state.selectedFloor][state.sectionStatus][state.selectedScaleType].param],
                        isOpeneds:[]
                    })
                }

            }
            else if (sectionStatusByNameLayer[state.sectionStatus] === 'zones') {

                if (state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker] !== undefined) {
                    getArrayPlanCoords(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].coords);
                    geDataNamesForMaps({
                        names:state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].names,
                        markers: state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].markers
                    })
                }
                else {
                    getArrayPlanCoords([]);
                    geDataNamesForMaps({
                        names:[],
                        markers: []
                    })
                }

            }
            else if (sectionStatusByNameLayer[state.sectionStatus] === 'polygonIn, polygonOut') {
                getArrayPlanCoords(state.dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords);
                geDataNamesForMaps({
                    markers:[],
                    names:[],
                    isOpeneds:[]
                })
            }
            else if (sectionStatusByNameLayer[state.sectionStatus] !== 'dataTrackForDraw') {
                if (state.dataBySelectedObjects === null) {
                    getArrayPlanCoords(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].coords);
                    // console.log('>>>>>>>>>>>>>>>>', state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].coords)
                    geDataNamesForMaps({
                        markers: state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].markers,
                        isOpeneds: state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].isOpeneds
                    })
                    if (state.sectionStatus === 'places') {
        
                        getArrayEntrances(state.arrayEntrances);
                    }
                }
                
            }

        }
        if (state.dataTrackForDraw[state.selectedFloor] !== undefined) {
            if (sectionStatusByNameLayer[state.sectionStatus] === 'dataTrackForDraw') {
                getArrayPlanCoords(state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords);
                getArrayTrackData(state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data);
            }
        }
    }, [
        state.dataPlanSetByFloor,
        state.dataTrackForDraw,
        state.selectedFloor,
        state.sectionStatus,
        state.selectedGruopMarker,
        state.selectedNavigationGeometryType,
        state.selectedScaleType,
        state.typeTrack,
        state.arrayEntrances
    ]);
    useEffect(() => {


        if(state.statusGrid) {
            if (state.dataPlanSetByFloor[state.selectedFloor] !== undefined)  {
                setGridObj({

                    countX: state.dataPlanSetByFloor[state.selectedFloor].width / (state.dataPlanSetByFloor[state.selectedFloor].scalePlanSet * 0.5),
                    countY: state.dataPlanSetByFloor[state.selectedFloor].height / (state.dataPlanSetByFloor[state.selectedFloor].scalePlanSet * 0.5),
                    scale: state.dataPlanSetByFloor[state.selectedFloor].scalePlanSet
                });
            }

        }
        else {
            setGridObj(null);
        }
    }, [
        state.statusGrid,
        state.dataPlanSetByFloor
    ]);


    useEffect(() => {

        document.onkeydown = onKeyPress

    }, [
        arrayCoords,
        arrayCoordsForDraw,
        arrayPlanCoords,
        state.statusFocusBox
    ]);

    useEffect(() => {

        setScaleGrid(state.stepGrid);
    }, [
        state.stepGrid
    ]);
    
    const onKeyPress = (e) => {
        // console.log('>>>>>>>>>>>>>>>>>.', e.key);
        let _arrayCoords = tools.copy(arrayCoords);
        let _arrayPlanCoords = tools.copy(arrayPlanCoords);
        if (e.key === 'Backspace') {
            // console.log()
            if (statusCreatedPolygon) {
                if (!state.statusFocusBox) {
                    _arrayCoords.splice(_arrayCoords.length - 1, 1);
                    _arrayPlanCoords[_arrayPlanCoords.length - 1] = _arrayCoords
                    
                    // console.log('>>>>>>>>>>', _arrayCoords)
                    if (_arrayCoords.length === 1) {
            
                        _arrayCoords = [];
                        _arrayPlanCoords.splice(_arrayPlanCoords.length - 1, 1)
                        dispatch({
                            type:'CLEAR_SELECTED_DATA'
                        });
                        getStatusCreatedPolygon(false);
            
                    }
                    // console.log('>>>>>>>>>>', _arrayPlanCoords)
                }
            }
            
            
        }
        else if (e.key === 'Escape') {
            if (statusCreatedPolygon) {
                _arrayCoords = [];
                _arrayPlanCoords[_arrayPlanCoords.length - 1] = _arrayCoords;
                getStatusCreatedPolygon(false);
            }
            
            // dispatch({
            //     type:'DELETE_LAST_OBJECT'
            // });
        }
        else if (e.key === 'Enter') {
            if (state.sectionStatus === 'tracks') {
                if (_arrayCoords.length > 2) {
                    _arrayCoords.splice(_arrayCoords.length - 1, 1);
                    _arrayPlanCoords[_arrayPlanCoords.length - 1] = _arrayCoords
                    dispatch({
                        type:'GET_DATA_AFTER_DRAW',
                        coords: _arrayCoords
                    });
                    getStatusCreatedPolygon(false);
                    getArrayCoords([])
                    getArrayPlanCoords(_arrayPlanCoords);

                    return;
                }
            }
            else {
                if (_arrayCoords.length > 3) {
                    _arrayCoords.splice(_arrayCoords.length - 1, 1);
                    _arrayPlanCoords[_arrayPlanCoords.length - 1] = _arrayCoords
                    dispatch({
                        type:'GET_DATA_AFTER_DRAW',
                        coords: _arrayCoords
                    });
                    getStatusCreatedPolygon(false);
                    getArrayCoords([])
                    getArrayPlanCoords(_arrayPlanCoords);

                    return;
                }
            }
        }

        getArrayCoords(_arrayCoords);
        getArrayPlanCoords(_arrayPlanCoords);
    };

    // console.log('>>>>>>>>>>>>>>>>>.1111', arrayCoords);

    useEffect(() => {

        if (state.dataPlanSetByFloor[state.selectedFloor] !== undefined) {
            getScale(tools.correctScaleMapsContainer(state.dataPlanSetByFloor[state.selectedFloor].width, state.dataPlanSetByFloor[state.selectedFloor].height))
        }
    }, [
        state.selectedFloor,
        state.src.dataPlanSet,
        // state.dataPlanSetByFloor
    ]);
    

    useEffect(() => {

        if (state.dataBySelectedObjects !== null) {
            let _array = [];
            let _objNames = {
                markers:[],
                names:[],
                isOpeneds:[]
            };
            // console.log('!!!!!!!!!!!', arrayPlanCoords);
            if (state.sectionStatus === 'devices') {

                state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].coords.forEach((itemIPoint, indexIpoint) => {
                    if (indexIpoint !== +state.dataBySelectedObjects.index) {
                        _array.push(itemIPoint);
                        _objNames.isOpeneds.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].isOpeneds[indexIpoint]);
                        _objNames.markers.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].markers[indexIpoint]);

                    }
                    else {
                        getArrayCoordsForDraw([itemIPoint])
                    }
                });

                geDataNamesForMaps(_objNames);
            }
            else if (state.sectionStatus === 'scale') {
                [state.dataPlanSetByFloor[state.selectedFloor][state.sectionStatus][state.selectedScaleType].coords].forEach((itemScale, indexScale) => {
                    if (indexScale !== +state.dataBySelectedObjects.index) {
                        _array.push(itemScale);

                    }
                    else {
                        getArrayCoordsForDraw(itemScale)
                    }

                });
            }
            else if (state.sectionStatus === 'places') {
                
                // getArrayEntrances(state.arrayEntrances);
                let _arrayEntrances = []
                state.dataPlanSetByFloor[state.selectedFloor].places.coords.forEach((itemPlaces, indexPlaces) => {
                    if (indexPlaces !== +state.dataBySelectedObjects.index) {
                        _array.push(itemPlaces);
                        // console.log('!!!!!!!!!!!', state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]]);
                        _objNames.isOpeneds.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].isOpeneds[indexPlaces]);
                        _objNames.markers.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].markers[indexPlaces]);
                        _arrayEntrances.push(state.arrayEntrances[indexPlaces]);
                    }
                    else if (indexPlaces === +state.dataBySelectedObjects.index) {
                        
                        getArrayCoordsForDraw(itemPlaces)
                        getArrayEntrancesForDraw(state.arrayEntrances[indexPlaces])
                    }

                });
                // console.log('<<<<<<<<<<<<<<<<<2', state.arrayEntrances)
                // console.log('!!!!!!!!!!!1', _array.length);
                getArrayEntrances(_arrayEntrances);
                geDataNamesForMaps(_objNames);
            }
            else if (state.sectionStatus === 'zones') {
                // console.log('!!!!!!!!!!!', state.dataBySelectedObjects, arrayPlanCoords);
                state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].coords.forEach((itemZones, indexZones) => {
                    if (indexZones !== +state.dataBySelectedObjects.index) {
                        _array.push(itemZones);
                        // console.log('!!!!!!!!!!!', state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker]);
                        _objNames.names.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].names[indexZones]);
                        _objNames.markers.push(state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]][state.selectedGruopMarker].markers[indexZones]);
                    }
                    else {
                        getArrayCoordsForDraw(itemZones);
                    }

                });

                geDataNamesForMaps(_objNames);
            }
            else if (state.sectionStatus === 'tracks') {
                let arrayDataTrack = [];
                state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords.forEach((itemTracks, indexTracks) => {
                    // console.log('!!!!!!!!!!!', state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[indexTracks]);
                    if (indexTracks !== +state.dataBySelectedObjects.index) {
                        _array.push(itemTracks);
                        arrayDataTrack.push(state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[indexTracks]);
                    }
                    else {
                        getArrayCoordsForDraw(itemTracks);
                        getArrayTrackDataForDraw(state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data[indexTracks])
                    }

                });
                getArrayTrackData(arrayDataTrack);
            }
            else if (state.sectionStatus === 'polygons') {
                // console.log('!!!!!!!!!!!', state.dataBySelectedObjects, arrayPlanCoords);
                state.dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.forEach((itemPoly, indexPoly) => {
                    // console.log('!!!!!!!!!!!', itemPoly);
                    if (indexPoly !== +state.dataBySelectedObjects.index) {
                        _array.push(itemPoly);
                    }
                    else {
                        getArrayCoordsForDraw(itemPoly);
                    }

                });
            }
            else if (state.sectionStatus === 'background') {
                // console.log('!!!!!!!!!!!', state.dataBySelectedObjects, arrayPlanCoords);
                state.dataPlanSetByFloor[state.selectedFloor][sectionStatusByNameLayer[state.sectionStatus]].coords.forEach((itemPoly, indexPoly) => {
                    // console.log('!!!!!!!!!!!', itemPoly);
                    if (indexPoly !== +state.dataBySelectedObjects.index) {
                        _array.push(itemPoly);
                    }
                    else {
                        getArrayCoordsForDraw(itemPoly);
                    }

                });
            }
            getArrayPlanCoords(_array);
        }
        else {
            getArrayCoordsForDraw([]);
        }
        
    
        

    }, [
        state.dataBySelectedObjects,
        state.sectionStatus,
        state.dataPlanSetByFloor,
        state.dataTrackForDraw,
        state.selectedGruopMarker,
        state.arrayEntrances
    ]);
    // if (state.dataTrackForDraw[state.selectedFloor] !== undefined) {
    //     console.log('>>>>>>>>>>>>>>>!!!!!!!!!!!!4', state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data)
    // }
    
    // console.log('!!!!!!!!!!!5555',arrayPlanCoords);

    const getCoordWithGrid = (arrayCoords, planSetScale, status, scaleGlobal, scaleGrid) => {
        return netRoundCoordinates(arrayCoords, planSetScale,status, scaleGlobal, scaleGrid);
    };
    
    const getStatusPolyIntersection = (arrayCoordsForDraw, arrayGlobalPoints) => {
        let statusIntersectionPolygons = false;
        arrayGlobalPoints.forEach((itemCoords, indexCoords) => {
            // polygonsIntersect

            // console.log('>>>>>>>>>>111111', polygonsIntersect(arrayCoordsForDraw, itemCoords))
            if (polygonsIntersect(arrayCoordsForDraw, itemCoords).length > 0) {
                statusIntersectionPolygons = true;
            }
        });
        return statusIntersectionPolygons;
    }

    const mouseMove = (e) => {
        if (modeMap !== 'noDo') {
            if (!state.checkedPlacesInputCreate) {
                if (statusMouseDown) {
                    let arrayGlobalPoints = tools.copy(arrayPlanCoords);
                    // getStatusMouseMove(true);
        
        
                    let coords = correctCoords({x: e.pageX, y: e.pageY}, document.getElementById('MapsContainer').getBoundingClientRect());
                    if (state.sectionStatus === 'devices') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[0] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            // dispatch({
                            //     type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                            //     status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            // });
                
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
                        else {
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: coords.x / scale,
                                y: coords.y / scale
                            };
                            // dispatch({
                            //     type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                            //     status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            // });
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
            
                        return;
                    }
                    else if (state.sectionStatus === 'places') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
                        else {
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: coords.x / scale,
                                y: coords.y / scale
                            };
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
            
                        return;
                    }
                    else if (state.sectionStatus === 'zones') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                
                        }
                        else {
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: coords.x / scale,
                                y: coords.y / scale
                            };
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
            
                        return;
                    }
                    else if (state.sectionStatus === 'scale') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                
                            return;
                        }
                        _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                            x: coords.x / scale,
                            y: coords.y / scale
                        };
                        getArrayCoordsForDraw(_arrayCoordsForDraw);
                        return;
                    }
                    else if (state.sectionStatus === 'tracks') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            // console.log('>>>>>>>>>>>>>>>>>.', _arrayCoordsForDraw, arrayPlanCoords, state.dataBySelectedObjects.index)
                
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
                        else {
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: coords.x / scale,
                                y: coords.y / scale
                            };
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                        }
            
                        return;
                    }
        
                    else if (state.sectionStatus === 'polygons') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
            
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                            return;
                        }
                        _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                            x: coords.x / scale,
                            y: coords.y / scale
                        };
                        dispatch({
                            type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                            status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                        });
                        getArrayCoordsForDraw(_arrayCoordsForDraw);
                        return;
                    }
                    else if (state.sectionStatus === 'background') {
                        let _arrayCoordsForDraw = tools.copy(arrayCoordsForDraw);
                        if (gridObj !== null) {
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                            _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            };
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                            });
                            getArrayCoordsForDraw(_arrayCoordsForDraw);
                            return;
                        }
                        _arrayCoordsForDraw[indexObjectAfterMouseDown] = {
                            x: coords.x / scale,
                            y: coords.y / scale
                        };
                        dispatch({
                            type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                            status: getStatusPolyIntersection(_arrayCoordsForDraw, arrayGlobalPoints)
                        });
                        getArrayCoordsForDraw(_arrayCoordsForDraw);
                        return;
                    }
                    getArrayCoordsForDraw([{
                        x: coords.x / scale,
                        y: coords.y / scale
                    }]);
                    return;
                }
                if (statusCreatedPolygon) {
                    let coords = correctCoords({x: e.pageX, y: e.pageY}, document.getElementById('MapsContainer').getBoundingClientRect());
                    let arrayGlobalPoints = tools.copy(arrayPlanCoords);
                    let array = tools.copy(arrayCoords);
        
                    if (gridObj !== null) {
            
                        // coords = correctCoords({x: objCoordGrid[0], y: objCoordGrid[1]}, document.getElementById('MapsContainer').getBoundingClientRect());
                        let objCoordGrid = getCoordWithGrid([coords.x, coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                        arrayGlobalPoints = tools.copy(arrayPlanCoords);
                        getCoords({x: objCoordGrid[0], y: objCoordGrid[1]});
                        // console.log('!!!!!!!!!!!!!4', objCoordGrid)
                        array[array.length - 1].x = objCoordGrid[0] / scale;
                        array[array.length - 1].y = objCoordGrid[1] / scale;
            
                        arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                        getArrayCoords(array);
                        // console.log('>>>>>>>>>>>>>>>>1', arrayGlobalPoints)
                        getArrayPlanCoords(arrayGlobalPoints);
                        return;
                    }
                    else {
                        getCoords({x:e.pageX, y:e.pageY});
                    }
        
        
        
                    array[array.length - 1].x = coords.x / scale;
                    array[array.length - 1].y = coords.y / scale;
        
                    arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                    getArrayCoords(array);
                    // console.log('>>>>>>>>>>>>>>>>2', arrayGlobalPoints)
                    getArrayPlanCoords(arrayGlobalPoints);
                }
            }
            
        }


    };

    // console.log('>>>>>>>>>>>>>>>>>!!!!!!!!', statusShowModalInfoIntersection, state.mapsStatusIntersectionPolygons)

    const onClickSvg = (e) => {

        let coords = correctCoords({x: e.pageX, y: e.pageY}, document.getElementById('MapsContainer').getBoundingClientRect());
        let arrayGlobalPoints = tools.copy(arrayPlanCoords);
        
        if (state.mapsStatusIntersectionPolygons === false) {
            
            if (modeMap !== 'noDo' && modeMap !== 'edit') {
                if (state.sectionStatus === 'devices') {
                    
                    if (gridObj !== null) {
                        let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                        dispatch({
                            type:'GET_DATA_AFTER_DRAW',
                            coords: {
                                x: objCoordGrid[0] / scale,
                                y: objCoordGrid[1] / scale
                            }
                        });
                    }
                    else {
                        dispatch({
                            type:'GET_DATA_AFTER_DRAW',
                            coords: {
                                x: coords.x / scale,
                                y: coords.y / scale
                            }
                        })
                    }
                }
                else {
                    
                    if (gridObj === null && (coords.x !== endCoords.x || coords.y !== endCoords.y)) {
                        if (state.sectionStatus === 'scale') {
                            let array = tools.copy(arrayCoords);
        
                            if (state.selectedScaleType === 'segment') {
                                getStatusCreatedPolygon(true);
                                let array = tools.copy(arrayCoords);
                                if (array.length === 0) {
                                    console.log('>>>>>>>>>>>>>>')
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                
                                    arrayGlobalPoints.push(array)
                                }
                                else if (array.length === 1) {
                                    console.log('>>>>>>>>>>>>>>1')
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                
                                    arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                                }
                                // console.log('>>>>>>>>>>>>>>2', array)
                                getArrayCoords(
                                    array
                                );
                                if (array.length > 1) {
                                    if (tools.compare(array[0], array[1]) === false) {
                                        dispatch({
                                            type:'GET_DATA_AFTER_DRAW',
                                            coords: array
                                        });
                                        arrayGlobalPoints = [[]];
                                        getStatusCreatedPolygon(false);
                                        getArrayCoords([])
                                    }
                                    
                                    // dispatch({
                                    //     type:'GET_DATA_AFTER_DRAW',
                                    //     coords: array
                                    // });
                                    // arrayGlobalPoints = [[]];
                                    // getStatusCreatedPolygon(false);
                                    // getArrayCoords([])
                                    // return;
                                }
                                // console.log('>>>>>>>>>>>>>>',arrayGlobalPoints,  array)
                            }
                            else {
                                getStatusCreatedPolygon(true);
                                let array = tools.copy(arrayCoords);
                                if (array.length === 0) {
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                
                                    arrayGlobalPoints.push(array)
                                }
                                else {
                                    array.push({
                                        x:coords.x / scale,
                                        y:coords.y / scale
                                    });
                
                                    arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                                }
                                getArrayCoords(
                                    array
                                );
                            }
                        }
                        else {
                            getStatusCreatedPolygon(true);
                            let array = tools.copy(arrayCoords);
                            if (array.length === 0) {
                                array.push({
                                    x:coords.x / scale,
                                    y:coords.y / scale
                                });
                                array.push({
                                    x:coords.x / scale,
                                    y:coords.y / scale
                                });
        
                                arrayGlobalPoints.push(array)
                            }
                            else {
                                array.push({
                                    x:coords.x / scale,
                                    y:coords.y / scale
                                });
        
                                arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                            }
                            getArrayCoords(
                                array
                            );
                        }
                        
                    }
                    else if (gridObj !== null) {
    
                        let _coords = correctCoords({x: e.pageX, y: e.pageY}, document.getElementById('MapsContainer').getBoundingClientRect());
                        let objCoordGrid = getCoordWithGrid([_coords.x, _coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
                        let __coords = {
                            x: objCoordGrid[0],
                            y: objCoordGrid[1]
                        };
                        
                        
    
                        if (__coords.x <= endCoords.x + 5 && __coords.x >= endCoords.x - 5 && __coords.y <= endCoords.y + 5 && __coords.y >= endCoords.y - 5 ) {
        
                            if (state.sectionStatus === 'scale') {
                                arrayCoords.splice(arrayCoords.length - 1,  1)
                                // console.log('>>>>>>>>>>>>>', arrayCoords);
                                // console.log('!!!!!!!!!!!!!3')
                                dispatch({
                                    type:'GET_DATA_AFTER_DRAW',
                                    coords: arrayCoords
                                });
                                arrayGlobalPoints = [[]];
                                getStatusCreatedPolygon(false);
                                getArrayCoords([])
                                return;
                            }
                            dispatch({
                                type:'GET_DATA_AFTER_DRAW',
                                coords: arrayCoords
                            });
    
                            if (state.sectionStatus !== 'tracks') {
                                let statusIntersectionPolygons = false;
        
                                arrayGlobalPoints.forEach((itemCoords, indexCoords) => {
                                    // polygonsIntersect
                                    // console.log('>>>>>>>>>>222222', polygonsIntersect(arrayCoords, itemCoords), arrayCoords, itemCoords)
                                    if (indexCoords < arrayGlobalPoints.length - 1) {
                                        if (polygonsIntersect(arrayCoords, itemCoords).length > 0) {
                                            statusIntersectionPolygons = true;
                                        }
                                    }
            
                                });
                                // console.log('>>>>>>>>>>>>>>>>>>>>>', statusIntersectionPolygons);
                                dispatch({
                                    type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                    status: statusIntersectionPolygons
                                });
                            }
    
                            arrayGlobalPoints = [[]];
                            getStatusCreatedPolygon(false);
                            getArrayCoords([])
                        }
                        else {
                            // console.log('>>>>>>>>>>>>>2', arrayCoords);
                            if (tools.compare(arrayCoords[0], arrayCoords[1]) === false) {
                                if (state.sectionStatus === 'scale') {
                                    // arrayCoords.splice(arrayCoords.length - 1,  1)
                                    // console.log('>>>>>>>>>>>>>', arrayCoords);
                                    if (state.selectedScaleType === 'segment') {
                                        dispatch({
                                            type:'GET_DATA_AFTER_DRAW',
                                            coords: arrayCoords
                                        });
                                        arrayGlobalPoints = [[]];
                                        getStatusCreatedPolygon(false);
                                        getArrayCoords([])
                                        return;
                                    }
                                    
                                    
                                }
                                // dispatch({
                                //     type:'GET_DATA_AFTER_DRAW',
                                //     coords: array
                                // });
                                // arrayGlobalPoints = [[]];
                                // getStatusCreatedPolygon(false);
                                // getArrayCoords([])
                            }
                            getStatusCreatedPolygon(true);
                            let array = tools.copy(arrayCoords);
                            let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid);
        
                            let tempObj = {
                                x:objCoordGrid[0] / scale,
                                y:objCoordGrid[1] / scale
                            };
        
                            // console.log('!!!!!!!!!!!!!', objCoordGrid);
        
                            if (array.length === 0) {
            
            
                                array.push(tempObj);
                                array.push(tempObj);
                                // console.log('!!!!!!!!!!!!!1', tempObj, array);
                                // arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                                arrayGlobalPoints.push(array)
                            }
                            else {
            
                                // array.push(tempObj);
                                // array[array.length - 1] = tempObj;
                                array.push(tempObj);
                                // console.log('!!!!!!!!!!!!!2', tempObj, array);
                                arrayGlobalPoints[arrayGlobalPoints.length - 1] = array;
                            }
                            // console.log('!!!!!!!!!!!!!3', array)
                            getArrayCoords(
                                array
                            );
                        }
                
                        
                
                    }
                    else {
    
                        if (state.sectionStatus !== 'tracks') {
                            let statusIntersectionPolygons = false;
        
                            arrayGlobalPoints.forEach((itemCoords, indexCoords) => {
                                // polygonsIntersect
                                // console.log('>>>>>>>>>>222222', polygonsIntersect(arrayCoords, itemCoords), arrayCoords, itemCoords)
                                if (indexCoords < arrayGlobalPoints.length - 1) {
                                    if (polygonsIntersect(arrayCoords, itemCoords).length > 0) {
                                        statusIntersectionPolygons = true;
                                    }
                                }
            
                            });
                            // console.log('>>>>>>>>>>>>>>>>>>>>>', statusIntersectionPolygons);
                            dispatch({
                                type:'GET_MAPS_STATUS_INTERSECTION_POLYGONS',
                                status: statusIntersectionPolygons
                            });
                        }
    
                        // console.log('>>>>>>>>>>>>>>3')
                        dispatch({
                            type:'GET_DATA_AFTER_DRAW',
                            coords: arrayCoords
                        });
                        arrayGlobalPoints = [[]];
                        getStatusCreatedPolygon(false);
                        getArrayCoords([])
                    }
                }
        
                // console.log('!!!!!!!!!!!!!3', arrayGlobalPoints)
                // console.log('>>>>>>>>>>>>>>>>3', arrayGlobalPoints)
                getArrayPlanCoords(arrayGlobalPoints);
                if (gridObj !== null) {
                    let objCoordGrid = getCoordWithGrid([coords.x,coords.y], gridObj.scale,gridObj !== null, scale, scaleGrid)
            
                    getEndCoords({
                        x:objCoordGrid[0],
                        y:objCoordGrid[1]
                    })
                }
                else {
                    getEndCoords({
                        x:coords.x,
                        y:coords.y
                    })
                }
            }
        }
        



    };

    const getScaleMinus = () => {
        getScale(scale - 0.05);
    };

    const getScalePlus = () => {
        getScale(scale + 0.05);
    };


    const onSelectObjectsHolst = (e, index) => {
        if (statusCreatedPolygon === false) {
            if (modeMap !== 'noDo' && modeMap !== 'add') {
                e.stopPropagation();
                dispatch({
                    type:'GET_INDEX_OBJECTS',
                    index: index
                })
            }

        }

    };

    const onMouseDownPoint = (index) => {
        getStatusMouseDown(true);
        getIndexObjectAfterMouseDown(index);
    };
    const onMouseUpPoint = (e) => {
        // e.stopPropagation();
        getStatusMouseDown(false);
        if (state.dataBySelectedObjects !== null) {
            dispatch({
                type:'SAVE_TEMP_COORDS',
                index: state.sectionStatus === 'devices' ? indexObjectAfterMouseDown : state.dataBySelectedObjects.index,
                coords: state.sectionStatus === 'devices' ? arrayCoordsForDraw[0] : arrayCoordsForDraw,
                dataTrack: state.sectionStatus === 'tracks' ? arrayTrackDataForDraw : null
            });
            
        }

    };

    const onClickSvgDraw = () => {
        
        dispatch({
            type:'GET_NEW_COORDS_AFTER_MOUSE_MOVE',
            index: state.sectionStatus === 'devices' ? indexObjectAfterMouseDown : state.dataBySelectedObjects.index,
            coords: state.sectionStatus === 'devices' ? arrayCoordsForDraw[0] : arrayCoordsForDraw,
            dataTrack: state.sectionStatus === 'tracks' ? arrayTrackDataForDraw : null
        });
        dispatch({
            type:'CLEAR_SELECTED_DATA'
        });
        getStatusMouseDown(false);
        getIndexObjectAfterMouseDown(null);
    };




    const getContextMenu = (e, index, _index, arrayEntrances) => {
        
        // console.log('????????????', arrayEntrances, state.arrayEntrances)
        getObjIndexPointsTrack({
            index:index,
            _index:_index
        });
        getCoordsContextMenu({
            x: e.pageX,
            y: e.pageY
        });
        props.getStatusContextMenu(
            {
                x: e.pageX,
                y: e.pageY
            }
        )
        getArrayEntrancesTemp(state.arrayEntrances);
        dispatch({
            type:'GET_ENTRANCES',
            arrayEntrances: state.arrayEntrances
        });
    };


    const onClickGlobal = (e) => {
        getObjIndexPointsTrack(null);
        getCoordsContextMenu(null);
        props.getStatusContextMenu(
            null
        )
    };

    const getTime = (time) => {
        let _arrayTrackDataForDraw = tools.copy(arrayTrackDataForDraw);

        _arrayTrackDataForDraw.duration_mask[objIndexPointsTrack._index] = time;
        getArrayTrackDataForDraw(_arrayTrackDataForDraw);

    };

    const netRoundCoordinates = (arrCoords, scale, gridStatus, scaleHolst, paramGrid) => {
        if (gridStatus === false) {
            return arrCoords;
        }
        else if (gridStatus === true) {

            var x = Math.round(arrCoords[0] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            var y = Math.round(arrCoords[1] / (scale *  paramGrid * scaleHolst)) * (scale *  paramGrid * scaleHolst);
            // console.log('scale', x);
            // console.log('!!!!!!!!!!!!!3', [x,y])
            return [x,y];
        }
    };

    const onChangeContextMenu = (status) => {

        // console.log('>>>>>>>>>>>>>>>>>>', objIndexPointsTrack, status);
        dispatch({
            ...objIndexPointsTrack,
            type:'ON_CHANGE_CONTEXT_MENU',
            status
        });
    };

    let menuItems = [

    ];

    if (state.sectionStatus === 'devices') {

    }
    else if (state.sectionStatus === 'scale') {

    }
    else if (state.sectionStatus === 'places') {
        menuItems = [
            {
                key: 'newPointBefore',
                text: 'Добавить точку до',
                onClick: () => onChangeContextMenu('newPointBefore'),
                // disabled: objIndexPointsTrack._index === 0 ? true : false,
            },
            {
                key: 'newPointAfter',
                text: 'Добавить точку после',
                onClick: () => onChangeContextMenu('newPointAfter'),
                // disabled: true,
            },
            {
                key: 'deletePoint',
                text: 'Удалить точку',
                onClick: () => onChangeContextMenu('deletePoint'),
                // disabled: state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[action.index][objIndexPointsTrack._index + 1] !== undefined ? false : true,
            },
            // {
            //     key: 'addPointForEntrances',
            //     text: 'Добавить точку во вход',
            //     onClick: () => onChangeContextMenu('addPointForEntrances'),
            //     disabled: !state.checkedPlacesInputCreate,
            // },

        ];
    }
    else if (state.sectionStatus === 'zones') {
        menuItems = [
            {
                key: 'newPointBefore',
                text: 'Добавить точку до',
                onClick: () => onChangeContextMenu('newPointBefore'),
                // disabled: true,
            },
            {
                key: 'newPointAfter',
                text: 'Добавить точку после',
                onClick: () => onChangeContextMenu('newPointAfter'),
                // disabled: true,
            },
            {
                key: 'deletePoint',
                text: 'Удалить точку',
                onClick: () => onChangeContextMenu('deletePoint'),
                // disabled: true,
            },

        ];
    }
    else if (state.sectionStatus === 'tracks') {
        
        menuItems = [
            {
                key: 'newPointBefore',
                text: 'Добавить точку до',
                onClick: () => onChangeContextMenu('newPointBefore'),
                disabled: objIndexPointsTrack === null ? false : (objIndexPointsTrack._index === 0 ? true : false),
            },
            {
                key: 'newPointAfter',
                text: 'Добавить точку после',
                onClick: () => onChangeContextMenu('newPointAfter'),
                disabled: objIndexPointsTrack === null ? false : (state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].coords[objIndexPointsTrack.index][objIndexPointsTrack._index + 1] !== undefined ? false : true),
            },
            {
                key: 'deletePoint',
                text: 'Удалить точку',
                onClick: () => onChangeContextMenu('deletePoint'),

            },
            {
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider
            },
            {
                key: 'timeStop',
                text: 'Время стояния',
                // onClick: () => console.log('Rename clicked'),
                subMenuProps: {
                    items: [
                        {
                            key: '1sec',
                            text: '1 сек',
                            title: 'Create an email',
                            onClick: () => getTime(1)
                        },
                        {
                            key: '20sec',
                            text: '20 сек',
                            title: 'Create a calendar event',
                            onClick: () => getTime(20)
                        },
                        {
                            key: '30sec',
                            text: '30 сек',
                            title: 'Create a calendar event',
                            onClick: () => getTime(30)
                        },{
                            key: '1min',
                            text: '1 мин',
                            title: 'Create a calendar event',
                            onClick: () => getTime(60)
                        },
                        {
                            key: '2min',
                            text: '2 мин',
                            title: 'Create a calendar event',
                            onClick: () => getTime(120)
                        },
                        {
                            key: '3min',
                            text: '3 мин',
                            title: 'Create a calendar event',
                            onClick: () => getTime(180)
                        },
                        {
                            key: '5min',
                            text: '5 мин',
                            title: 'Create a calendar event',
                            onClick: () => getTime(300)
                        }
                    ]
                },
            },
        ];
    }
    else if (state.sectionStatus === 'polygons') {
        menuItems = [
            {
                key: 'newPointBefore',
                text: 'Добавить точку до',
                onClick: () => onChangeContextMenu('newPointBefore'),
                // disabled: true,
            },
            {
                key: 'newPointAfter',
                text: 'Добавить точку после',
                onClick: () => onChangeContextMenu('newPointAfter'),
                // disabled: true,
            },
            {
                key: 'deletePoint',
                text: 'Удалить точку',
                onClick: () => onChangeContextMenu('deletePoint'),
                // disabled: true,
            },

        ];
    }
    else if (state.sectionStatus === 'background') {
        menuItems = [
            {
                key: 'newPointBefore',
                text: 'Добавить точку до',
                onClick: () => onChangeContextMenu('newPointBefore'),
                // disabled: true,
            },
            {
                key: 'newPointAfter',
                text: 'Добавить точку после',
                onClick: () => onChangeContextMenu('newPointAfter'),
                // disabled: true,
            },
            {
                key: 'deletePoint',
                text: 'Удалить точку',
                onClick: () => onChangeContextMenu('deletePoint'),
                // disabled: true,
            },

        ];
    }
    
    

    
    const options = [
        {
            key: 'noDo',
            // text: 'Режим осмотра',
            iconProps: { iconName: 'RedEye' },
            styles:
                {
                    labelWrapper: {
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                    },
                    field: {
                        marginRight: '15px',
                        padding: '0px'
                        // paddingTop: '2px'
                    },

                    choiceFieldWrapper: {
                        // marginTop: '5px'
                        height: '32px',
                        width: '82px',
                        display: "flex",
                    },
                    iconWrapper: {
                        height: '20px',
                        width: '20px',
                        fontSize: '20px',
                    }
                },
            disabled: statusCreatedPolygon || state.checkedPlacesInputCreate
        },
        {
            key: 'edit',
            // text: 'Редактироание сущностей',
            iconProps: { iconName: 'SingleColumnEdit' },
            styles:
                {
                    labelWrapper: {
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                    },
                    field: {
                        marginRight: '15px',
                        padding: '0px'
                        // paddingTop: '2px'
                    },

                    choiceFieldWrapper: {
                        // marginTop: '5px'
                        height: '32px',
                        width: '82px',
                        display: "flex",
                    },
                    iconWrapper: {
                        height: '20px',
                        width: '20px',
                        fontSize: '20px',
                    }
                },
            disabled: statusCreatedPolygon || state.checkedPlacesInputCreate
        },
        {
            key: 'add',
            // text: 'Добавление сущностей',
            iconProps: { iconName: 'BoxAdditionSolid' },
            styles:
                {
                    labelWrapper: {
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                    },
                    field: {
                        marginRight: '15px',
                        padding: '0px'
                        // paddingTop: '2px'
                    },

                    choiceFieldWrapper: {
                        // marginTop: '5px'
                        height: '32px',
                        width: '82px',
                        display: "flex",
                    },
                    iconWrapper: {
                        height: '20px',
                        width: '20px',
                        fontSize: '20px',
                    }
                },
            disabled: statusCreatedPolygon || state.checkedPlacesInputCreate || state.sectionStatus === 'zones' ? state.selectedGruopMarker === '' : false
        },

    ];

    const itemAlignmentsStackTokens = {
        childrenGap: 5,
        padding: 5
    };

    const itemAlignmentsStackStyles = {
        root: {
            background: 'rgba(208,208,208,0.1)',
            height: 50
        }
    };

    const onChangeModeMaps = (e, option) => {
        // console.log('>>>>>>>>>>>>>>>>>.', option.key);
        setModeMap(option.key);
        dispatch({
            type:'GET_MODE_MAPS',
            mode:option.key
        })
    }


    const _box = state.dataPlanSetByFloor[state.selectedFloor] ?
        {
            height:state.dataPlanSetByFloor[state.selectedFloor].height,
            width:state.dataPlanSetByFloor[state.selectedFloor].width
        }:
        {
            height:1,
            width:1
        };

    const targetContextMenu = coordsContextMenu !== null ? {x: coordsContextMenu.x, y: coordsContextMenu.y} : {x: 0, y: 0};
    const iconButtonStyles = mergeStyleSets({
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: theme.palette.neutralDark
        }
    });

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        },
        header: [
            // tslint:disable-next-line:deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${'#46b875'}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });
    
    const contentStyles2 = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        },
        header: [
            // tslint:disable-next-line:deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${'#a22735'}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });

    const tokensStack = { childrenGap: 50 };

    const stylesStack = { root: { width: 300 } };

    const columnProps = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } }
    };

    const closeModal = () => {
        setStatusGoToGeomaping(false);
    };
    
    const closeModalIntersection = () => {
        setStatusShowModalInfoIntersection(false);
    };
    
    const onClickPoint = (index, _index) => {
        if (state.statusAddPointsEntrances) {
            dispatch({
                type:'GET_NEW_POINT_ENTRANCES',
                index,
                _index
            })
        }
        
    };
    
    

    return (

        <div className={'MainMaps'}
             onClick={onClickGlobal}

        >


            <MapsContainer
                box={_box}
                scale={scale}
                imageBack={imageBack}
                className={'maps'}
                id={'MapsContainer'}
            >

                <SvgBox
                    onMouseMove={mouseMove}
                    tempCoords={coords}
                    onClick={onClickSvg}
                    arrayPoints={arrayCoords}
                    coords={arrayPlanCoords}
                    scale={scale}
                    modeColorScheme={state.modeDisplay}
                    type={state.sectionStatus}
                    dataNamesForMaps={dataNamesForMaps}
                    selectedScaleType={state.selectedScaleType}
                    dataBySelectedObjects={state.dataBySelectedObjects}
                    dataCkeckedSubsection={state.dataCkeckedSubsection}
                    typeTrack={state.typeTrack}
                    selectedNavigationGeometryType={state.selectedNavigationGeometryType}
                    selectedGruopMarker={state.selectedGruopMarker}
                    statusSelectedObjects={state.statusSelectedObjects}
                    onSelectObjectsHolst={onSelectObjectsHolst}
                    onMouseDownPoint={onMouseDownPoint}
                    onMouseUpPoint={onMouseUpPoint}
                    arrayTrackData={arrayTrackData}
                    arrayEntrances={arrayEntrances}
                    isShowMark={state.isShowMark}
                />
                <SvgBoxDrow
                    onMouseMove={mouseMove}
                    tempCoords={coords}
                    onClick={onClickSvgDraw}
                    arrayPoints={arrayCoords}
                    scale={scale}
                    modeColorScheme={state.modeDisplay}
                    type={state.sectionStatus}
                    dataNamesForMaps={dataNamesForMaps}
                    selectedScaleType={state.selectedScaleType}
                    dataBySelectedObjects={state.dataBySelectedObjects}
                    dataCkeckedSubsection={state.dataCkeckedSubsection}
                    typeTrack={state.typeTrack}
                    selectedNavigationGeometryType={state.selectedNavigationGeometryType}
                    selectedGruopMarker={state.selectedGruopMarker}
                    statusSelectedObjects={state.statusSelectedObjects}
                    onSelectObjectsHolst={onSelectObjectsHolst}
                    onMouseDownPoint={onMouseDownPoint}
                    onMouseUpPoint={onMouseUpPoint}
                    getContextMenu={getContextMenu}
                    arrayCoordsForDraw={arrayCoordsForDraw}
                    arrayTrackDataForDraw={arrayTrackDataForDraw}
                    arrayEntrances={arrayEntrances}
                    onClickPoint={onClickPoint}
                    checkedPlacesInputCreate={state.checkedPlacesInputCreate}
                    arrayEntrancesForDraw={arrayEntrancesForDraw}
                    objDataByMarker={state.objDataByMarker}
                    isShowMark={state.isShowMark}
                />
                <SCBoxContextMenu top={coordsContextMenu !== null ? coordsContextMenu.y : 0} left={coordsContextMenu !== null ? coordsContextMenu.x : 0}>
                    <ContextualMenu
                        items={menuItems}
                        hidden={coordsContextMenu === null}
                        target={targetContextMenu}
                        onItemClick={onClickGlobal}
                        onDismiss={onClickGlobal}
                    />
                </SCBoxContextMenu>
                <div className="boxAddItemMaps">
                    <Stack horizontal disableShrink styles={itemAlignmentsStackStyles} tokens={itemAlignmentsStackTokens}>
                        <ChoiceGroup
                            // label="Выбор режима работы с картой"
                            defaultSelectedKey={modeMap}
                            // className="inlineflex"
                            options={options}
                            onChange={onChangeModeMaps}

                        />
                    </Stack>

                </div>

                
            </MapsContainer>
            <div className={state.statusSelectedObjects ? 'boxButtonMaps colapsed' : "boxButtonMaps"}>

                <div onClick={getScalePlus} className="plusButton buttonIcon">
                    <span className={'boxIcon'} >
                        <i className="fas fa-search-plus fa-2x"></i>
                    </span>
                </div>
                <div onClick={getScaleMinus} className="minusBuuton buttonIcon">
                    <span className={'boxIcon'} >
                        <i className="fas fa-search-minus fa-2x"></i>
                    </span>
                </div>






            </div>

            <Modal
                titleAriaId={'New group zone'}
                subtitleAriaId={'subText'}
                isOpen={statusGoToGeomaping}
                onDismiss={closeModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                // dragOptions={_dragOptions}
            >
                <div className={contentStyles.header}>
                    <span id={'New group zone'}>Информация</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={closeModal}
                    />
                </div>
                <div id={'subText'} className={contentStyles.body}>
                    <Stack vertical
                           tokens={tokensStack}
                           styles={stylesStack}
                    >
                        <Stack
                            {...columnProps}
                        >
                            <div>
	                            <p>
		                            Данный план сет не имеет гео данных по этому требуется добавить масштаб сохранить и нажать геомапинг добавить гео расположение в виде широты и долготы и перейти в геомапинг
	                            </p>
                            </div>
                            <PrimaryButton
                                text="ok"
                                // disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                                onClick={closeModal}
                                // allowDisabledFocus
                                // disabled={disabled}
                                // checked={checked}
                            />
                        </Stack>

                    </Stack>
                </div>
            </Modal>
            <Modal
                titleAriaId={'New group zone'}
                subtitleAriaId={'subText'}
                isOpen={statusMouseDown === false ? statusShowModalInfoIntersection: false}
                onDismiss={closeModalIntersection}
                isBlocking={false}
                containerClassName={contentStyles2.container}
                // dragOptions={_dragOptions}
            >
                <div className={contentStyles2.header}>
                    <span id={'New group zone'}>Ошибка</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={closeModalIntersection}
                    />
                </div>
                <div id={'subText'} className={contentStyles2.body}>
                    <Stack vertical
                           tokens={tokensStack}
                           styles={stylesStack}
                    >
                        <Stack
                            {...columnProps}
                        >
                            <div>
                                <p>
                                    Последний нарисованный полигон имеет пересечение с другими полигонами.
                                    Нарисуйте его так чтобы он не пересекал другие полигоны
                                </p>
                            </div>
                            <PrimaryButton
                                text="ok"
                                // disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                                onClick={closeModalIntersection}
                                // allowDisabledFocus
                                // disabled={disabled}
                                // checked={checked}
                            />
                        </Stack>
            
                    </Stack>
                </div>
            </Modal>
        </div>

    );
};


export default Maps;