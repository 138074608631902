import React from 'react';
import tools from "../tools_svg";

const SvgBoxDrow = React.memo((props) => {
    const onMouseUpPoint = (e) => {
        // console.log('>>>>>>>>>>>>>', e)
        props.onMouseUpPoint(e)
    };


    return ( props.dataBySelectedObjects === null ?
            null :
        <svg
            className={'SvgBoxDrow'}
            onMouseMove={props.onMouseMove}
            onClick={props.onClick}
            onMouseUp={onMouseUpPoint}
            height={'100%'} width={'100%'}>
            {tools.createSvgDrawElement(props)}

        </svg>
    );
});

export default SvgBoxDrow;