import React, { useContext, useEffect, useState } from 'react';
import GlobalDispatchContext from "../../../global_dispatch_context";
// import tools from '../../../tools';
// import { Nav } from 'office-ui-fabric-react/lib/Nav';
// import styled from "styled-components";
import { FocusZone, FocusZoneDirection } from 'office-ui-fabric-react/lib/FocusZone';
import { List, ScrollToMode } from 'office-ui-fabric-react/lib/List';
// import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { mergeStyleSets, getTheme, getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
// import {Dropdown} from "office-ui-fabric-react/lib/Dropdown";
import {Stack} from "office-ui-fabric-react/lib/Stack";
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
// import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
// import { Icon } from 'office-ui-fabric-react/lib/Icon';
import toolsLeftPanel from "../tools";
import tools from "../../../tools";
// import { createListItems, IExampleItem } from '@uifabric/example-data';


const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

// const ScTitleNav = styled.h3`
//   margin-left: 10px;
//   ${
//     props => props.modeDisplay === 'light' ? null :
//         `color:#827f7d`
// }
//
// `;

const ListObjects = (props) => {

    const {state, dispatch} = useContext(GlobalDispatchContext);
    // const [filterText, setFilterTexty] = useState('');
    const [componentsListNode, setComponentsListNode] = useState([]);
    const [_componentsListNode, _setComponentsListNode] = useState([]);
    const [selectedKey, _setSelectedKey] = useState([]);
    const [checkedToggle, setCheckedToggle] = useState(false);
    const [modeMaps, setModeMaps] = useState('add');
    const [stateScrollabel, _setStateScrollabel] = useState({
        selectedIndex: 0,
        scrollToMode: ScrollToMode.auto,
        showItemIndexInView: false
    });

    let _list;


    useEffect(() => {
        let componentsList = []
        if (checkedToggle) {
            componentsList = tools.copy(toolsLeftPanel.createListObjects(
                state.dataPlanSetByFloor,
                state.sectionStatus,
                selectedKey,
                state.selectedNavigationGeometryType,
                state.selectedGruopMarker,
                state.selectedScaleType,
                state.modeMaps,
                state.dataTrackForDraw,
                state.typeTrack,
                state.mapsStatusIntersectionPolygons
            ))



        }
        else {
            if (state.dataPlanSetByFloor[state.selectedFloor] !== undefined) {

                if (state.sectionStatus === 'devices') {
                    state.dataPlanSetByFloor[state.selectedFloor].layers.shopster_ipoint_layer.ipoints.forEach((item, index) => {

                        componentsList.push(
                            {
                                name: item.marker,
                                url: '',
                                key: index,
                                isExpanded: true,
                                selectedKey: selectedKey === index,
                                is_opened:item.is_opened,
                                modeMaps:state.modeMaps,
                                disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                            }
                        );


                    })
                }
                else if (state.sectionStatus === 'background') {
                    // console.log('>>>>>>>>>>>>>>>',  state.dataPlanSetByFloor[state.selectedFloor].perimeter.coords);
                    state.dataPlanSetByFloor[state.selectedFloor].perimeter.coords.forEach((item, index) => {

                        componentsList.push(
                            {
                                name: index,
                                url: '',
                                key: index,
                                isExpanded: true,
                                selectedKey: selectedKey === index,
                                modeMaps:state.modeMaps,
                                disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                            }
                        );


                    })
                }
                else if (state.sectionStatus === 'places') {

                    state.dataPlanSetByFloor[state.selectedFloor].layers.place_layer.places.forEach((item, index) => {
                        componentsList.push(
                            {
                                name: item.marker,
                                url: '',
                                key: index,
                                isExpanded: true,
                                selectedKey: selectedKey === index,
                                is_opened: item.is_opened,
                                modeMaps:state.modeMaps,
                                disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                            }
                        );

                    })
                }
                else if (state.sectionStatus === 'polygons') {


                    state.dataPlanSetByFloor[state.selectedFloor][state.selectedNavigationGeometryType].coords.forEach((item, index) => {
                        componentsList.push(
                            {
                                name: index,
                                url: '',
                                key: index,
                                isExpanded: true,
                                selectedKey: selectedKey === index,
                                modeMaps:state.modeMaps,
                                disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                            }
                        );

                    })
                }
                else if (state.sectionStatus === 'zones') {
                    if (state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker] !== undefined) {
                        state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers.forEach((item, index) => {
                            // console.log('<<<<<<<<<<<<<<<<<,', state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers[index])
                            componentsList.push(
                                {
                                    name: state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].names[index],
                                    url: '',
                                    key: index,
                                    isExpanded: true,
                                    selectedKey: selectedKey === index,
                                    marker: state.dataPlanSetByFloor[state.selectedFloor].zones[state.selectedGruopMarker].markers[index],
                                    modeMaps:state.modeMaps,
                                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                                }
                            );

                        })
                    }

                }
                else if (state.sectionStatus === 'tracks') {

                    if (state.dataTrackForDraw[state.selectedFloor] !== undefined) {
                        // console.log('>>>>>>>>>>>>>>>>>', state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data);
                        state.dataTrackForDraw[state.selectedFloor].data[state.typeTrack].data.forEach((item, index) => {
                            // console.log('>>>>>>>>>>>>>>>>>', item.id);
                            componentsList.push(
                                {
                                    name: item.id,
                                    url: '',
                                    key: index,
                                    isExpanded: true,
                                    selectedKey: selectedKey === index,
                                    modeMaps:state.modeMaps,
                                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                                }
                            );

                        });
                    }
                }
                else if (state.sectionStatus === 'scale') {
                    if (state.dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords.length > 0 &&
                        state.dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].coords !== undefined) {
                        componentsList.push(
                            {
                                name: state.dataPlanSetByFloor[state.selectedFloor].scale[state.selectedScaleType].param,
                                url: '',
                                key: 0,
                                isExpanded: true,
                                selectedKey: selectedKey === 0,
                                modeMaps:state.modeMaps,
                                disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                            }
                        );

                    }

                }

            }
        }

        setComponentsListNode(componentsList);
        _setComponentsListNode(componentsList);

    }, [
        state.dataPlanSetByFloor,
        state.dataTrackForDraw,
        state.sectionStatus,
        state.dataBySelectedObjects,
        state.selectedGruopMarker,
        state.typeTrack,
        checkedToggle,
        state.selectedFloor,
        state.modeMaps
    ]);

    useEffect(() => {
        // console.log('>>>>>>>>>>>>>>>>>>>>>')
        setCheckedToggle(false);
    }, [
        state.sectionStatus,
        state.selectedFloor
    ]);
    useEffect(() => {
        let selectedKey = null;
        if (state.dataBySelectedObjects !== null) {
            selectedKey = state.dataBySelectedObjects.index;
        }
        _setSelectedKey(selectedKey)
        _scroll(parseInt(selectedKey, 10) || 0, stateScrollabel.scrollToMode);
    }, [
        state.dataBySelectedObjects,
    ]);

    useEffect(() => {
        // console.log('>>>>>>>>>>>>>>>>22', state.modeMaps)
        setModeMaps(state.modeMaps);
    }, [
        state.modeMaps
    ]);


    const onSelectObjects = (e, item, _item, _modeMaps) =>{

        if (_modeMaps !== 'add') {

            dispatch({
                type:'GET_INDEX_OBJECTS',
                index: item,
                item:_item
            })
        }

    };
    const classNames = mergeStyleSets({
        container: {
            overflow: 'auto',
            height: props.withoutTempBox ? window.innerHeight - 87 - 440 : window.innerHeight - 87 - 395 - 220,
        },
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                height: 44,
                padding: 3,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                // borderLeft: '3px solid ' + '#46b875',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                    // '&:active' : { borderLeft: '3px solid ' + '#46b875'}
                }
            }
        ],
        // itemImage: {
        //     flexShrink: 0
        // },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,


        },
        itemName: [
            fonts.medium,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        ],
        itemIndex: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10
        },
        chevron: {
            alignSelf: 'center',
            marginLeft: 10,
            color: palette.neutralTertiary,
            fontSize: fonts.large.fontSize,
            flexShrink: 0
        }
    });

    const evenItemHeight = 52;
    const oddItemHeight = 100;
    // const numberOfItemsOnPage = 10;



    // console.log('>>>>>>>>>>>>', state.dataBySelectedObjects)
    // function _onRenderGroupHeader(group) {
    //     return <ScTitleNav modeDisplay={state.modeDisplay}>{group.name}</ScTitleNav>;
    // }
    const _onRenderCell = (item, index, isScrolling) =>{
        // console.log('>>>>>>>>>>>>>>>>33333', modeMaps)
        
        let _onSelectObjects = (e) => {
            // e.stopPropagation();
            if (state.checkedPlacesInputCreate) {
                onSelectObjects(e, index, item, modeMaps);
            }
            
        };
        return (
            <div

                onClick={_onSelectObjects}
                className={classNames.itemCell}
                style={index === selectedKey ? {
                    borderLeft: '3px solid ' + '#46b875',
                    background: palette.neutralLight
                } : null}
                
                data-is-focusable={true}
            >
                <div className={classNames.itemContent}>
                    <div className={classNames.itemName}>{item.name}</div>
                    <div className={classNames.itemIndex}>{
                        item.marker ?
                            item.marker : item.is_opened ?
                            `открыто: ${item.is_opened} ` : `Объект ${index} `
                    }
                        {
                            checkedToggle ? ` этаж: ${item.floor}` : ''
                        }
                    </div>


                </div>
            </div>
        );
    }
    // console.log('>>>>>>>>>>>>>>>>', index, selectedKey)
    const _onFilterChanged = ( any, text) => {
        // setFilterTexty(text);
        // componentsList = componentsList.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0)
        setComponentsListNode([]);
        let _array = tools.copy(_componentsListNode)
        let array = _array.filter((item) => {

            return item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;

        })
        // console.log('>>>>>>>>>>>>>>>>.', array)
        setComponentsListNode(array);

    };
    // console.log('>>>>>>>>>>>>>>>>.1', selectedKey)

    const _scroll = (index, scrollToMode) => {
        const updatedSelectedIndex = Math.min(Math.max(index, 0), componentsListNode.length - 1);
        _setStateScrollabel({
            selectedIndex: updatedSelectedIndex,
            scrollToMode: scrollToMode
        });
        if (_list !== undefined) {
            _list.scrollToIndex(updatedSelectedIndex, idx => (idx % 2 === 0 ? evenItemHeight : oddItemHeight), scrollToMode);
        }

        // this.setState(
        //     {
        //         selectedIndex: updatedSelectedIndex,
        //         scrollToMode: scrollToMode
        //     },
        //     // () => {
        //     //     this.
        //     // }
        // );
    };

    const _resolveList = (list) => {
        _list = list;
    };
    const stackTokens = { childrenGap: 24 };

    const onChangeToggle = (e, checked) => {
        // console.log('>>>>>>>>>>>>>>>>', checked)
        setCheckedToggle(checked);
    };

    const stylesTextField = {
        wrapper: {
            width: 180 ,
            marginLeft: '12px'
        }
    };

    return (
        <div className={'ListObjects'}>
           {/*{componentsList}*/}
            <div className="fullFloor">
                <Toggle
                    onText={
                        <div>
                            по всем этажам
                            {/*<TooltipHost content="Выбор по всем этажам">*/}
                            {/*    <Icon iconName="Info" aria-label="Info tooltip" />*/}
                            {/*</TooltipHost>*/}
                        </div>
                    }
                    offText={
                        <div>
                            по всем этажам
                            {/*<TooltipHost content="Выбор по всем этажам">*/}
                            {/*    <Icon iconName="Info" aria-label="Info tooltip" />*/}
                            {/*</TooltipHost>*/}
                        </div>

                    }
                    checked={checkedToggle}
                    onChange={onChangeToggle}
                    disabled={(state.sectionStatus === 'scale') || state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate}
                />
            </div>
            <FocusZone direction={FocusZoneDirection.vertical}>

                <Stack tokens={stackTokens} verticalAlign="end">

                    <TextField
                        // label={'Фильтр по названию ' + filterText}
                        onChange={_onFilterChanged}
                        placeholder="Фильтр"
                        styles={stylesTextField}
                        disabled={state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate}
                    />
                </Stack>



                <div className={classNames.container} data-is-scrollable={true}>
                    {state.checkedPlacesInputCreate === false ? componentsListNode.length > 0 ? <List
                        // usePageCache={false}
                        ref={_resolveList}
                        selectedKey={selectedKey}
                        items={componentsListNode}
                        onRenderCell={_onRenderCell}
                        disabled={state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate}
                        version={componentsListNode}
                    /> : null : null}

                </div>
            </FocusZone>

        </div>
    );
};



export default ListObjects;