import React from 'react';

const tools = {
    createDeviceComponent: (props, item, index, middleX, middleY, editingStatus, drawStatus, statusItem) => {
        let statusTemp = false
        if (drawStatus) {
            const onSelectObjectsHolst = (e) => {
                e.stopPropagation();
            };
            const onMouseDownPoint = (e) => {
                // console.log('>>>>>>>>>>>>>', e)
                if (props.dataBySelectedObjects !== null) {
                    if (props.dataBySelectedObjects.index === index) {
                        props.onMouseDownPoint(index)
                    }
                }

            };
            // console.log('>>>>>>>>>>>>>', props.dataBySelectedObjects.marker)
            return (
                <g
                    key={`circleMaps-${index}`}
                    onClick={onSelectObjectsHolst}
                    style={{
                        cursor: 'move',
                        userSelect:'none'
                    }}
                    onMouseDown={
                        onMouseDownPoint
                    }
                    // onMouseUp={props.dataBySelectedObjects.index === index ? onMouseUpPoint : null}
                >
                    <circle
                        cx={item[0].x * props.scale}
                        cy={item[0].y * props.scale}
                        r="15"
                        stroke={'red'}
                        strokeWidth={2}
                        fill={'rgba(253,255,255,.3)'}

                    />
                    <circle
                        cx={item[0].x * props.scale}
                        cy={item[0].y * props.scale}
                        r="10"
                        fill={'rgb(253,255,255)'}
                        stroke={'rgb(100,183,122)'}
                        strokeWidth={3}
                    />
                    <circle
                        cx={item[0].x * props.scale}
                        cy={item[0].y * props.scale}
                        r="5"
                        fill={'black'}
                    />

                    {/*<defs>*/}
                    {/*    <filter x="0" y="0"  width="1" height="1" id="solidDevice">*/}
                    {/*        <feFlood floodColor="rgba(0,0,0,.9)"/>*/}
                    {/*        <feComposite  in="SourceGraphic"/>*/}
                    {/*    </filter>*/}
                    {/*</defs>*/}
                    {/*<text  filter="url(#solidDevice)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={400}  fontSize={11} textAnchor='middle' x={item[0].x*props.scale} y={item[0].y*props.scale - 10}>{props.dataBySelectedObjects.marker}</text>*/}
                </g>
            )
        }
        else {
            if (editingStatus) {
                const onSelectObjectsHolst = (e) => {
                    props.onSelectObjectsHolst(e, index)
                };
                const onMouseDownPoint = (e) => {
                    // console.log('>>>>>>>>>>>>>', e)
                    if (props.dataBySelectedObjects !== null) {
                        if (props.dataBySelectedObjects.index === index) {
                            props.onMouseDownPoint(index)
                        }
                    }

                };

                if (statusItem === 'item') {
                    return (
                        <g
                            key={`circleMapsItem-${index}`}
                            onClick={onSelectObjectsHolst}
                            style={{cursor: 'pointer'}}
                            onMouseDown={
                                onMouseDownPoint
                            }
                            // onMouseUp={props.dataBySelectedObjects.index === index ? onMouseUpPoint : null}
                        >
                            {/*{*/}
                            {/*    props.dataBySelectedObjects !== null ? props.dataBySelectedObjects.index === index*/}
                            {/*        ?*/}
                            {/*        <circle*/}
                            {/*            cx={item.x * props.scale}*/}
                            {/*            cy={item.y * props.scale}*/}
                            {/*            r="15"*/}
                            {/*            stroke={'red'}*/}
                            {/*            strokeWidth={2}*/}
                            {/*            fill={'rgba(253,255,255,.3)'}*/}

                            {/*        />*/}
                            {/*        :*/}
                            {/*        null : null*/}
                            {/*}*/}
                            <circle
                                cx={item.x * props.scale}
                                cy={item.y * props.scale}
                                r="10"
                                fill={'rgb(253,255,255)'}
                                stroke={'rgb(100,183,122)'}
                                strokeWidth={3}
                            />
                            <circle
                                cx={item.x * props.scale}
                                cy={item.y * props.scale}
                                r="5"
                                fill={'black'}
                            />
                        </g>
                    )
                }
                else if (statusItem === 'name') {
                    // console.log('><<>>>>>><<<<<', props.isShowMark)
                    if (props.isShowMark) {
                        return (
                            <g
                                key={`circleMapsName-${index}`}
                                onClick={onSelectObjectsHolst}
                                style={{cursor: 'pointer'}}
                                onMouseDown={
                                    onMouseDownPoint
                                }
                                // onMouseUp={props.dataBySelectedObjects.index === index ? onMouseUpPoint : null}
                            >
                                {/*{*/}
                                {/*    props.dataBySelectedObjects !== null ? props.dataBySelectedObjects.index === index*/}
                                {/*        ?*/}
                                {/*        <circle*/}
                                {/*            cx={item.x * props.scale}*/}
                                {/*            cy={item.y * props.scale}*/}
                                {/*            r="15"*/}
                                {/*            stroke={'red'}*/}
                                {/*            strokeWidth={2}*/}
                                {/*            fill={'rgba(253,255,255,.3)'}*/}

                                {/*        />*/}
                                {/*        :*/}
                                {/*        null : null*/}
                                {/*}*/}


                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solidDevice">
                                        <feFlood floodColor="rgba(0,0,0,.9)"/>
                                        <feComposite  in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text  filter="url(#solidDevice)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={400}  fontSize={11} textAnchor='middle' x={item.x*props.scale} y={item.y*props.scale - 10}>{props.dataNamesForMaps.markers[index]}</text>
                            </g>
                        )
                    }

                }

            }

            if (statusItem === 'item') {
                return (
                    <g
                        key={`circleMapsItem-${index}`}
                    >
                        <circle
                            cx={item.x * props.scale}
                            cy={item.y * props.scale}
                            r="10"
                            fill={'rgb(253,255,255)'}
                            stroke={'rgba(100,183,122,.6)'}
                            strokeWidth={3}
                        />
                        <circle
                            cx={item.x * props.scale}
                            cy={item.y * props.scale}
                            r="5"
                            fill={'black'}
                        />

                        {/*<defs>*/}
                        {/*    <filter x="0" y="0"  width="1" height="1" id="solidDevice">*/}
                        {/*        <feFlood floodColor="rgba(0,0,0,.6)"/>*/}
                        {/*        <feComposite  in="SourceGraphic"/>*/}
                        {/*    </filter>*/}
                        {/*</defs>*/}
                        {/*<text  filter="url(#solidDevice)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={400}  fontSize={11} textAnchor='middle' x={item.x*props.scale} y={item.y*props.scale - 10}>{props.dataCkeckedSubsection.devices.data.markers[index]}</text>*/}
                    </g>
                )
            }
            else if (statusItem === 'name') {
                if (props.isShowMark) {
                    return  (
                        <g
                            key={`circleMapsName-${index}`}
                        >

                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solidDevice">
                                    <feFlood floodColor="rgba(0,0,0,.6)"/>
                                    <feComposite  in="SourceGraphic"/>
                                </filter>
                            </defs>
                            <text  filter="url(#solidDevice)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={400}  fontSize={11} textAnchor='middle' x={item.x*props.scale} y={item.y*props.scale - 10}>{props.dataCkeckedSubsection.devices.data.markers[index]}</text>
                        </g>
                    )
                }

            }
        }


    },
    createTrackComponent: (props, item, index,middleX, middleY, editingStatus, drawStatus, statusItem) => {
        if (drawStatus)  {
            const onSelectObjectsHolst = (e) => {
                e.stopPropagation();
            };
            const onMouseDownPoint = (e, index) => {
                // console.log('>>>>>>>>>>>>>', index, props.dataBySelectedObjects)
                if (props.dataBySelectedObjects !== null) {
                    props.onMouseDownPoint(index)
                }

            };
            const onContextMenu = (e, _index) => {
                e.preventDefault();
                e.stopPropagation();
                props.getContextMenu(e, index, _index);

            };
            let stringPOints = '';
            let componentsPoint = [];

            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                // console.log('!!!!!!!!!!!', props.arrayTrackDataForDraw.duration_mask[index2]);
                if (props.arrayTrackDataForDraw !== null) {
                    if (props.arrayTrackDataForDraw.duration_mask[index2] >= 60) {
                        componentsPoint.push(
                            <g
                                key={`polygonMapsLyneTrack-${index2}-${index}`}
                                onContextMenu={(e) => onContextMenu(e, index2)}
                                onMouseDown={(e) => onMouseDownPoint(e, index2)}
                                style={{
                                    cursor: 'move',
                                    userSelect:'none'
                                }}
                            >
                                <circle

                                    cx={item2.x * props.scale}
                                    cy={item2.y * props.scale}
                                    r="10"
                                    fill={'black'}
                                    style={{
                                        cursor: 'move',
                                        userSelect:'none'
                                    }}

                                />
                                <text fill="rgb(255,255,255)" fontSize="10" textAnchor="middle" x={item2.x * props.scale} y={item2.y * props.scale + 3}>{props.arrayTrackDataForDraw.duration_mask[index2] / 60}м</text>
                            </g>
                        )
                    }
                    else if (props.arrayTrackDataForDraw.duration_mask[index2] < 60 && props.arrayTrackDataForDraw.duration_mask[index2] > 1) {
                        componentsPoint.push(
                            <g
                                onContextMenu={(e) => onContextMenu(e, index2)}
                                onMouseDown={(e) => onMouseDownPoint(e, index2)}
                                key={`polygonMapsLyneTrack-${index2}-${index}`}
                                style={{
                                    cursor: 'move',
                                    userSelect:'none'
                                }}
                            >
                                <circle

                                    cx={item2.x * props.scale}
                                    cy={item2.y * props.scale}
                                    r="10"
                                    fill={'black'}
                                    style={{cursor: 'move'}}

                                />
                                <text

                                    fill="rgb(255,255,255)" fontSize="10" textAnchor="middle" x={item2.x * props.scale} y={item2.y * props.scale + 3}>{props.arrayTrackDataForDraw.duration_mask[index2]}с</text>
                            </g>
                        )
                    }
                    else {
                        componentsPoint.push(
                            <circle
                                key={`polygonMapsLyneTrack-${index2}-${index}`}
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="6"
                                fill={'black'}
                                style={{cursor: 'move'}}
                                onContextMenu={(e) => onContextMenu(e, index2)}
                                onMouseDown={(e) => onMouseDownPoint(e, index2)}
                            />
                        )
                    }
                }


            });
            
            return(
                <g key={`polyLineMapsTrack-${index}`}
                   onClick={onSelectObjectsHolst}
                   style={{cursor: 'pointer'}}
                >
                    <polyline
                        points={stringPOints}
                        // strokeDasharray={'6,3'}
                        strokeWidth={4}
                        fill="none"
                        stroke={'red'}
                    />

                    <polyline
                        points={stringPOints}
                        // strokeDasharray={'6,3'}
                        strokeWidth={2}
                        fill="none"
                        stroke={'rgb(46,233,120)'}
                    />
                    {componentsPoint}


                </g>
            );
        }
        else {
            if (editingStatus) {
                const onSelectObjectsHolst = (e) => {
                    props.onSelectObjectsHolst(e, index)
                };
                let stringPOints = '';
                let componentsPoint = [];
                let componentsPointDrow = [];
                // console.log('<<<<<<<<<>>>>>>>>>', item)
                item.forEach((item2, index2) => {
                    componentsPointDrow.push(
                        <g
                            key={`polygonMapsLyneTrackDraw-${index2}-${index}`}>
                            <circle
                
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="3"
                                fill={'black'}
                            />
                            
                        </g>
                    )
                    if (props.arrayTrackData[index] !== undefined) {
                        if (props.arrayTrackData[index].duration_mask[index2] >= 60) {
                            componentsPoint.push(
                                <g
                                    key={`polygonMapsLyneTrack-${index2}-${index}`}>
                                    <circle

                                        cx={item2.x * props.scale}
                                        cy={item2.y * props.scale}
                                        r="10"
                                        fill={'black'}
                                    />
                                    <text fill="rgb(255,255,255)" fontSize="10" textAnchor="middle" x={item2.x * props.scale} y={item2.y * props.scale + 3}>{props.arrayTrackData[index].duration_mask[index2] / 60}м</text>
                                </g>
                            )
                        }
                        else if (props.arrayTrackData[index].duration_mask[index2] < 60 && props.arrayTrackData[index].duration_mask[index2] > 1) {
                            componentsPoint.push(
                                <g
                                    key={`polygonMapsLyneTrack-${index2}-${index}`}>
                                    <circle

                                        cx={item2.x * props.scale}
                                        cy={item2.y * props.scale}
                                        r="10"
                                        fill={'black'}
                                    />
                                    <text fill="rgb(255,255,255)" fontSize="10" textAnchor="middle" x={item2.x * props.scale} y={item2.y * props.scale + 3}>{props.arrayTrackData[index].duration_mask[index2]}с</text>
                                </g>
                            )
                        }
                        else {
                            componentsPoint.push(
                                <circle
                                    key={`polygonMapsLyneTrack-${index2}-${index}`}
                                    cx={item2.x * props.scale}
                                    cy={item2.y * props.scale}
                                    r="3"
                                    fill={'black'}
                                />
                            )
                        }
                    }
                    stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                    // stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                    // componentsPoint.push(
                    //     <circle
                    //         key={`polygonMapsLyne-${index2}-${index}`}
                    //         cx={item2.x * props.scale}
                    //         cy={item2.y * props.scale}
                    //         r="3"
                    //         fill={'black'}
                    //     />
                    // )
                });
                // console.log('<<<<<<<<<>>>>>>>>>', componentsPoint)
                return(
                    <g key={`polyLineMapsTrack-${index}`}
                       onClick={onSelectObjectsHolst}
                       style={{cursor: 'pointer'}}
                    >


                        <polyline
                            points={stringPOints}
                            // strokeDasharray={'6,3'}
                            strokeWidth={2}
                            fill="none"
                            stroke={'rgb(46,233,120)'}
                        />
                        {componentsPointDrow}
                        {componentsPoint}


                    </g>
                );
            }


            let stringPOints = '';
            let componentsPoint = [];
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                componentsPoint.push(
                    <circle
                        key={`polygonMapsLyneTrack-${index2}-${index}`}
                        cx={item2.x * props.scale}
                        cy={item2.y * props.scale}
                        r="3"
                        fill={'black'}
                    />
                )
            });
            return(
                <g key={`polyLineMapsTrack-${index}-${stringPOints}`}>


                    <polyline
                        points={stringPOints}
                        // strokeDasharray={'6,3'}
                        strokeWidth={2}
                        fill="none"
                        stroke={'rgba(46,233,120,.6)'}
                    />
                    {componentsPoint}


                </g>
            );
        }


    },
    createScaleComponent: (props, item, index, middleX, middleY, editingStatus, drawStatus, statusItem) => {
        if (drawStatus) {

            if (item[0] !== undefined) {
                const onSelectObjectsHolst = (e) => {
                    e.stopPropagation();
                };
                const onMouseDownPoint = (e, index) => {
                    // console.log('>>>>>>>>>>>>>', index, props.dataBySelectedObjects)
                    if (props.dataBySelectedObjects !== null) {
                        props.onMouseDownPoint(index)
                    }

                };
                let stringPOints = '';
                let componentsPoint = [];
                item.forEach((item2, index2) => {
                    stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                    componentsPoint.push(
                        <circle
                            key={`polygonMapsLyne-${index2}-${index}`}
                            cx={item2.x * props.scale}
                            cy={item2.y * props.scale}
                            r="6"
                            fill={'black'}
                            style={{cursor: 'move'}}
                            onMouseDown={(e) => onMouseDownPoint(e, index2)}
                        />
                    )
                });
                let componentNode = [];
                if (props.selectedScaleType ==='polygon') {
                    componentNode.push(
                        <g key={`ScalepolygonMaps-${index}`}
                           onClick={onSelectObjectsHolst}
                           style={{cursor: 'pointer'}}
                        >

                            <polygon

                                points={stringPOints}
                                // strokeDasharray={'6,3'}
                                strokeWidth={1}
                                style={{fill: 'rgba{255,0,0,.5}' , fillOpacity: '0.5', stroke: 'black'}}
                            />
                            <polygon

                                points={stringPOints}
                                strokeDasharray={'6,3'}
                                strokeWidth={2}
                                style={{fill: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                                
                            />
                            {props.dataNamesForMaps.names === undefined ? null :
                                <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>
                            }
                            {componentsPoint}
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                    <feFlood floodColor="rgba(0,0,0,.9)"/>
                                    <feComposite  in="SourceGraphic"/>
                                </filter>
                            </defs>
                        </g>
                    )
                }
                else {
                    const onSelectObjectsHolst = (e) => {
                        e.stopPropagation();
                    };
                    const onMouseDownPoint = (e, index) => {
                        // console.log('>>>>>>>>>>>>>', index, props.dataBySelectedObjects)
                        if (props.dataBySelectedObjects !== null) {
                            props.onMouseDownPoint(index)
                        }

                    };
                    componentNode.push(
                        <g key={`ScalepolygonMaps-${index}`}
                           onClick={onSelectObjectsHolst}
                           style={{cursor: 'pointer'}}
                        >
                            <circle
                                cx={item[0].x * props.scale}
                                cy={item[0].y * props.scale}
                                r="6"
                                fill={'black'}
                                onMouseDown={(e) => onMouseDownPoint(e, 0)}
                                style={{cursor: 'move'}}
                            />
                            <line
                                x1={item[0].x * props.scale}
                                y1={item[0].y * props.scale}
                                x2={item[1].x * props.scale}
                                y2={item[1].y * props.scale}
                                // points={stringPOints}
                                // strokeDasharray={'6,3'}
                                strokeWidth={4}
                                style={{fill:  'rgb(150, 147, 61)', fillOpacity: '0.5', stroke:  'rgb(150,33,33)'}}
                            />
                            <line
                                x1={item[0].x * props.scale}
                                y1={item[0].y * props.scale}
                                x2={item[1].x * props.scale}
                                y2={item[1].y * props.scale}
                                // points={stringPOints}
                                strokeDasharray={'6,3'}
                                strokeWidth={2}
                                style={{fill: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)'}}
                            />
                            <circle
                                cx={item[1].x * props.scale}
                                cy={item[1].y * props.scale}
                                r="6"
                                fill={'black'}
                                onMouseDown={(e) => onMouseDownPoint(e, 1)}
                                style={{cursor: 'move'}}
                            />
                            {props.dataNamesForMaps.names === undefined ? null :
                                <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>
                            }
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                    <feFlood floodColor="rgba(0,0,0,.9)"/>
                                    <feComposite  in="SourceGraphic"/>
                                </filter>
                            </defs>
                        </g>
                    )
                }
                return(
                    componentNode
                );
            }
            else {
                return null;
            }
        }
        else {
            if (editingStatus) {
                if (item[0] !== undefined) {
                    let stringPOints = '';
                    let componentsPoint = [];
                    const onSelectObjectsHolst = (e) => {
                        props.onSelectObjectsHolst(e, index)
                    };
                    item.forEach((item2, index2) => {
                        stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                        componentsPoint.push(
                            <circle
                                key={`polygonMapsLyne-${index2}-${index}`}
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="3"
                                fill={'black'}
                            />
                        )
                    });
                    let componentNode = [];
                    if (props.selectedScaleType ==='polygon') {
                        componentNode.push(
                            <g key={`ScalepolygonMaps-${index}`}
                               onClick={onSelectObjectsHolst}
                               style={{cursor: 'pointer'}}
                            >
                                {/*{componentsPoint}*/}
                                {/*<polygon*/}

                                {/*    points={stringPOints}*/}
                                {/*    // strokeDasharray={'6,3'}*/}
                                {/*    strokeWidth={1}*/}
                                {/*    style={{fill: 'rgba{255,0,0,.5}' , fillOpacity: '0.5', stroke: 'black'}}*/}
                                {/*/>*/}
                                <polygon

                                    points={stringPOints}
                                    strokeDasharray={'6,3'}
                                    strokeWidth={2}
                                    style={{fill: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                                />
                                {props.dataNamesForMaps.names === undefined ? null :
                                    <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>
                                }
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                        <feFlood floodColor="rgba(0,0,0,.9)"/>
                                        <feComposite  in="SourceGraphic"/>
                                    </filter>
                                </defs>
                            </g>
                        )
                    }
                    else {
                        componentNode.push(
                            <g key={`scaleLineMaps-${index}`}
                               onClick={onSelectObjectsHolst}
                               style={{cursor: 'pointer'}}
                            >
                                <circle
                                    cx={item[0].x * props.scale}
                                    cy={item[0].y * props.scale}
                                    r="3"
                                    fill={'black'}
                                />
                                {/*{*/}
                                {/*    props.statusSelectedObjects ?*/}
                                {/*        <line*/}
                                {/*            x1={item[0].x * props.scale}*/}
                                {/*            y1={item[0].y * props.scale}*/}
                                {/*            x2={item[1].x * props.scale}*/}
                                {/*            y2={item[1].y * props.scale}*/}
                                {/*            // points={stringPOints}*/}
                                {/*            // strokeDasharray={'6,3'}*/}
                                {/*            strokeWidth={4}*/}
                                {/*            style={{fill:  'rgb(150, 147, 61)', fillOpacity: '0.5', stroke:  'rgb(150,33,33)'}}*/}
                                {/*        /> : null*/}
                                {/*}*/}
                                <line
                                    x1={item[0].x * props.scale}
                                    y1={item[0].y * props.scale}
                                    x2={item[1].x * props.scale}
                                    y2={item[1].y * props.scale}
                                    // points={stringPOints}
                                    strokeDasharray={'6,3'}
                                    strokeWidth={2}
                                    style={{fill: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)'}}
                                />
                                <circle
                                    cx={item[1].x * props.scale}
                                    cy={item[1].y * props.scale}
                                    r="3"
                                    fill={'black'}
                                />
                                {props.dataNamesForMaps.names === undefined ? null :
                                    <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>
                                }
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                        <feFlood floodColor="rgba(0,0,0,.9)"/>
                                        <feComposite  in="SourceGraphic"/>
                                    </filter>
                                </defs>
                            </g>
                        )
                    }
                    return(
                        componentNode
                    );
                }
                else {
                    return null;
                }

            }

            let stringPOints = '';
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
            });
            let componentNode = [];
            if (props.selectedScaleType ==='polygon') {
                componentNode.push(
                    <g key={`ScalepolygonMaps-${index}`}
                    >
                        <polygon

                            points={stringPOints}
                            strokeDasharray={'6,3'}
                            strokeWidth={1}
                            style={{fill: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                        />
                        {props.dataCkeckedSubsection.scale.data.param === undefined ? null :
                            <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataCkeckedSubsection.scale.data.param}</text>
                        }
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                <feFlood floodColor="rgba(0,0,0,.9)"/>
                                <feComposite  in="SourceGraphic"/>
                            </filter>
                        </defs>
                    </g>
                )
            }
            else {
                componentNode.push(
                    <g key={`scaleLianeMaps-${index}`}
                       // onMouseMove={onMouseMove}
                    >
                        <circle
                            cx={item[0].x * props.scale}
                            cy={item[0].y * props.scale}
                            r="3"
                            fill={'black'}
                        />
                        <line
                            x1={item[0].x * props.scale}
                            y1={item[0].y * props.scale}
                            x2={item[1].x * props.scale}
                            y2={item[1].y * props.scale}
                            // points={stringPOints}
                            strokeDasharray={'6,3'}
                            strokeWidth={2}
                            style={{fill: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'rgba(255,165,70,0.9)' : 'rgb(150, 147, 61)'}}
                        />
                        <circle
                            cx={item[1].x * props.scale}
                            cy={item[1].y * props.scale}
                            r="3"
                            fill={'black'}
                        />
                        {props.dataCkeckedSubsection.scale.data.param === undefined ? null :
                            <text  filter="url(#solidSCale)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataCkeckedSubsection.scale.data.param}</text>
                        }
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solidSCale">
                                <feFlood floodColor="rgba(0,0,0,.9)"/>
                                <feComposite  in="SourceGraphic"/>
                            </filter>
                        </defs>
                    </g>
                )
            }
            return(
                componentNode
            );
        }


    },
    createPlaceComponent: (props, item, index, middleX, middleY, editingStatus, drawStatus, statusItem, onMouseMove) => {
        
        if (drawStatus) {
            let stringPOints = '';
            let componentsPoint = [];
            let componentEntrances = [];
            let coordsPointEntrances = [];
            let coordsPointEntrancesNames = [];
            let stringPointEntrances = '';
            // const onSelectObjectsHolst = (e) => {
            //     props.onSelectObjectsHolst(e, index)
            // };
            
            const onSelectObjectsHolst = (e) => {
                e.stopPropagation();
            };
            const onContextMenu = (e, _index) => {
                // console.log('<<<<<<<<<<<<<<3', _index)
                e.preventDefault();
                e.stopPropagation();
                props.getContextMenu(e, index, _index, props.arrayEntrances);
            };
            const onMouseDownPoint = (e, index) => {
                // console.log('>>>>>>>>>>>>>', index, props.dataBySelectedObjects)
                if (props.dataBySelectedObjects !== null) {
                    props.onMouseDownPoint(index)
                }

            };
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                
                let colorPoint = 'black';
                // console.log('<<<<<<<<<<<<<<<<<1', props.arrayEntrancesForDraw)
                
                if (props.arrayEntrancesForDraw[index2] !== null) {
                    colorPoint = 'green';
                    // console.log('<<<<<<<<<<<<<<1', props.arrayEntrancesForDraw[index2], props.arrayEntrancesForDraw[index2 + 1])
                    if (props.arrayEntrancesForDraw[index2] !== props.arrayEntrancesForDraw[index2 + 1]) {
                        // console.log('<<<<<<<<<<<<<<<<<12', props.arrayEntrancesForDraw)
                        stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                        coordsPointEntrances.push(stringPointEntrances);
                        coordsPointEntrancesNames.push(props.arrayEntrancesForDraw[index2])
                        stringPointEntrances = '';
                    }
                    else {
                        
                        stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                    }
                    
                    // console.log('<<<<<<<<<<<<<<1', stringPointEntrances)
                    if (index2 === item2.length - 1) {
                        coordsPointEntrances.push(stringPointEntrances);
                        coordsPointEntrancesNames.push(props.arrayEntrancesForDraw[index2])
                        // console.log('<<<<<<<<<<<<<<22', coordsPointEntrances, stringPointEntrances)
                        stringPointEntrances = '';
                        
                    }
                
                    else if (index2 === 0) {
                        if (props.arrayEntrancesForDraw[props.arrayEntrancesForDraw.length -1] !== null) {
                            
                            if (props.arrayEntrancesForDraw[props.arrayEntrancesForDraw.length -1] === props.arrayEntrancesForDraw[index2]) {
                                // console.log('<<<<<<<<<<<<<<<<<12', props.arrayEntrancesForDraw)
                                stringPointEntrances = '';
                                // console.log('<<<<<<<<<<<<<<5', item, props.arrayEntrances.length -1)
                                stringPointEntrances = stringPointEntrances + `${item[props.arrayEntrancesForDraw.length -1].x * props.scale},${item[props.arrayEntrancesForDraw.length -1].y * props.scale} `;
                                stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                            }
                            if (props.arrayEntrancesForDraw[index2] !== props.arrayEntrancesForDraw[index2 + 1]) {
                                coordsPointEntrances.push(stringPointEntrances);
                                coordsPointEntrancesNames.push(props.arrayEntrancesForDraw[index2])
                                stringPointEntrances = '';
                            }
                            
                        }
                    }
                    // if (props.arrayEntrances[index][index2] !== null) {
                    //     stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                    // }
                    // else if (props.arrayEntrances[index][index2 + 1] === null) {
                    //     coordsPointEntrances.push(stringPointEntrances);
                    //     stringPointEntrances = '';
                    // }
                }
                else if (props.arrayEntrancesForDraw[index2] === null) {
                    // console.log('<<<<<<<<<<<<<<2', stringPointEntrances)
                    if (stringPointEntrances !== ''){
                        coordsPointEntrances.push(stringPointEntrances);
                        coordsPointEntrancesNames.push(props.arrayEntrancesForDraw[index2])
                        // console.log('<<<<<<<<<<<<<<22', coordsPointEntrances, stringPointEntrances)
                        stringPointEntrances = '';
                    }
                    
                }
    
                // props.onClickPoint()
                // console.log('<<<<<<<<<<<<<<', coordsPointEntrances, stringPointEntrances)
                if (props.checkedPlacesInputCreate) {
                    
                    const onSelectPoint =(e) => {
                        e.stopPropagation();
                        props.onClickPoint(index, index2);
                    }
                    componentsPoint.push(
        
                        <g key={`placesMapsLyne-${index2}-${index}`}
                           onMouseDown={(e) => onMouseDownPoint(e, index2)}
                           onContextMenu={(e) => onContextMenu(e, index2)}
                            // onMouseMove={onMouseMove}
                            
                            onClick={onSelectPoint}
                           style={{
                               cursor: 'move',
                               userSelect:'none'
                           }}
                        >
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="5"
                                fill={colorPoint}
                                // strokeWidth={1}
                                // stroke={'rgb(255,255,255)'}
                            />
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="6"
                                // fill={'black'}
                                fill={'rgba(0,0,0,0)'}
                                strokeWidth={2}
                                stroke={'rgb(255,255,255)'}
                            />
                        </g>
    
                    );
                }
                else {
                    componentsPoint.push(
        
                        <g key={`placesMapsLyne-${index2}-${index}`}
                           onMouseDown={(e) => onMouseDownPoint(e, index2)}
                           onContextMenu={(e) => onContextMenu(e, index2)}
                            // onMouseMove={onMouseMove}
                           style={{
                               cursor: 'move',
                               userSelect:'none'
                           }}
                        >
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="5"
                                fill={colorPoint}
                                // strokeWidth={1}
                                // stroke={'rgb(255,255,255)'}
                            />
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="6"
                                // fill={'black'}
                                fill={'rgba(0,0,0,0)'}
                                strokeWidth={2}
                                stroke={'rgb(255,255,255)'}
                            />
                        </g>
    
                    );
                }
                
    
                
            });
            
            coordsPointEntrances.forEach((item3, index3) => {
                // props.objDataByMarker
                if (props.objDataByMarker[coordsPointEntrancesNames[index3]] !== undefined) {
                    componentEntrances.push(
                        <g key={`placesMapsLyneTrack-${index3}-${index}`}
            
                            // onMouseMove={onMouseMove}
                        >
                            <polyline
                                points={item3}
                                // strokeDasharray={'6,3'}
                                strokeWidth={6}
                                fill="none"
                                stroke={props.objDataByMarker[coordsPointEntrancesNames[index3]].color}
                            />
                        </g>
                    )
                }
                
            })
            
            
            
            return(
                <g key={`placesMapsPoly-${index}-${statusItem}`}
                   onClick={onSelectObjectsHolst}
                   style={{cursor: 'pointer'}}
                   // onMouseMove={onMouseMove}
                >
                    <polygon

                        points={stringPOints}
                        strokeDasharray={'6,3'}
                        strokeWidth={1}
                        style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                    />

                    <polygon
                        // onContextMenu={(e) => onContextMenu(e, index2)}
                        points={stringPOints}
                        strokeDasharray={'6,3'}
                        strokeWidth={1}
                        style={{fill:  'black', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                    />
                    {componentEntrances}
                    {componentsPoint}
                    
                    {/*<defs>*/}
                    {/*    <filter x="0" y="0"  width="1" height="1" id="solid">*/}
                    {/*        <feFlood floodColor="rgba(0,0,0,.9)"/>*/}
                    {/*        <feComposite  in="SourceGraphic"/>*/}
                    {/*    </filter>*/}
                    {/*</defs>*/}
                    {/*<text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.markers[index]}</text>*/}
                </g>
            );
        }
        else {
            if (editingStatus) {
                
                let stringPOints = '';
                let componentsPoint = [];
                let componentEntrances = [];
                let coordsPointEntrances = [];
                let stringPointEntrances = '';
                
                const onSelectObjectsHolst = (e) => {
                    
                    props.onSelectObjectsHolst(e, index)
                };
                
                const _onMouseMove = (e) => {
                    onMouseMove(e, index);
                }
    
                
                
                
                item.forEach((item2, index2) => {
                    stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                    // console.log('1111111111', +new Date(), stringPOints)
                    componentsPoint.push(
                        <g key={`placesMapsLyne-${index2}-${index}`}

                        >
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="4"
                                fill={'black'}
                                // strokeWidth={1}
                                // stroke={'rgb(255,255,255)'}
                            />
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="6"
                                // fill={'black'}
                                strokeWidth={2}
                                stroke={'rgb(255,255,255)'}
                            />
                        </g>

                    );
                    // console.log('<<<<<<<<<<<<<<1', props.arrayEntrances[index])
                    if (props.arrayEntrances[index] !== undefined && props.arrayEntrances[index] !== null) {
                        if (props.arrayEntrances[index][index2] !== null) {
                            // colorPoint = 'green';
                            if (props.arrayEntrances[index][index2] !== props.arrayEntrances[index][index2 + 1]) {
                                stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                                coordsPointEntrances.push(stringPointEntrances);
                                stringPointEntrances = '';
                            }
                            else {
                                stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                            }
                            
                            // console.log('<<<<<<<<<<<<<<1', stringPointEntrances)
                            if (index2 === item2.length - 1) {
                                coordsPointEntrances.push(stringPointEntrances);
                                // console.log('<<<<<<<<<<<<<<22', coordsPointEntrances, stringPointEntrances)
                                stringPointEntrances = '';
            
                            }
        
                            else if (index2 === 0) {
                                if (props.arrayEntrances[index][props.arrayEntrances[index].length-1] !== null) {
                                    if (props.arrayEntrances[index][props.arrayEntrances[index].length-1] === props.arrayEntrances[index][index2]) {
                                        stringPointEntrances = '';
                                        // console.log('<<<<<<<<<<<<<<5', item, props.arrayEntrances.length -1)
                                        stringPointEntrances = stringPointEntrances + `${item[props.arrayEntrances[index].length -1].x * props.scale},${item[props.arrayEntrances[index].length -1].y * props.scale} `;
                                        stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                                    }
                                    if (props.arrayEntrances[index][index2] !== props.arrayEntrances[index][index2 + 1]) {
                                        coordsPointEntrances.push(stringPointEntrances);
                                        stringPointEntrances = '';
                                    }
                                    
                                }
                            }
                            // if (props.arrayEntrances[index][index2] !== null) {
                            //     stringPointEntrances = stringPointEntrances + `${item2.x * props.scale},${item2.y * props.scale} `;
                            // }
                            // else if (props.arrayEntrances[index][index2 + 1] === null) {
                            //     coordsPointEntrances.push(stringPointEntrances);
                            //     stringPointEntrances = '';
                            // }
                        }
                        else if (props.arrayEntrances[index][index2] === null) {
                            // console.log('<<<<<<<<<<<<<<2', stringPointEntrances)
                            if (stringPointEntrances !== ''){
                                coordsPointEntrances.push(stringPointEntrances);
                                // console.log('<<<<<<<<<<<<<<22', coordsPointEntrances, stringPointEntrances)
                                stringPointEntrances = '';
                            }
        
                        }
                    }
                    
                });
    
                
                coordsPointEntrances.forEach((item3, index3) => {
                    componentEntrances.push(
                        <g key={`placesMapsLyneTrack23-${index3}-${index}`}
                
                            // onMouseMove={onMouseMove}
                        >
                            <polyline
                                points={item3}
                                // strokeDasharray={'6,3'}
                                strokeWidth={4}
                                fill="none"
                                stroke={'#46b875'}
                            />
                        </g>
                    )
                })
    
                // console.log('<<<<<<<<<<<<<<<<,', componentEntrances, statusItem)
                
                if (statusItem === 'item') {
                    return(
                        <g key={`polygonMapsItem-${index}`}
                           onClick={onSelectObjectsHolst}
                           style={{cursor: 'pointer'}}
                           onMouseMove={_onMouseMove}
                        >
                            {/*{*/}
                            {/*    props.dataBySelectedObjects !== null ?  props.dataBySelectedObjects.index === index*/}
                            {/*        ?*/}
                            {/*        <>*/}

                            {/*            <polygon*/}

                            {/*                points={stringPOints}*/}
                            {/*                strokeDasharray={'6,3'}*/}
                            {/*                strokeWidth={1}*/}
                            {/*                style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}*/}
                            {/*            />*/}
                            {/*            {componentsPoint}*/}
                            {/*        </>*/}
                            {/*        :*/}
                            {/*        null : null*/}
                            {/*}*/}
                            <polygon

                                points={stringPOints}
                                strokeDasharray={'6,3'}
                                strokeWidth={1}
                                style={{fill:  'black', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                            />
    
                            {componentEntrances}
                        </g>
                        
                    );
                }
                else if (statusItem === 'name') {
                    // console.log('>>>>>>>>>', props.dataNamesForMaps.markers, index)
                    if (props.dataNamesForMaps.markers) {
                        return(
                            <g key={`polygonMapsName-${index}`}
                               onClick={onSelectObjectsHolst}
                               style={{cursor: 'pointer'}}
                                // onMouseMove={onMouseMove}
                            >
                                {/*{*/}
                                {/*    props.dataBySelectedObjects !== null ?  props.dataBySelectedObjects.index === index*/}
                                {/*        ?*/}
                                {/*        <>*/}
            
                                {/*            <polygon*/}
            
                                {/*                points={stringPOints}*/}
                                {/*                strokeDasharray={'6,3'}*/}
                                {/*                strokeWidth={1}*/}
                                {/*                style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}*/}
                                {/*            />*/}
                                {/*            {componentsPoint}*/}
                                {/*        </>*/}
                                {/*        :*/}
                                {/*        null : null*/}
                                {/*}*/}
            
                                <defs>
                                    <filter x="0" y="0"  width="1" height="1" id="solid">
                                        <feFlood floodColor="rgba(0,0,0,.9)"/>
                                        <feComposite  in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.markers[index]}</text>
                            </g>
                        );
                    }
    
                    return(null);
                    
                }

            }

            let stringPOints = '';
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
            });
            if (statusItem === 'item') {
                return(
                    <g key={`polygonMapsItem-${index}`}
                       // onMouseMove={onMouseMove}
                    >

                        <polygon

                            points={stringPOints}
                            // strokeDasharray={'6,3'}
                            strokeWidth={1}
                            style={{fill:  'rgba(0,0,0,.6)' , fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                        />

                    </g>
                );
            }
            else if (statusItem === 'name') {
                return(
                    <g key={`polygonMapsName-${index}`}

                    >


                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.3)"/>
                                <feComposite  in="SourceGraphic"/>
                            </filter>
                        </defs>
                        <text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataCkeckedSubsection.places.data.markers[index]}</text>
                    </g>
                );
            }

        }


    },
    createOtherComponent: (props, item, index, middleX, middleY, editingStatus, drawStatus, statusItem, onMouseMove, color) => {
        if (drawStatus) {
            let stringPOints = '';
            let componentsPoint = [];
            const onSelectObjectsHolst = (e) => {
                e.stopPropagation();
            };
            const onMouseDownPoint = (e, index) => {
                // console.log('>>>>>>>>>>>>>', index, props.dataBySelectedObjects)
                if (props.dataBySelectedObjects !== null) {
                    props.onMouseDownPoint(index)
                }

            };
            
            const onContextMenu = (e, _index) => {
                e.preventDefault();
                e.stopPropagation();
                props.getContextMenu(e, index, _index);
            };
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                componentsPoint.push(
                    <g key={`otherMapsLyne-${index2}-${index}`}
                       onMouseDown={(e) => onMouseDownPoint(e, index2)}
                       onContextMenu={(e) => onContextMenu(e, index2)}
                       style={{
                           cursor: 'move',
                           userSelect:'none'
                       }}
                    >
                        <circle
                            cx={item2.x * props.scale}
                            cy={item2.y * props.scale}
                            r="4"
                            fill={'black'}
                            // strokeWidth={1}
                            // stroke={'rgb(255,255,255)'}
                        />
                        <circle
                            cx={item2.x * props.scale}
                            cy={item2.y * props.scale}
                            r="6"
                            // fill={'black'}
                            strokeWidth={2}
                            stroke={'rgb(255,255,255)'}
                        />
                    </g>

                )
            });

            return(
                <g key={`otherpolygonMaps-${index}`}
                   onClick={onSelectObjectsHolst}
                   style={{cursor: 'pointer'}}
                >
                    <polygon

                        points={stringPOints}
                        strokeDasharray={'6,3'}
                        strokeWidth={2}
                        style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                    />


                    <polygon

                        points={stringPOints}
                        strokeDasharray={'6,3'}
                        strokeWidth={2}
                        style={{fill: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                    />
                    {/*{props.dataNamesForMaps.names === undefined ? null :*/}
                    {/*    <text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>*/}
                    {/*}*/}
                    {componentsPoint}
                    <defs>
                        <filter x="0" y="0"  width="1" height="1" id="solid">
                            <feFlood floodColor="rgba(0,0,0,.5)"/>
                            <feComposite  in="SourceGraphic"/>
                        </filter>
                    </defs>
                </g>
            );
        }
        else {
            if (editingStatus) {
                let stringPOints = '';
                let componentsPoint = [];
                const onSelectObjectsHolst = (e) => {
                    props.onSelectObjectsHolst(e, index)
                };
                const _onMouseMove = (e) => {
                    onMouseMove(e, index);
                }
                item.forEach((item2, index2) => {
                    stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;
                    componentsPoint.push(
                        <g key={`OtherMapsLyneLine-${index2}-${index}`}>
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="4"
                                fill={'black'}
                                // strokeWidth={1}
                                // stroke={'rgb(255,255,255)'}
                            />
                            <circle
                                cx={item2.x * props.scale}
                                cy={item2.y * props.scale}
                                r="6"
                                // fill={'black'}
                                strokeWidth={2}
                                stroke={'rgb(255,255,255)'}
                            />
                        </g>

                    )
                });

                if (statusItem === 'item') {
                    
                    return(
                        <g key={`OtherMapsItem-${index}`}
                           onClick={onSelectObjectsHolst}
                           style={{cursor: 'pointer'}}
                           onMouseMove={_onMouseMove}
                        >
                            {/*{*/}
                            {/*    props.dataBySelectedObjects !== null ?  props.dataBySelectedObjects.index === index*/}
                            {/*        ?*/}
                            {/*        <>*/}
                            {/*            <polygon*/}

                            {/*                points={stringPOints}*/}
                            {/*                strokeDasharray={'6,3'}*/}
                            {/*                strokeWidth={2}*/}
                            {/*                style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}*/}
                            {/*            />*/}

                            {/*            {componentsPoint}*/}
                            {/*        </>*/}
                            {/*        :*/}
                            {/*        null : null*/}
                            {/*}*/}
                            <polygon

                                points={stringPOints}
                                strokeDasharray={'6,3'}
                                strokeWidth={2}
                                style={{fill: props.modeColorScheme === 'light' ? color ? color : 'black' : color ? color : 'rgb(150, 147, 61)', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                            />

                        </g>
                    );
                }
                else if (statusItem === 'name') {
                    return(
                        <g key={`OtherMapsName-${index}`}
                           onClick={onSelectObjectsHolst}
                           style={{cursor: 'pointer'}}
                        >
                            {/*{*/}
                            {/*    props.dataBySelectedObjects !== null ?  props.dataBySelectedObjects.index === index*/}
                            {/*        ?*/}
                            {/*        <>*/}
                            {/*            <polygon*/}

                            {/*                points={stringPOints}*/}
                            {/*                strokeDasharray={'6,3'}*/}
                            {/*                strokeWidth={2}*/}
                            {/*                style={{fill: 'red', fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}*/}
                            {/*            />*/}

                            {/*            {componentsPoint}*/}
                            {/*        </>*/}
                            {/*        :*/}
                            {/*        null : null*/}
                            {/*}*/}

                            {props.dataNamesForMaps.names === undefined ? null :
                                <text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNamesForMaps.names[index]}</text>
                            }
                            <defs>
                                <filter x="0" y="0"  width="1" height="1" id="solid">
                                    <feFlood floodColor="rgba(0,0,0,.5)"/>
                                    <feComposite  in="SourceGraphic"/>
                                </filter>
                            </defs>
                        </g>
                    );
                }


            }


            let stringPOints = '';
            item.forEach((item2, index2) => {
                stringPOints = stringPOints + `${item2.x * props.scale},${item2.y * props.scale} `;

            });

            if (statusItem === 'item') {
                // console.log('>>>>>>>><<<<<<<<<<<<');
                return(
                    <g key={`OtherMapsItem-${index}-${stringPOints}`}>

                        <polygon

                            points={stringPOints}
                            // strokeDasharray={'6,3'}
                            strokeWidth={2}
                            style={{fill: color ? color : 'rgba(0,0,0,.3)' , fillOpacity: '0.5', stroke: props.modeColorScheme === 'light' ? 'black' : 'rgb(150, 147, 61)'}}
                        />

                    </g>
                );
            }
            else if (statusItem === 'name') {
                return(
                    <g key={`OtherMapsName-${index}-${stringPOints}`}>

                        {props.dataNames === undefined ? null :
                            <text  filter="url(#solid)" style={{pointerEvents: 'none'}} fill="#fff" fontWeight={300}  fontSize={11} textAnchor='middle' x={middleX*props.scale} y={middleY*props.scale - 10}>{props.dataNames.names[index]}</text>
                        }
                        <defs>
                            <filter x="0" y="0"  width="1" height="1" id="solid">
                                <feFlood floodColor="rgba(0,0,0,.5)"/>
                                <feComposite  in="SourceGraphic"/>
                            </filter>
                        </defs>
                    </g>
                );
            }

        }


    },
    createBackgroundSvgElement: (props) => {
        let componentNodeBack = [];


        for (let key in props.dataCkeckedSubsection) {
            if (!props.dataCkeckedSubsection[key].statusBackground) {
                if (props.type === 'polygons') {
                    
                    let polygonStatus = 'polygonIn';
                    if (props.selectedNavigationGeometryType === 'polygonIn') {
                        polygonStatus = 'polygonOut';
                    }
                    // console.log('>>>>>>>>>>>>>', props.dataCkeckedSubsection[key])
                    if (props.dataCkeckedSubsection[key].data[polygonStatus] !== undefined) {
                        props.dataCkeckedSubsection[key].data[polygonStatus].coords.forEach((item, index) => {
                            var middleX = 0;
                            var middleY = 0;
                            if (item[0] !== undefined) {
                                let objMiddlePoint = tools.findMeddlePoint(item);
                                middleX = objMiddlePoint.middleX;
                                middleY = objMiddlePoint.middleY;
                            }
                            componentNodeBack.push(
                                tools.createOtherComponent({
                                    ...props,
                                }, item, index, middleX, middleY, false, false, 'item')
                            )
                        });
                    }
                    
                }
                
                continue;
            }
            if (key === 'devices') {
                props.dataCkeckedSubsection[key].data.coords.forEach((item, index) => {

                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createDeviceComponent(props, item, index, middleX, middleY, false, false, 'item')
                    )
                    componentNodeBack.push(
                        tools.createDeviceComponent(props, item, index, middleX, middleY, false, false, 'name')
                    )
                })
            }
            if (key === 'places') {

                props.dataCkeckedSubsection[key].data.coords.forEach((item, index) => {

                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createPlaceComponent(props, item, index, middleX, middleY, false, false,'item')
                    )
                    componentNodeBack.push(
                        tools.createPlaceComponent(props, item, index, middleX, middleY, false, false, 'name')
                    )
                })
            }
            if (key === 'zones') {
                props.dataCkeckedSubsection[key].data[props.selectedGruopMarker].coords.forEach((item, index) => {
                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createOtherComponent({
                            ...props,
                            dataNames: props.dataCkeckedSubsection[key].data[props.selectedGruopMarker]
                        }, item, index, middleX, middleY, false, false, 'item')
                    )
                    componentNodeBack.push(
                        tools.createOtherComponent({
                            ...props,
                            dataNames: props.dataCkeckedSubsection[key].data[props.selectedGruopMarker]
                        }, item, index, middleX, middleY, false, false, 'name')
                    )
                });
            }
            if (key === 'tracks') {

                props.dataCkeckedSubsection[key].data[props.typeTrack].coords.forEach((item, index) => {
                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createTrackComponent({
                            ...props,
                            dataNames: props.dataCkeckedSubsection[key].data[props.typeTrack]
                        }, item, index, middleX, middleY, false)
                    )
                });
            }
            if (key === 'polygons') {
                // console.log('1111111111', props.dataCkeckedSubsection[key].data)
                props.dataCkeckedSubsection[key].data.polygonIn.coords.forEach((item, index) => {
                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createOtherComponent({
                            ...props,
                        }, item, index, middleX, middleY, false, false, 'item',null, 'green')
                    )
                });
                props.dataCkeckedSubsection[key].data.polygonOut.coords.forEach((item, index) => {
                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createOtherComponent({
                            ...props,
                        }, item, index, middleX, middleY, false, false, 'item',null, 'blue')
                    )
                });
            }
            if (key === 'background') {
                props.dataCkeckedSubsection[key].data.coords.forEach((item, index) => {

                    var middleX = 0;
                    var middleY = 0;
                    if (item[0] !== undefined) {
                        let objMiddlePoint = tools.findMeddlePoint(item);
                        middleX = objMiddlePoint.middleX;
                        middleY = objMiddlePoint.middleY;
                    }
                    componentNodeBack.push(
                        tools.createOtherComponent(props, item, index, middleX, middleY, false, false, 'item')
                    )
                })
            }
            if (key === 'scale') {

                var middleX = 0;
                var middleY = 0;
                if (props.dataCkeckedSubsection[key].data.coords[0] !== undefined) {
                    let objMiddlePoint = tools.findMeddlePoint(props.dataCkeckedSubsection[key].data.coords);
                    middleX = objMiddlePoint.middleX;
                    middleY = objMiddlePoint.middleY;
                }
                componentNodeBack.push(
                    tools.createScaleComponent(props, props.dataCkeckedSubsection[key].data.coords, 0, middleX, middleY, false)
                )
            }
        }

        return componentNodeBack;
    },
    findMeddlePoint: (item) => {
        var middleX = 0;
        var middleY = 0;
        var minCoorX = item[0].x;
        var minCoorY = item[0].y;
        var maxCoorX = item[0].x;
        var maxCoorY = item[0].y;
        for (let j = 1; j < item.length; j++) {
            let p = item[j];
            if (minCoorX > p.x) {
                minCoorX = p.x;
            }
            if (maxCoorX < p.x) {
                maxCoorX = p.x;
            }
            if (minCoorY > p.y) {
                minCoorY = p.y;
            }
            if (maxCoorY < p.y) {
                maxCoorY = p.y;
            }

        }
        middleX = (maxCoorX + minCoorX) / 2;
        middleY = (minCoorY + maxCoorY) / 2;

        return {
            middleX:middleX,
            middleY:middleY
        }

    },
    findMeddlePointArray: (item) => {
        // console.log('>>>>>>>>>>>>>>>>', item);
        var middleX = 0;
        var middleY = 0;
        var minCoorX = item[0].x;
        var minCoorY = item[0].y;
        var maxCoorX = item[0].x;
        var maxCoorY = item[0].y;
        for (let j = 1; j < item.length; j++) {
            let p = item[j];
            if (minCoorX > p.x) {
                minCoorX = p.x;
            }
            if (maxCoorX < p.x) {
                maxCoorX = p.x;
            }
            if (minCoorY > p.y) {
                minCoorY = p.y;
            }
            if (maxCoorY < p.y) {
                maxCoorY = p.y;
            }

        }
        middleX = (maxCoorX + minCoorX) / 2;
        middleY = (minCoorY + maxCoorY) / 2;

        return {
            middleX:middleX,
            middleY:middleY
        }

    },
    createSvgElement: (props) => {
        let componentNode = [];
        componentNode = tools.createBackgroundSvgElement(props);

        const onMouseMove = (e, index) => {
            
            if (index !== props.coords.length - 1) {
                // console.log('>>>>>>>>>>>>>>>>>>>>>', props.coords);
                // e.stopPropagation();
            }
        };
        // console.log('>>>>>>>>>>>>>>>>>>>>>', props.coords.length)
        props.coords.forEach((item,index) => {
            var middleX = 0;
            var middleY = 0;
            if (item[0] !== undefined) {
                let objMiddlePoint = tools.findMeddlePoint(item);
                middleX = objMiddlePoint.middleX;
                middleY = objMiddlePoint.middleY;
            }


            if (props.type === 'devices') {
                if (Array.isArray(item) === false) {

                    componentNode.push(
                        tools.createDeviceComponent(props, item, index, middleX, middleY, true, false, 'item')
                    )
                }
            }


            else if (props.type === 'tracks') {
                if (Array.isArray(item)) {

                    componentNode.push(
                        tools.createTrackComponent(props, item, index, middleX, middleY, true, false, 'item')
                    )
                }
            }
            else if (props.type === 'places') {
                if (Array.isArray(item)) {
                    // console.log('1111111111', +new Date(), item)
                    componentNode.push(
                        tools.createPlaceComponent(props, item, index, middleX, middleY, true, false, 'item', onMouseMove)
                    );
                }
            }
            else if (props.type === 'scale') {
                if (Array.isArray(item)) {
                    componentNode.push(
                        tools.createScaleComponent(props, item, index, middleX, middleY, true, false, 'item')
                    );
                }
            }
            else {
                if (Array.isArray(item)) {

                    componentNode.push(
                        tools.createOtherComponent(props, item, index, middleX, middleY, true, false, 'item', onMouseMove)
                    )
                }
            }


        });

        props.coords.forEach((item,index) => {
            var middleX = 0;
            var middleY = 0;
            if (item[0] !== undefined) {
                let objMiddlePoint = tools.findMeddlePoint(item);
                middleX = objMiddlePoint.middleX;
                middleY = objMiddlePoint.middleY;
            }


            if (props.type === 'devices') {
                if (Array.isArray(item) === false) {

                    componentNode.push(
                        tools.createDeviceComponent(props, item, index, middleX, middleY, true, false, 'name')
                    )
                }
            }


            else if (props.type === 'tracks') {
                if (Array.isArray(item)) {

                    componentNode.push(
                        null
                    )
                }
            }
            else if (props.type === 'places') {
                if (Array.isArray(item)) {
                    // console.log('1111111111', +new Date(), item)
                    componentNode.push(
                        tools.createPlaceComponent(props, item, index, middleX, middleY, true, false, 'name')
                    );
                }
            }
            else if (props.type === 'scale') {
                if (Array.isArray(item)) {
                    componentNode.push(
                        tools.createScaleComponent(props, item, index, middleX, middleY, true, false, 'name')
                    );
                }
            }
            else {
                if (Array.isArray(item)) {

                    componentNode.push(
                        tools.createOtherComponent(props, item, index, middleX, middleY, true, false, 'name')
                    )
                }
            }


        });


        return componentNode;
    },
    createSvgDrawElement: (props) => {
        // console.log('>>>>>>>>>>>>>1111111', props.dataBySelectedObjects)
        let componentNode = [];

        // coords: (2) [498.0875488281259, 1201.269970703127]
        // index: 4
        // is_opened: true
        // marker: "I5"
        // type: null


        // componentNode = tools.createBackgroundSvgElement(props);

        // console.log('>>>>>>>>>>>>>>>>>>>>>', props.dataBySelectedObjects.coords.x)
        var middleX = 0;
        var middleY = 0;
        if (props.arrayCoordsForDraw[0] !== undefined) {
            let objMiddlePoint = tools.findMeddlePoint(props.arrayCoordsForDraw);
            middleX = objMiddlePoint.middleX;
            middleY = objMiddlePoint.middleY;
        }

        // console.log('>>>>>>>>>>>>>>>>>>', middleX, middleY)



        if (props.type === 'devices') {
            // console.log('>>>>>>>>>>>>>>>>>>>>>', props.arrayCoordsForDraw)
            if (props.arrayCoordsForDraw[0] !== undefined) {
                componentNode.push(
                    tools.createDeviceComponent(props, props.arrayCoordsForDraw, props.dataBySelectedObjects.index, 0, 0, true, true)
                )
            }

        }


        else if (props.type === 'tracks') {
            if (props.arrayCoordsForDraw[0] !== undefined) {

                componentNode.push(
                    tools.createTrackComponent(props, props.arrayCoordsForDraw, props.dataBySelectedObjects.index, middleX, middleY, true, true)
                )
            }
        }
        else if (props.type === 'places') {
            if (props.arrayCoordsForDraw[0] !== undefined) {

                componentNode.push(
                    tools.createPlaceComponent(props, props.arrayCoordsForDraw, props.dataBySelectedObjects.index, middleX, middleY, true, true)
                );
            }
        }
        else if (props.type === 'scale') {
            if (props.arrayCoordsForDraw[0] !== undefined) {
                componentNode.push(
                    tools.createScaleComponent(props, props.arrayCoordsForDraw, props.dataBySelectedObjects.index, middleX, middleY, true, true)
                );
            }
        }
        else {
            if (props.arrayCoordsForDraw[0] !== undefined) {

                componentNode.push(
                    tools.createOtherComponent(props, props.arrayCoordsForDraw, props.dataBySelectedObjects.index, middleX, middleY, true, true)
                )
            }
        }


        return componentNode;
    }
};

export default tools;