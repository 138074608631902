import React from 'react';
import './style/style_left_panel.less';
// import GlobalDispatchContext from "../../global_dispatch_context";
import LeftPanel from "./components/left_panel";
import styled from 'styled-components';

const SCLeftPanelContent = styled.div`
   opacity: ${props => props.statusOpasity ? 0.5 : 1};
   pointer-events: ${props => props.statusOpasity ? 'none' : 'auto'};
`;

const LeftPanelMain = (props) => {


    // console.log('>>>>>>>>>>>>>>', props.statusOpasity)
    return (
        <SCLeftPanelContent statusOpasity={props.statusOpasity} className={'leftPanel'} id={'leftPanel'} >


            <LeftPanel
                setStatusShowModalOfNewGroupZones={props.setStatusShowModalOfNewGroupZones}
            />

        </SCLeftPanelContent>
    );
}

export default LeftPanelMain;