import React, {useContext, useEffect, useState} from 'react';
import './style/style_top_panel.less';
import './style/toppanelStyle.css';
import GlobalDispatchContext from "../../global_dispatch_context";
import {
    DefaultButton,
    FontWeights,
    IconButton,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack
} from 'office-ui-fabric-react';
import toolsForTopPanel from './tools';
import {FontSizes} from "@uifabric/styling";
import {getTheme} from 'office-ui-fabric-react/lib/Styling';
import {TextField} from "office-ui-fabric-react/lib/TextField";
import {ChoiceGroup} from "office-ui-fabric-react/lib/ChoiceGroup";
import {DetailsList, DetailsListLayoutMode, SelectionMode} from "office-ui-fabric-react/lib/DetailsList";
import {CopyToClipboard} from "react-copy-to-clipboard";
import styled from "styled-components";
import tools from "../../tools";
// import {DetailsList, DetailsListLayoutMode, SelectionMode,} from 'office-ui-fabric-react/lib/DetailsList';


const theme = getTheme();
// const { fonts } = theme;
const BoxLoader = styled.span`
    display: block;
    width: 20px;
    height: 20px;
    background: ${props => props.statusConflict ? 'rgba(128, 128, 128,.5)' : 'rgba(0, 0, 255,.5)'};
    border-radius: 50%;
    margin: 0 auto;
`;

const CheckInput = styled.input`
    display: block;
    width: 20px;
    height: 20px;
    //color: rgba(0, 0, 255,.5);
    //border-color: rgba(0, 0, 255,.5);
    //border-radius: 50%;
    margin: 0;
    opacity: 0.5;
`;

const CheckDiv = styled.div`
    display: block;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 255,.5);
    //border-color: rgba(0, 0, 255,1);
    border-radius: 50%;
    margin: 0 auto;
`;




const TopPanelMain = (props) => {

    const {state, dispatch} = useContext(GlobalDispatchContext);
    const [statusShowModal, setStatusShowModal] = useState(false);
    const [valueGeo, setValueGeo] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [componentsListNode, setComponentsListNode] = useState(null);
    const [statusShowModalConflict, setStatusShowModalConflict] = useState(false);
    const [listDataCheckbox, setListDataCheckbox] = useState([]);
    
    // const onClickButton = (e) => {
    //     dispatch({
    //         type:'GET_MODE_DISPLAY',
    //         mode: state.modeDisplay === 'light' ? 'dark' : 'light'
    //     })
    // };
    
    useEffect(() => {
        let componentsList = [];
        let listDataCheckbox = [];
        state.dataConflict.forEach((item, index) => {
            
            for (var key in item) {
                // console.log('>>>>>>>>>>>>>', state.dataCompareModifiedStateInInitialState[index][key])
    
                // arrTemp.push({
                //     conflictData: item[key],
                //     modifiedStateInInitialState: state.dataCompareModifiedStateInInitialState[index][key]
                // })
                componentsList.push(
                    {
                        floor: item[key].floor,
                        key: index + 'conflictlist' + key,
                        isExpanded: true,
                        name: key,
                        statusConflict: item[key].statusConflict,
                        modifiedStateInInitialState:state.dataCompareModifiedStateInInitialState[index][key].statusConflict,
                        willToSaveModifare: ''

                    }
                );
            }
            
            
            
        })
        componentsList.sort(function (a, b) {
            return ('' + a.name).localeCompare(b.name);
        })
        componentsList.forEach((item2, index2) => {
            let _obj = {
                checkboxLocal: false,
                checkboxServer: false,
            }
            // console.log('>>>>>>>>>>>>>', item2.statusConflict, item2.modifiedStateInInitialState);
            
            if (item2.statusConflict === false && item2.modifiedStateInInitialState === false) {
                _obj = {
                    checkboxLocal: true,
                    checkboxServer: false,
                }
            }
            else if (item2.statusConflict === false && item2.modifiedStateInInitialState) {
                _obj = {
                    checkboxLocal: false,
                    checkboxServer: true,
                }
            }
            else if (item2.statusConflict && item2.modifiedStateInInitialState === false) {
                _obj = {
                    checkboxLocal: true,
                    checkboxServer: false,
                }
            }
            item2['checkboxData'] = _obj;
            item2['index'] = index2;
            item2.willToSaveModifare = _obj.checkboxLocal ? 'Мои изменения' : _obj.checkboxServer ? 'Изменения с сервера' : 'Изменений не было';
            
            listDataCheckbox.push(_obj)
        });
        
        
        
        
        // console.log('>>>>>>>>>>>>>', componentsList)
        
        // isExpanded: true
        // key: "0listPlanSets"
        // latLon: "55.78611807, 49.12126695"
        // latitude: "55.78611807"
        // longitude: "49.12126695"
        // name: "Золотое Яблоко, Казань, Пушкина 2"
        // url: "/make_geometry/b0a5f1da-4a73-4ab9-a2ab-6b30faff417c/"
        // uuid: "b0a5f1da-4a73-4ab9-a2ab-6b30faff417c"
        
        setComponentsListNode(componentsList);
        // _setComponentsListNode(componentsList);
        // _setColumn(
        //     [...columns]
        // )
        
    }, [
        state.dataConflict,
    ]);
    const onClickSave = (e) => {
        // console.log('>>>>>>>>>>>>>>>', toolsForTopPanel.save(state));
        props.saveData(toolsForTopPanel.save(state));
        
    };
    
    const onClickSaveAfterConflict = (e) => {
    
        // dispatch({
        //     type:'TO_FIX_CONFLICT',
        //     listFixConflict: componentsListNode
        // })
        // let _state = toolsForTopPanel.fixConflict(state, componentsListNode);
        props.saveDataAfterConflict(toolsForTopPanel.fixConflict(state, componentsListNode));
        setStatusShowModalConflict(false)
    };

    const onClickShowModal = (e) => {

        setStatusShowModal(true);
    };
    const stackTokens = {
        childrenGap: 10,
        padding: 9
    };

    const iconButtonStyles = mergeStyleSets({
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: theme.palette.neutralDark
        }
    });

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            overflow: 'auto',
        },
        header: [
            // tslint:disable-next-line:deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${'#46b875'}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });
    
    const contentStylesModalConflict = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            overflow: 'auto',
            height:  window.innerHeight - 100,
            // width: window.innerWidth - 100
        },
        containerDiv: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            overflow: 'auto',
            height:  window.innerHeight - 222,
            // width: window.innerWidth - 100
        },
        header: [
            // tslint:disable-next-line:deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${'#46b875'}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });

    const tokensStack = { childrenGap: 50 };

    const stylesStack = { root: { width: 300 } };
    
    const stylesStackConflictModal = { root: { width: 670 } };

    const columnProps = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } }
    };

    const closeModal = () => {
        setStatusShowModal(false);
    };

    const onChangeGeo = (e) => {
        setValueGeo(e.target.value);
    };

	const goToGeoMaping = (e) => {

        let arrayValueGeo = valueGeo.split(', ');
        if (state.statusHaveScale) {
            // console.log('>>>>>>>>>>>>>>>>>>', arrayValueGeo, typeof arrayValueGeo[0], typeof arrayValueGeo[1])
            // console.log('>>>>>>>>>>>>>>>>>>', state.src.dataPlanSet.longitude)
            setErrorMessage(null);
            // console.log('>>>><<<<<<<<', `https://geomapping.getshopster.net/geo-mapping/?plan_set_uuid=${state.uuidSet}&latitude=${state.src.dataPlanSet.latitude}&longitude=${state.src.dataPlanSet.longitude}`)
            window.location.replace(`https://geomapping.getshopster.net/geo-mapping/?plan_set_uuid=${state.uuidSet}&latitude=${state.src.dataPlanSet.latitude}&longitude=${state.src.dataPlanSet.longitude}`, '_blank');
        }
        else {
            setErrorMessage('Нету масштаба, пожалуйста добавть масштаб и сохраните');
        }
	};
	
	const onClickLogo = (e) => {
        window.location.replace('/', '_blank');
    };
	
	// console.log('>>>>>>>>>>>>>>>>>>>.', state.uuidSet);
	
	const onChangeDefaultPageStatus = (e, item) => {
        // console.log('>>>>>>>>>>>>>>>>>>>.', e, temp.key);
        
        dispatch({
            type:'GET_STATUS_DEFAULT_PAGE',
            statusDefaultPage: item.key
        })
    }
    
    const itemAlignmentsStackTokens = {
        childrenGap: 5,
        // padding: 5
    };
    
    const itemAlignmentsStackStyles = {
        root: {
            background: 'rgba(208,208,208,0.1)',
            height: 50
        }
    };
    
    const options = [
        
        {
            key: 'listPlanSet',
            // text: 'Редактироание сущностей',
            iconProps: { iconName: 'GroupedList' },
            styles:
                {
                    labelWrapper: {
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                    },
                    field: {
                        marginRight: '15px',
                        padding: '0px'
                        // paddingTop: '2px'
                    },
                    
                    choiceFieldWrapper: {
                        // marginTop: '5px'
                        height: '32px',
                        width: '82px',
                        display: "flex",
                    },
                    iconWrapper: {
                        height: '20px',
                        width: '20px',
                        fontSize: '20px',
                    }
                },
            // disabled: statusCreatedPolygon
        },
        {
            key: 'newPlanSet',
            // text: 'Режим осмотра',
            iconProps: { iconName: 'NewAnalyticsQuery' },
            styles:
                {
                    labelWrapper: {
                        height: '0px',
                        width: '0px',
                        padding: '0px'
                    },
                    field: {
                        marginRight: '15px',
                        padding: '0px'
                        // paddingTop: '2px'
                    },
                
                    choiceFieldWrapper: {
                        // marginTop: '5px'
                        height: '32px',
                        width: '82px',
                        display: "flex",
                    },
                    iconWrapper: {
                        height: '20px',
                        width: '20px',
                        fontSize: '20px',
                    }
                },
            // disabled: statusCreatedPolygon
        },
    
    ];
    
    const styleButtonConflict = {
        label: {
            color: 'rgb(224,82,82)'
        }
    }
    
    const onCheckInputConflict = (item, status) => (e) => {
        let _componentsListNode = tools.copy(componentsListNode);
        // tools
        // console.log('>>>>>>>>>>>>>',_componentsListNode[item.index].checkboxData, status)
        for (var key in _componentsListNode[item.index].checkboxData) {
            _componentsListNode[item.index].checkboxData[key] = false;
        }
        _componentsListNode[item.index].checkboxData[status] = true;
        _componentsListNode[item.index].willToSaveModifare = _componentsListNode[item.index].checkboxData.checkboxLocal
            ? 'Мои изменения' : _componentsListNode[item.index].checkboxData.checkboxServer
                ? 'Изменения с сервера' : 'Изменений не было';
        setComponentsListNode(_componentsListNode);
    };
    
    let columns = [
        {
            key: 'column3',
            name: 'Слой',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 100,
            // isResizable: true,
            // onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
            data: 'string',
            cellRightPadding:12,
            onRender: (item) => {
                return(<span>{item.name}</span>);
            },
            isPadded: false,
        },
        {
            key: 'column2',
            name: 'Этаж',
            fieldName: 'floor',
            minWidth: 20,
            maxWidth: 50,
            cellRightPadding:12,
            // isRowHeader: true,
            // isResizable: true,
            // isSorted: true,
            // isSortedDescending: false,
            // sortAscendingAriaLabel: 'Sorted A to Z',
            // sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
            data: 'number',
            isPadded: false,
        },
        
        {
            key: 'column5',
            name: 'Мои изменения',
            fieldName: 'modifiedStateInInitialState',
            minWidth: 120,
            maxWidth: 120,
            cellRightPadding:12,
            // isPadded:false,
            // isResizable: true,
            // isCollapsible: true,
            // data: 'string',
            // onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
            onRender: (item) => {
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>", item.modifiedStateInInitialState)
                if (item.modifiedStateInInitialState === false) {
                    return (
                        <CheckDiv>
                            <CheckInput
                                onChange={onCheckInputConflict(item, 'checkboxLocal')}
                                type={'radio'}
                                checked={item.checkboxData.checkboxLocal}
                                statusConflict={item.statusConflict}
                            ></CheckInput>
                        </CheckDiv>
                        
                    );
                }
                return (
                    <BoxLoader statusConflict={item.modifiedStateInInitialState}></BoxLoader>
                );
            },
            isPadded: false,
        },
        {
            key: 'column4',
            name: 'Конфликт',
            fieldName: 'statusConflict',
            minWidth: 80,
            maxWidth: 80,
            cellRightPadding:12,
            // isResizable: true,
            // isCollapsible: true,
            // data: 'string',
            // onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
            onRender: (item) => {
                if (item.statusConflict === false) {
                    return (
                        <CheckDiv>
                            <CheckInput
                                onChange={onCheckInputConflict(item, 'checkboxServer')}
                                type={'radio'}
                                checked={item.checkboxData.checkboxServer}
                                statusConflict={item.modifiedStateInInitialState}
                            ></CheckInput>
                        </CheckDiv>
                        
                    );
                }
                return (
                    <BoxLoader statusConflict={item.statusConflict}></BoxLoader>
                );
            },
            isPadded: false,
        },
        {
            key: 'column5',
            name: 'Сохраняемые изменения',
            fieldName: 'willToSaveModifare',
            minWidth: 160,
            maxWidth: 160,
            cellRightPadding:12,
            // isResizable: true,
            // isCollapsible: true,
            // data: 'string',
            // onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
            onRender: (item) => {
                return (
                    <span >{item.willToSaveModifare}</span>
                );
            },
            isPadded: false,
        },
        
    ];
    
    const onShowModalConflict = () => {
        props.onShowModalConflict();
        setStatusShowModalConflict(true);
    };
    
    const onHideModalConflict = () => {
        setStatusShowModalConflict(false);
    };
    
	const stylesDetailList = {
        cellRightPadding: 12,
        cellExtraRightPadding: 12
        
        
    };
	let statusDisable = false;
	state.filesData.forEach((itemImage, indexImage) => {
        if (itemImage.name === undefined) {
            statusDisable = true;
        }
    });
    // console.log('>>>>>>>>>>>>>>>>>>', state.newPlanSetData)
    return (
        <div className={'topPanel'} id={'topPanel'}>
            <div onClick={onClickLogo} className='logoBox'>

            </div>
            <div className='contentBox'>
                <Stack horizontal horizontalAlign="start" tokens={stackTokens}>
                    {
                        state.uuidSet === '' ? <div className="boxChoceGroup">
                            <Stack horizontal disableShrink styles={itemAlignmentsStackStyles} tokens={itemAlignmentsStackTokens}>
                                <ChoiceGroup
                                    // label="Выбор режима работы с картой"
                                    defaultSelectedKey={state.statusDefaultPage}
                                    // className="inlineflex"
                                    options={options}
                                    onChange={onChangeDefaultPageStatus}
        
                                />
                            </Stack>
                        </div> : <div className="tempBackground">
                        
                        </div>
                    }
    
                    { state.uuidSet !== '' ? <DefaultButton
                        text="Сохранить"
                        onClick={onClickSave}
                        allowDisabledFocus
                        disabled={
                            props.statusOpasity ||
                            state.mapsStatusIntersectionPolygons ||
                            state.statusSelectObjects
                            || state.statusConflict
                        }
    
                    /> : null}
                    { state.uuidSet === '' && state.statusDefaultPage === 'newPlanSet' ? <DefaultButton
                        text="Создать"
                        onClick={props.createNewPlanSet}
                        allowDisabledFocus
                        disabled={
                            state.newPlanSetData.name !== '' && state.newPlanSetData.latitude !== '' &&
                            state.newPlanSetData.longitude !== '' && statusDisable === false ? false : true
                        }
    
                    /> : null}
                    {state.uuidSet !== '' ? <DefaultButton
                        text="Геомапинг"
                        onClick={goToGeoMaping}
                        allowDisabledFocus
                        disabled={props.statusOpasity || state.statusSelectObjects}
                    /> : null}
                    {state.statusConflict ? <PrimaryButton
                        text="Конфликт"
                        onClick={onShowModalConflict}
                        styles={styleButtonConflict}
                        allowDisabledFocus
                        // disabled={props.statusOpasity || state.statusSelectObjects}
                    /> : null}
                    {
                        state.uuidSet !== '' && state.src.dataPlanSet !== null ?
                            <h2 className={'h2TopPanel'}>{state.src.dataPlanSet.name}</h2>
                            :
                            null
                    }
                </Stack>
            </div>
        
            {/*<button className={'buttonModeColorScheme'} onClick={onClickButton}>*/}

            {/*</button>*/}
            <Modal
                titleAriaId={'New group zone'}
                subtitleAriaId={'subText'}
                isOpen={statusShowModal}
                onDismiss={closeModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
                // dragOptions={_dragOptions}
            >
                <div className={contentStyles.header}>
                    <span id={'New group zone'}>Переход в геомапинг</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={closeModal}
                    />
                </div>
                <div id={'subText'} className={contentStyles.body}>
                    <Stack horizontle
                           tokens={tokensStack}
                           styles={stylesStack}
                    >
                        <Stack
                            {...columnProps}
                        >
                            <div>
                                <p>
                                    Введите долготу и широту объекта
                                </p>
                            </div>
                            <TextField
                                label="latitude, longitude"
                                value={valueGeo}
                                onChange={onChangeGeo}
                                errorMessage={errorMessage}
                            />
                            <PrimaryButton
                                text="Перейти в геомапинг"
                                // disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                                onClick={goToGeoMaping}
                                // allowDisabledFocus
                                // disabled={disabled}
                                // checked={checked}
                            />
                        </Stack>

                    </Stack>
                </div>
            </Modal>
            <Modal
                titleAriaId={'Conflict'}
                subtitleAriaId={'subText'}
                isOpen={statusShowModalConflict}
                onDismiss={onHideModalConflict}
                isBlocking={false}
                containerClassName={contentStylesModalConflict.container}
                // dragOptions={_dragOptions}
            >
                <div className={contentStylesModalConflict.header}>
                    <span id={'New group zone'}>Конфликт</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={onHideModalConflict}
                    />
                </div>
                <div id={'subText'}
                     className={contentStylesModalConflict.body}
                >
                    <Stack vertical
                           tokens={tokensStack}
                           styles={stylesStackConflictModal}
                    >
                        <div
                            className={contentStylesModalConflict.containerDiv}
                            data-is-scrollable={true}>
                            <DetailsList
                                items={componentsListNode}
                                // compact={isCompactMode}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                // getKey={this._getKey}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                ICellStyleProps={stylesDetailList}
                                // onItemInvoked={this._onItemInvoked}
                            />
                        </div>
                    </Stack>
                    <Stack>
                        <PrimaryButton
                            text="Сохранить"
                            // disabled={!(nameGroupZone !== '' && markerGroupZone !== '')}
                            onClick={onClickSaveAfterConflict}
                            // allowDisabledFocus
                            // disabled={disabled}
                            // checked={checked}
                        />
                    </Stack>
                </div>
            </Modal>
        </div>
    );
};

export default TopPanelMain;