import React from 'react';
import './style/style_panel_objects.less';
import DetailPanelBox from './components/box_detail_panel'

const PanelDetailObjects = (props) => {
    return (
        <div className={'PanelDetailObjects'} id={'PanelDetailObjects'}>
            <DetailPanelBox/>
        </div>
    );
}

export default PanelDetailObjects;