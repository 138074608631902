import React from 'react';
import SubSectionsBox from "./sub_sections_box";
import DetailSubSectionsBox from "./detail_sub_sections_box";


const LeftPanel = (props) => {
    return (
        <div className={'leftPanelContent'}>
            <SubSectionsBox/>
            <DetailSubSectionsBox
                setStatusShowModalOfNewGroupZones={props.setStatusShowModalOfNewGroupZones}
            />
        </div>
    );
}

export default LeftPanel;