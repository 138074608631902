import React, { useContext, useState, useEffect } from 'react';
import GlobalDispatchContext from "../../../global_dispatch_context";
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { IconButton } from 'office-ui-fabric-react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import tools from "../../../tools";
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import styled from "styled-components";


const BoxTitleInput = styled.span`
   //display: inline-block;
   
   margin: 10px;
`;

const BoxDivTitleInput = styled.div`
   position: relative;
   padding: 10px;
   border: 1px solid rgb(244, 244, 244);
`;

const DivLineColor = styled.div`
   
   height: 10px;
    width: 38px;
    margin: auto;
    top: 23px;
    right: 10px;
    position: absolute;
    border-radius: 3px;
   background: ${props => props.color};
`;

const DetailPanelBox = (props) => {
    const [valueInput, getValueInput] = useState('');
    const [valueInputName, getValueInputName] = useState('');
    const [isCheck, check] = useState(true);
    const [statusUniqMarkers, getStatusUniqMarkers] = useState(true);
    const {state, dispatch} = useContext(GlobalDispatchContext);
    
    let tempStringMarker = 'E-1'

    useEffect(() => {
        if (state.sectionStatus === 'devices') {
            getValueInput(state.dataBySelectedObjects.marker);
            check(state.dataBySelectedObjects.is_opened);
        }
        else if (state.sectionStatus === 'places') {
            getValueInput(state.dataBySelectedObjects.marker);
            check(state.dataBySelectedObjects.is_opened);
        }
        else if (state.sectionStatus === 'zones') {
            getValueInput(state.dataBySelectedObjects.zone_marker);
            getValueInputName(state.dataBySelectedObjects.zone_name)
            // check(state.dataBySelectedObjects.zone_name);
        }
        else if (state.sectionStatus === 'scale') {
            getValueInput(state.dataBySelectedObjects.param);

            // check(state.dataBySelectedObjects.is_opened);
        }

    }, [
        state.dataBySelectedObjects.marker,
        state.sectionStatus,
        state.dataBySelectedObjects.zone_marker,
        state.dataBySelectedObjects.is_opened,
        state.dataBySelectedObjects.zone_name,
        state.dataBySelectedObjects.param,
        state.selectedScaleType
    ]);
    
    useEffect(() => {
    
        dispatch({
            type:'ON_CHANGE_MARKER_ENTRANCES',
            value: tempStringMarker
        });
    }, [
    
    ]);
    const onChangeInput = (e) => {
        // console.log('>>>>>>>>>>>>>>>', e.target.value[e.target.value.length -1], e.nativeEvent.data)
        if (e.nativeEvent.data !== '~') {
            getStatusUniqMarkers(true);
            getValueInput(e.target.value)
        }
    };

    const onChangeInputName = (e) => {
        if (e.nativeEvent.data !== '~') {
            getValueInputName(e.target.value)
        }
    };

    const onCheck = (e) => {
        // console.log('<<<<<<<>>>>>>>', e.target.checked)
        check(e.target.checked)
    };

    const onSave = () => {


        let statusNotUniq = true;
        for (var key in state.dataPlanSetByFloor) {

            if (state.sectionStatus === 'devices') {
                let markers = tools.copy(state.dataPlanSetByFloor[key].IPoint.markers);
                for (var i = 0; i < markers.length; i++) {
                    if (state.dataBySelectedObjects.index !== i) {

                        if (markers[i] === valueInput) {
                            statusNotUniq = false;
                        }
                    }

                }
            }
            else if (state.sectionStatus === 'places') {
                let markers = tools.copy(state.dataPlanSetByFloor[key].places.markers);
                for (var i = 0; i < markers.length; i++) {
                    if (state.dataBySelectedObjects.index !== i) {

                        if (markers[i] === valueInput) {
                            statusNotUniq = false;
                        }
                    }

                }
            }
            else if (state.sectionStatus === 'zones') {


                for (var _key in state.dataPlanSetByFloor[key].zones) {
                    let markers = tools.copy(state.dataPlanSetByFloor[key].zones[_key].markers);
                    for (var i = 0; i < markers.length; i++) {
                        if (state.dataBySelectedObjects.index !== i) {

                            if (markers[i] === valueInput) {
                                if (state.dataPlanSetByFloor[key].zones[_key].names[i] !== valueInputName) {
                                    statusNotUniq = false;
                                }

                            }
                        }

                    }
                }

            }

        }

        getStatusUniqMarkers(statusNotUniq);


        if (statusNotUniq) {
            dispatch({
                type:'SAVE_TEMP_DATA',
                status: true,
                data: {
                    checked: isCheck,
                    marker: valueInput,
                    name: valueInputName,
                    index: state.dataBySelectedObjects.index
                }
            });
        }

    };

    const trashObjects = () => {
        dispatch({
            type:'TRASH_OBJECTS',
        })
    };



    const stackTokens = { childrenGap: 10 };
    const stackTokensItem = { childrenGap: 1 };
    const deleteIcon = { iconName: 'Delete' };
    const stylesIconButton = {
        icon:  {color:'rgb(168,43,14)'}
    };
    
    const stylesIconButtonDeleteMarker = {
        icon:  {color:'rgb(168,43,14)'}
    };
    
    const onFocusBox = () => {
        // console.log('>>>>>>>>>>>>>')
        dispatch({
            type:'FOCUS_DETAIL_BOX',
        })
    }
    const onBlurBox = () => {
        dispatch({
            type:'BLUR_DETAIL_BOX',
        })
    }

    let componentContent = <>

        <Stack
            tokens={stackTokens}
            verticalAlign="end">

            <IconButton
                split
                iconProps={deleteIcon}
                splitButtonAriaLabel="See 2 options"
                aria-roledescription="split button"
                // icon={{color:'red'}}
                styles={stylesIconButton}
                ariaLabel="New item"
                onClick={trashObjects}
                disabled={state.modeMaps === 'noDo'}

            />

        </Stack>



    </>;

    const stylesInput = {
        root: {
            marginTop: '26px'
        }
    };
    
    

    if (state.sectionStatus === 'scale') {
        const stylesIconButton2 = {
            icon:  {color:'rgb(168,43,14)'}
        };
        componentContent = <>

            <Stack
                tokens={stackTokens}
                verticalAlign="end">
                <TextField
                    label={state.dataBySelectedObjects.statusParam}
                    value={valueInput}
                    onChange={onChangeInput}
                    disabled={state.modeMaps === 'noDo'}
                />
                <IconButton
                    split
                    iconProps={deleteIcon}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    styles={stylesIconButton2}
                    ariaLabel="New item"
                    onClick={trashObjects}
                    disabled={state.modeMaps === 'noDo'}
                />

            </Stack>



        </>
    }
    else if (state.sectionStatus === 'devices') {
        componentContent = <>




            <Stack
                tokens={stackTokens}
                verticalAlign="end">
                <TextField
                    label={`marker`}
                    value={valueInput}
                    onChange={onChangeInput}
                    disabled={state.modeMaps === 'noDo'}
                />
                <Checkbox
                    label="open"
                    onChange={onCheck}
                    checked={isCheck}
                    disabled={state.modeMaps === 'noDo'}
                />
                <IconButton
                    split
                    iconProps={deleteIcon}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    styles={stylesIconButton}
                    ariaLabel="New item"
                    onClick={trashObjects}
                    disabled={state.modeMaps === 'noDo'}
                />

            </Stack>


            
        </>
    }
    else if (state.sectionStatus === 'places') {
        const addIcon = { iconName: 'Add' };
        const _onChange = (e, checked) => {
            // console.log({checked})
            dispatch({
                type:'ON_CHECK_PLACES_INPUT',
                checked
            });
        };
    
    
        
    
        const _onChangeTextField = (e) => {
            // console.log({checked: e.target.value});
            // e.stopPropagation();
            dispatch({
                type:'ON_CHANGE_MARKER_ENTRANCES',
                value: e.target.value
            });
        };
    
        const onClickAddMarker = (e) => {
            // console.log({checked: e.target.value});
            // e.stopPropagation();
            dispatch({
                type:'GET_NEW_MARKER_ENTRANCES',
            });
        };
    
        const onClickAddPoint = (e) => {
            
            dispatch({
                type:'GET_STATUS_ADD_POINTS',
                status: !state.statusAddPointsEntrances
            });
        };
    
        const onClickSaveTempEntrances = (e) => {
        
            dispatch({
                type:'SAVE_TEMP_ENTRANCES'
            });
        };
    
        const onClickSaveTempEntrancesClear = (e) => {
        
            dispatch({
                type:'CLEAR_TEMP_ENTRANCES'
            });
        };
        
        
        let componentNodeMarker = [];
        Object.keys(state.objDataByMarker).forEach((itemMarker, indexMarker) => {
            // console.log('>>>>>>>>>>>>>>>>>>>>>>>>', state.objDataByMarker[itemMarker].color);
            const trashMarkerInput = (e, checked) => {
                // console.log({checked})
                dispatch({
                    type:'TRASH_MARKER_INPUT',
                    itemMarker
                });
            };
            componentNodeMarker.push(
                <BoxDivTitleInput
                    key={`nodeItemMarker${indexMarker}`}
                    className={'BoxTitleInput'}
                >
                    <Stack
                        vertical
                
                    >
                        <BoxTitleInput  >
                            
                            {itemMarker}
                            
                        </BoxTitleInput>
                        <DivLineColor color={state.objDataByMarker[itemMarker].color}></DivLineColor>
                        <div className={'BoxTitleInputDiv'}>
                            <IconButton
                                split
                                iconProps={deleteIcon}
                                splitButtonAriaLabel="See 2 options"
                                aria-roledescription="split button"
                                styles={stylesIconButtonDeleteMarker}
                                ariaLabel="New item"
                                onClick={trashMarkerInput}
                                // disabled={state.modeMaps === 'noDo'}
                            />
                        </div>
                        { state.statusEditPointsEntrances && indexMarker === Object.keys(state.objDataByMarker).length - 1 ? <>
                            <DefaultButton
                            text="Добавить точки"
                            onClick={onClickAddPoint}
                            allowDisabledFocus
                            // disabled={disabled}
                            checked={state.statusAddPointsEntrances}
                        />
                            <PrimaryButton
                            text="Применить "
                            onClick={onClickSaveTempEntrances}
                            allowDisabledFocus
                            disabled={state.countPointEntrances < 2}
                            // checked={false}
                            />
                            <DefaultButton
                                text="Отмена"
                                onClick={onClickSaveTempEntrancesClear}
                                allowDisabledFocus
                                // disabled={disabled}
                                // checked={state.statusAddPointsEntrances}
                            />
                            </> : null}
                    </Stack>
                </BoxDivTitleInput>
        
            )
        });
        
        
        
        
        componentContent = <>


            <Stack
                // tokens={stackTokens}
                tokens={stackTokens}
                verticalAlign="end">
                <TextField
                    label={`marker`}
                    value={valueInput}
                    onChange={onChangeInput}
                    disabled={state.modeMaps === 'noDo'}
                />
                <Checkbox
                    label="open"
                    onChange={onCheck}
                    checked={isCheck}
                    disabled={state.modeMaps === 'noDo'}
                />
                <IconButton
                    split
                    iconProps={deleteIcon}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    styles={stylesIconButton}
                    ariaLabel="New item"
                    onClick={trashObjects}
                    disabled={state.modeMaps === 'noDo'}
                />
    
                {/*<PrimaryButton*/}
                {/*    text="Добавление входа"*/}
                {/*    // onClick={_alertClicked}*/}
                {/*    allowDisabledFocus*/}
                {/*    // disabled={disabled}*/}
                {/*    // checked={checked}*/}
                {/*/>*/}
                
                <Stack
                    // tokens={stackTokens}
                    // tokens={stackTokens}
                    // verticalAlign="end"
                    // vertical
                >
        
                    {componentNodeMarker}
                    <Separator/>
                    <Toggle
                        label="Добавление входа"
                        onChange={_onChange}
                    />
                    {state.checkedPlacesInputCreate ? <Stack
                        // tokens={stackTokens}
                        // tokens={stackTokens}
                        // verticalAlign="end"
                        horizontal
                    >
                        <TextField
                            label="Marker входа"
                            // styles={stylesInput}
                            onChange={_onChangeTextField}
                            // defaultValue={tempStringMarker}
                            value={state.valueMarkerEntrances}
        
                        />
        
                        <IconButton
                            iconProps={addIcon}
                            styles={stylesInput}
                            // disabled={state.countPointEntrances < 2}
                            onClick={onClickAddMarker}
                            // title="Emoji"
                            // ariaLabel="Emoji"
                            disabled={state.statusEditPointsEntrances || state.valueMarkerEntrances === ''}
                            // checked={checked}
                        />
                    </Stack> : null}
                </Stack>
                
                

            </Stack>
            
        </>
    }

    else if (state.sectionStatus === 'zones') {
    
        componentContent = <>


            <Stack
                // tokens={stackTokens}
                tokens={stackTokens}
                verticalAlign="end">
                <TextField
                    label={`marker`}
                    value={valueInput}
                    onChange={onChangeInput}
                    disabled={state.modeMaps === 'noDo'}
                />
                <TextField
                    label={`name`}
                    value={valueInputName}
                    onChange={onChangeInputName}
                    disabled={state.modeMaps === 'noDo'}
                />
                <IconButton
                    split
                    iconProps={deleteIcon}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    styles={stylesIconButton}
                    ariaLabel="Delete item"
                    onClick={trashObjects}
                    disabled={state.modeMaps === 'noDo'}
                    // disabled={disabled}
                    // checked={checked}
                />

            </Stack>
            
        </>
    }
    else if (state.sectionStatus === 'tracks') {
        // console.log('!!!!!!!!!!!!', state.dataBySelectedObjects.uuid)
        componentContent = <>


            <Stack
                // tokens={stackTokens}
                tokens={stackTokens}
                verticalAlign="end">
                <Stack
                    // tokens={stackTokens}
                    tokens={stackTokensItem}
                    verticalAlign="end">
                    <Text variant={'xLarge'} block>
                        id:
                    </Text>
                    <Text>
                        {state.dataBySelectedObjects.id}
                    </Text>
                </Stack>

                <Stack
                    // tokens={stackTokens}
                    tokens={stackTokensItem}
                    verticalAlign="end">
                    <Text variant={'xLarge'} block>
                        time:
                    </Text>
                    <Text>
                        {state.dataBySelectedObjects.dt}
                    </Text>
                </Stack>

                <Stack
                    // tokens={stackTokens}
                    tokens={stackTokensItem}
                    verticalAlign="end">
                    <Text variant={'xLarge'} block>
                        track type:
                    </Text>
                    <Text>
                        {state.dataBySelectedObjects.track_type}
                    </Text>
                </Stack>

                <Stack
                    // tokens={stackTokens}
                    tokens={stackTokensItem}
                    verticalAlign="end">
                    <Text variant={'xLarge'} block>
                        uuid:
                    </Text>
                    <Text>
                        {state.dataBySelectedObjects.uuid}
                    </Text>
                </Stack>

                <IconButton
                    split
                    iconProps={deleteIcon}
                    splitButtonAriaLabel="See 2 options"
                    aria-roledescription="split button"
                    styles={stylesIconButton}
                    ariaLabel="New item"
                    onClick={trashObjects}
                    disabled={state.modeMaps === 'noDo'}
                    // disabled={disabled}
                    // checked={checked}
                />


            </Stack>
            
            
        </>
    }

    const horizontalStackProps = {
        horizontal: true,
        tokens: { childrenGap: 16 }
    };

    const verticalStackProps = {
        styles: { root: { overflow: 'hidden', width: '100%' } },
        tokens: { childrenGap: 20 }
    };

    return (
        <div className={'DetailPanelBox'}
             onFocus={onFocusBox}
             onBlur={onBlurBox}
             style={{color:'#827f7d'}}>
            {componentContent}

            <Separator/>
            <Stack vertical tokens={stackTokens}>
                {state.sectionStatus === 'tracks' ? null : <PrimaryButton

                    text="Применить"
                    onClick={onSave}
                    disabled={state.modeMaps === 'noDo'}
                    // allowDisabledFocus
                />}


            </Stack>

            <Stack {...horizontalStackProps}>
                <Stack {...verticalStackProps}>
                    {statusUniqMarkers === false ?
                        <MessageBar
                            dismissButtonAriaLabel="Close"
                            // onDismiss={onChoise}
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                        >
                            {'Маркер не является уникальным,' +
                            'исправьте маркер'}
                        </MessageBar> :
                        null

                        }
                </Stack>
            </Stack>

        </div>
    );
};



export default DetailPanelBox;