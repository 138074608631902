import React, {useContext, useEffect, useState} from 'react';

import GlobalDispatchContext from "../../global_dispatch_context";


import {FocusZone, FocusZoneDirection} from 'office-ui-fabric-react/lib/FocusZone';
// import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
// import {getFocusStyle, getTheme, mergeStyleSets} from 'office-ui-fabric-react/lib/Styling';
import {TextField} from 'office-ui-fabric-react/lib/TextField';
// import {Dropdown} from "office-ui-fabric-react/lib/Dropdown";
import {Stack} from "office-ui-fabric-react/lib/Stack";
import tools from "../../tools";
import {DetailsList, DetailsListLayoutMode, SelectionMode,} from 'office-ui-fabric-react/lib/DetailsList';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {MessageBar, MessageBarType} from "office-ui-fabric-react";
import ImageUploader from "react-images-upload";
import { Label } from 'office-ui-fabric-react/lib/Label';
import {
	ComboBox,
	IComboBoxOption,
	SelectableOptionMenuItemType,
	Fabric,
	mergeStyles,
} from 'office-ui-fabric-react/lib/index';
import { Icon } from '@fluentui/react/lib/Icon';
import { SpinButton } from 'office-ui-fabric-react/lib/SpinButton';
import './style/style_list_plansets.less'


import { useId, useBoolean } from '@uifabric/react-hooks';
import {
	getTheme,
	mergeStyleSets,
	getFocusStyle,
	FontWeights,
	ContextualMenu,
	Toggle,
	DefaultButton,
	Modal,
	IDragOptions,
	IconButton,
	IIconProps,
} from 'office-ui-fabric-react';
import styled from "styled-components";

const dragOptions = {
	moveMenuItemText: 'Move',
	closeMenuItemText: 'Close',
	menu: ContextualMenu,
};
const cancelIcon = { iconName: 'Cancel' };
const addIcon = { iconName: 'Add' };
const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const editIcon = { iconName: 'Edit' };
const deleteIcon = { iconName: 'Delete' };
const CheckMarkIcon = { iconName: 'CheckMark' };
const CopyIcon = { iconName: 'Copy' };

const contentStyles = mergeStyleSets({
	container: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
	},
	header: [
		theme.fonts.xLargePlus,
		{
			flex: '1 1 auto',
			borderTop: `4px solid ${theme.palette.themePrimary}`,
			color: theme.palette.neutralPrimary,
			display: 'flex',
			alignItems: 'center',
			fontWeight: FontWeights.semibold,
			padding: '12px 12px 14px 24px',
		},
	],
	body: {
		flex: '4 4 auto',
		padding: '0 24px 24px 24px',
		overflowY: 'hidden',
		selectors: {
			p: { margin: '14px 0' },
			'p:first-child': { marginTop: 0 },
			'p:last-child': { marginBottom: 0 },
		},
	},
});
const toggleStyles = { root: { marginBottom: '20px' } };

const iconButtonStyles = {
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
	},
	rootHovered: {
		color: theme.palette.neutralDark,
	},
};

const iconAddButtonStyles = {
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
		fontSize: '88px',
		width: '100px',
		height: '90px'
	},
	icon: {
		fontSize: '88px',
	},
	rootHovered: {
		color: theme.palette.neutralDark,
	},
};


const iconSpinBuutonStyles = {
	root: {
		width: '150px'
	},
	field: {
		width: '80px'
	}
};


const ImageContainer = styled.div`

  width: 100px;
  height: 100px;
  background: ${props => props.imageBack === '' ? null : `url(https://maps.getshopster.net/${props.imageBack})`} no-repeat;
  background-size: contain;
  background-position: center;
 
 
`;

const DivAddPlan = styled.div`

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100px;
  grid-template-rows: 100px;
  align-items: center;
  justify-content: center;
 
`;

const DivImageBox = styled.div`

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100px;
  grid-template-rows: 100px;
  align-items: center;
  justify-content: center;
 
`;

const SpanAddPlan = styled.span`

  font-size: 20px;
 
`;

const DivButtonRowPlan = styled.div`

  width: 100%;
  height: 100%;
  display: none;
  grid-template-columns: 100px;
  grid-template-rows: 100px;
  align-items: center;
  justify-content: end;
  position: relative;
 
`;

const TempDivButtonRowPlan = styled.div`

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 50px 50px;
  grid-template-rows: 100px;
  align-items: center;
  justify-content: center;
 
`;

const wrapperClassName = mergeStyles({
	selectors: {
		'& > *': { marginBottom: '20px' },
		'& .ms-ComboBox': { maxWidth: '300px' },
	},
});

const fontMapping = {
	['Arial Black']: '"Arial Black", "Arial Black_MSFontService", sans-serif',
	['Times New Roman']: '"Times New Roman", "Times New Roman_MSFontService", serif',
	['Comic Sans MS']: '"Comic Sans MS", "Comic Sans MS_MSFontService", fantasy',
	['Calibri']: 'Calibri, Calibri_MSFontService, sans-serif',
};

const fonts2 = Object.keys(fontMapping);

const optionsForCustomRender = [
	{
		key: 'header1',
		text: 'Theme Fonts',
		itemType: SelectableOptionMenuItemType.Header
	},
	...fonts2.map((fontName) => ({ key: fontName, text: fontName })),
	{
		key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider
	},
	{
		key: 'header2', text: 'Other Options', itemType: SelectableOptionMenuItemType.Header
	},
];

const ListPlanSetsMain = (props) => {
	
	const {state, dispatch} = useContext(GlobalDispatchContext);
	const [componentsListNode, setComponentsListNode] = useState([]);
	const [_componentsListNode, _setComponentsListNode] = useState([]);
	const [_columnsGlobal, _setColumn] = useState([]);
	const [copyStatus, setCopyStatus] = useState(false);
	const [dataSelectPlanSets, setDataSelectPlanSets] = useState(null);
	const [dataEditFloor, setDataEditFloor] = useState(null);
	const [statusAddNewFloor, satStatusAddNewFloor] = useState(false);
	const [valueNewFloor, setValueNewFloor] = useState(0);
	const [dataShowPanelCopy, setDataShowPanelCopy] = useState(null);
	const [listOfFloors, setListOfFloors] = useState([]);
	const [valueSelectFloorToCopy, setValueSelectFloorToCopy] = useState(null);
	
	
	
	
	
	useEffect(() => {
		let componentsList = []
		
		state.listPlanSets.forEach((item, index) => {
			// console.log('>>>>>>>>>>>>>!!!!!!', item)
			componentsList.push(
				{
					...item,
					url: `/make_geometry/${item.uuid}/`,
					key: index + 'listPlanSets',
					isExpanded: true,
					latLon: item.latitude + ', ' + item.longitude
					
				}
			);
			
			
		})
		
		// isExpanded: true
		// key: "0listPlanSets"
		// latLon: "55.78611807, 49.12126695"
		// latitude: "55.78611807"
		// longitude: "49.12126695"
		// name: "Золотое Яблоко, Казань, Пушкина 2"
		// url: "/make_geometry/b0a5f1da-4a73-4ab9-a2ab-6b30faff417c/"
		// uuid: "b0a5f1da-4a73-4ab9-a2ab-6b30faff417c"
		
		setComponentsListNode(componentsList);
		_setComponentsListNode(componentsList);
		_setColumn(
			[...columns]
		)
		
	}, [
		state.listPlanSets,
	]);
	
	useEffect(() => {
		let _columnsGlobal2 = _columnsGlobal.length === 0 ? columns: _columnsGlobal;
		_columnsGlobal2.forEach((item, index) => {
			item.onColumnClick = (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode)
		});
		_setColumn(
			[..._columnsGlobal2]
		)

	}, [
		_componentsListNode,
	]);
	
	
	
	
	const _copyAndSort = (items, columnKey, isSortedDescending) => {
		const key = columnKey;
		return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	};
	
	const onCopy = () => {
		setCopyStatus(true);
		setTimeout(() => {
			setCopyStatus(false);
		}, 2000);
	};
	// console.log('!!!!!!!!!!!!1', _columnsGlobal);
	let columns = [
		{
			key: 'column2',
			name: 'Name',
			fieldName: 'name',
			minWidth: 210,
			maxWidth: 350,
			isRowHeader: true,
			isResizable: true,
			isSorted: true,
			isSortedDescending: false,
			sortAscendingAriaLabel: 'Sorted A to Z',
			sortDescendingAriaLabel: 'Sorted Z to A',
			onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
			data: 'string',
			onRender: (item) => {
				return <a
					href={item.url}
					target="_blank"
				>
					{item.name}
				</a>;
			},
			isPadded: true,
		},
		{
			key: 'column3',
			name: 'lat, lon',
			fieldName: 'latLon',
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
			onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
			data: 'number',
			onRender: (item) => {
				return(<CopyToClipboard onCopy={onCopy} text={item.latLon}>
					<span>{item.latLon}</span>
				</CopyToClipboard>);
			},
			isPadded: true,
		},
		{
			key: 'column4',
			name: 'uuid',
			fieldName: 'uuid',
			minWidth: 250,
			maxWidth: 250,
			isResizable: true,
			isCollapsible: true,
			data: 'string',
			onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
			onRender: (item) => {
				return (
					<CopyToClipboard onCopy={onCopy} text={item.uuid}>
						<span>{item.uuid}</span>
					</CopyToClipboard>
				);
			},
			isPadded: true,
		},
		{
			key: 'column5',
			name: 'Link',
			fieldName: 'url',
			minWidth: 70,
			maxWidth: 90,
			isResizable: true,
			isCollapsible: true,
			data: 'number',
			onColumnClick: (e, column) => onClickColumn(e, column, _columnsGlobal, _componentsListNode),
			onRender: (item) => {
				const onClickEditPlanSetTemp = () => {
					onClickEditPlanSet(item);
				};
				
				
				return <IconButton
					iconProps={editIcon} title="Emoji"
					ariaLabel="Emoji"
					onClick={onClickEditPlanSetTemp}
				/>;
			},
		},
	];
	
	const onClickEditPlanSet = (item) => {
		props.getPlansetRequestInfo(item);
		setDataSelectPlanSets(item);
	};
	
	const hidePlansetsEditModal = (item) => {
		setDataSelectPlanSets(null);
		setDataEditFloor(null);
		satStatusAddNewFloor(false);
		setValueNewFloor(0);
		setDataShowPanelCopy(null);
		dispatch({
			type:'CLEAR_PLAN_SET_INFO'
		})
	}
	
	
	const onClickColumn = (e, column, columnsGlobal, _componentsListNode2) => {
		
		const items = _componentsListNode2;
		if (columnsGlobal.length > 0) {
			const newColumns = columnsGlobal.slice();
			
			const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
			// console.log('!!!!!!!!!!!!', currColumn, newColumns);
			newColumns.forEach((newCol) => {
				if (newCol === currColumn) {
					currColumn.isSortedDescending = !currColumn.isSortedDescending;
					currColumn.isSorted = true;
					// this.setState({
					// 	announcedMessage: `${currColumn.name} is sorted ${
					// 		currColumn.isSortedDescending ? 'descending' : 'ascending'
					// 	}`,
					// });
				} else {
					newCol.isSorted = false;
					newCol.isSortedDescending = true;
				}
			});
			// console.log('!!!!!!!!!!!!',  currColumn);
			
			
			
			
			
			
			const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
			
			setComponentsListNode(newItems);
			_setComponentsListNode(newItems)
			_setColumn(newColumns)
		}
		
		
	};
	
	
	const _onFilterChanged = ( any, text) => {
		// setFilterTexty(text);
		// componentsList = componentsList.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0)
		setComponentsListNode([]);
		let _array = tools.copy(_componentsListNode)
		let array = _array.filter((item) => {
			
			return item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
			
		})
		let arrayUuid = _array.filter((item) => {
			
			return item.uuid.toLowerCase().indexOf(text.toLowerCase()) >= 0;
			
		})
		// console.log('>>>>>>>>>>>>>>>>.', array)
		setComponentsListNode(array.concat(arrayUuid));
		
	};
	
	const classNames = mergeStyleSets({
		container: {
			overflow: 'auto',
			height:  window.innerHeight - 100,
			width: window.innerWidth - 100
		},
		itemCell: [
			getFocusStyle(theme, { inset: -1 }),
			{
				height: 44,
				padding: 3,
				boxSizing: 'border-box',
				borderBottom: `1px solid ${semanticColors.bodyDivider}`,
				display: 'flex',
				// borderLeft: '3px solid ' + '#46b875',
				selectors: {
					'&:hover': { background: palette.neutralLight },
					// '&:active' : { borderLeft: '3px solid ' + '#46b875'}
				}
			}
		],
		// itemImage: {
		//     flexShrink: 0
		// },
		itemContent: {
			marginLeft: 10,
			overflow: 'hidden',
			flexGrow: 1,
			
			
		},
		itemName: [
			fonts.medium,
			{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		],
		itemIndex: {
			fontSize: fonts.small.fontSize,
			color: palette.neutralTertiary,
			marginBottom: 10
		},
		chevron: {
			alignSelf: 'center',
			marginLeft: 10,
			color: palette.neutralTertiary,
			fontSize: fonts.large.fontSize,
			flexShrink: 0
		},
		fileIconHeaderIcon: {
			padding: 0,
			fontSize: '16px',
		},
		fileIconCell: {
			textAlign: 'center',
			selectors: {
				'&:before': {
					content: '.',
					display: 'inline-block',
					verticalAlign: 'middle',
					height: '100%',
					width: '0px',
					visibility: 'hidden',
				},
			},
		},
		fileIconImg: {
			verticalAlign: 'middle',
			maxHeight: '16px',
			maxWidth: '16px',
		},
		controlWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		exampleToggle: {
			display: 'inline-block',
			marginBottom: '10px',
			marginRight: '30px',
		},
		selectionDetails: {
			marginBottom: '20px',
		},
	});
	
	const stackTokens = { childrenGap: 24 };
	
	const stylesTextField = {
		wrapper: {
			width: 300 ,
			marginLeft: '12px',
			marginTop: '12px'
		}
	};
	
	
	// console.log('!!!!!!!!!!!!1', _columnsGlobal);
	
	const verticalStackProps = {
		styles: { root: { overflow: 'hidden', width: '100%' } },
		tokens: { childrenGap: 20 }
	};
	
	const horizontalStackProps = {
		horizontal: true,
		tokens: { childrenGap: 16 }
	};
	
	let componentFloorModal = [];
	/* eslint-disable no-unused-expressions */
	state.dataPlansetInfo?.plans.forEach((itemFloor, indexFloor) => {
		
		// console.log('>>>>>>>>>>>>>>>>>', itemFloor.image);
		const editFloorTitle = (e) => {
			dispatch({
				type:'GET_FLOOR_AFTER_EDIT',
				value: 1,
				index: indexFloor
			});
		};
		
		const getEditFloorTitle = (e) => {
			setDataEditFloor({
				indexFloor,
				itemFloor
			})
		};
		
		const getHideFloorTitle = (e) => {
			setDataEditFloor(null);
			dispatch({
				type:'GET_FLOOR_AFTER_EDIT',
				value: 1,
				index: indexFloor
			});
		};
		
		const _onIncrement = (e, value) => {
			let _dataEditFloor = tools.copy(dataEditFloor)
			_dataEditFloor.itemFloor.floor = +value;
			// console.log('>>>>>>>><<<<<<<<<<', value, dataEditFloor.itemFloor.floor);
			setDataEditFloor(_dataEditFloor);
		};
		
		const getHideFloorEditTitle = (e) => {
			setDataEditFloor(null);
		};
		
		const saveFloorTitle = (e) => {
			setDataEditFloor(null);
			dispatch({
				type:'GET_FLOOR_AFTER_EDIT',
				value: dataEditFloor,
				index: indexFloor
			});
		};
		
		const onClickCopy = (e) => {
			setDataShowPanelCopy(itemFloor);
			let tempArray = []
			state.dataPlansetInfo?.plans.forEach((itemfloorTemp, indexFloorTemp) => {
				
				if (itemfloorTemp.floor !== itemFloor.floor) {

					tempArray.push({
						key: itemfloorTemp.floor,
						text: `Этаж ${itemfloorTemp.floor}`,
					})
				}
			});
			setListOfFloors(tempArray);
		};
		
		const ComboBoxCustomStyledExampleStyles = {
			container: {
				maxWidth: '200px',
			},
			// root: {
			// 	backgroundColor: '#b4a0ff',
			// },
			// input: {
			// 	backgroundColor: '#b4a0ff',
			// },
		};
		
		const containerStackTokens = { childrenGap: 10 };
		const onClickCancelCopy = (e) => {
			setDataShowPanelCopy(null);
		};
		
		const onChangeListFloor = (e, option, index, value) => {
			// console.log('>>>>>>>><<<<<<<<<<', option, index, value);
			setValueSelectFloorToCopy(option);
		};
		const saveCopyFloorTitle = (e) => {
			setDataShowPanelCopy(null);
			dispatch({
				type:'GET_FLOOR_COPIED',
				value: valueSelectFloorToCopy,
				indexSelectFloor: indexFloor
			});
		};
		// console.log('>>>>>>>><<<<<<<<<<', state.dataPlansetInfo?.plans.length);
		// console.log('>>>>>>>><<<<<<<<<<', itemFloor.image);
		componentFloorModal.push(
			<div className="boxFloorOfModal">
			
				{
					dataEditFloor !== null && dataEditFloor.indexFloor === indexFloor ?
						<div className={'floorTitle'}>
							<TextField
								value={dataEditFloor.itemFloor.floor}
								label={'Этаж:'}
								// min={0}
								// max={100}
								step={1}
								type={'number'}
								// iconProps={{ iconName: 'IncreaseIndentLegacy' }}
								styles={iconSpinBuutonStyles}
								onChange={_onIncrement}
								underlined
								autoFocus
								
							/>
							<IconButton
								// styles={iconButtonStyles}
								iconProps={CheckMarkIcon}
								// ariaLabel="Close popup modal"
								onClick={saveFloorTitle}
							/>
							<IconButton
								// styles={iconButtonStyles}
								iconProps={cancelIcon}
								// ariaLabel="Close popup modal"
								onClick={getHideFloorEditTitle}
							/>
						</div> :
						<div className={'floorTitle'}>{`Этаж ${itemFloor.floor}`}</div>
				}
				
				<DivImageBox>
					<ImageContainer
						imageBack={itemFloor.image}
					>
					</ImageContainer>
				</DivImageBox>
				{dataShowPanelCopy && dataShowPanelCopy.floor === itemFloor.floor ? <div className="copyPanel">
					<Stack >
						<Stack horizontal disableShrink tokens={containerStackTokens}>
							<Stack.Item align="center"
							            // styles={stackItemStyles}
							>
								<Label>{`Этаж: ${dataShowPanelCopy.floor}`}</Label>
							</Stack.Item>
							<Stack.Item align="center"
							            // styles={stackItemStyles}
							>
								<Icon iconName="Forward" />
							</Stack.Item>
							<Stack.Item align="center"
							            // styles={stackItemStyles}
							>
								<ComboBox
									// defaultSelectedKey="Calibri"
									label={'Этажи'}
									allowFreeform
									autoComplete="on"
									options={listOfFloors}
									styles={ComboBoxCustomStyledExampleStyles}
									onChange={onChangeListFloor}
									// onRenderOption={onRenderOption}
								/>
							</Stack.Item>
							<Stack.Item align="center"
								// styles={stackItemStyles}
							>
								<IconButton
									// styles={iconButtonStyles}
									iconProps={CheckMarkIcon}
									// ariaLabel="Close popup modal"
									onClick={saveCopyFloorTitle}
								/>
							</Stack.Item>
							<Stack.Item align="center"
								// styles={stackItemStyles}
							>
								<IconButton
									// styles={iconButtonStyles}
									iconProps={cancelIcon}
									// ariaLabel="Close popup modal"
									onClick={onClickCancelCopy}
								/>
							</Stack.Item>
							
						</Stack>
					</Stack>
					
				</div> : null}
				
				<DivButtonRowPlan className={'DivButtonRowPlan'}>
					
					<TempDivButtonRowPlan>
						<IconButton
							styles={iconButtonStyles}
							iconProps={editIcon}
							// ariaLabel="Close popup modal"
							onClick={getEditFloorTitle}
						/>
						<IconButton
							styles={iconButtonStyles}
							iconProps={CopyIcon}
							// ariaLabel="Close popup modal"
							disabled={state.dataPlansetInfo?.plans.length === 1}
							onClick={onClickCopy}
						/>
						<IconButton
							styles={iconButtonStyles}
							iconProps={deleteIcon}
							// ariaLabel="Close popup modal"
							onClick={hidePlansetsEditModal}
						/>
					</TempDivButtonRowPlan>
				</DivButtonRowPlan>
			</div>
		)
	});
	
	
	const addNewFloor = (e) => {
		satStatusAddNewFloor(true);
		// dispatch({
		// 	type:'GET_FLOOR_AFTER_EDIT',
		//
		// });
	};
	
	
	const onDrop = (picture) => {
		console.log('><><><>>>>>>>><<<<', picture)
	};
	
	const onChangeTextNewFloor = (e, value) => {
		// console.log('><><><>>>>>>>><<<<', value)
		
		setValueNewFloor(+value)
	};
	
	const saveNewFloor = (e) => {
		satStatusAddNewFloor(false);
		dispatch({
			type:'ADD_NEW_FLOOR',
			value: valueNewFloor,
		});
	};
	
	if (statusAddNewFloor) {
		componentFloorModal.push(
			<div className="boxFloorOfModal addButton">
				
				<div className={'floorTitle'}>
					<TextField
						value={valueNewFloor}
						label={'Этаж:'}
						step={1}
						type={'number'}
						styles={iconSpinBuutonStyles}
						onChange={onChangeTextNewFloor}
						underlined
						autoFocus
					
					/>
					<IconButton
						iconProps={CheckMarkIcon}
						onClick={saveNewFloor}
					/>
					<IconButton
						iconProps={cancelIcon}
						// onClick={getHideFloorEditTitle}
					/>
					<ImageUploader
						withIcon={false}
						buttonText="Выбрать картинку"
						onChange={onDrop}
						imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
						maxFileSize={10000000}
						label={'Максимальный размер файла: 10mb, можно использовать: jpg, gif, png'}
						withPreview={true}
						singleImage={true}
						className={'ImageUploader'}
					/>
				</div>
				
				
			
			</div>
		);
		
	}
	else {
		componentFloorModal.push(
			<div className="boxFloorOfModal addButton">
				
				<DivAddPlan>
					<SpanAddPlan>
						<IconButton
							styles={iconAddButtonStyles}
							iconProps={addIcon}
							ariaLabel="Close popup modal"
							onClick={addNewFloor}
						/>
					</SpanAddPlan>
				</DivAddPlan>
			
			
			</div>
		);
	}
	
	
	
	// console.log('>>>>>>>>>>>>>>>>>', state.dataPlansetInfo?.plans);
	return (
		<div id={'ListPlanSetsMain'} className={'ListPlanSetsMain'} >
			<FocusZone direction={FocusZoneDirection.vertical}>
				
				<Stack tokens={stackTokens} verticalAlign="end">
					
					<TextField
						// label={'Фильтр по названию ' + filterText}
						onChange={_onFilterChanged}
						placeholder="Фильтр по имени или uuid объекта"
						styles={stylesTextField}
						// disabled={state.mapsStatusIntersectionPolygons}
					/>
				</Stack>
				
				
				
				<div className={classNames.container} data-is-scrollable={true}>
					{componentsListNode.length > 0 && _columnsGlobal.length > 0 ?
					// 	<List
					// 	// usePageCache={false}
					// 	// ref={_resolveList}
					// 	// selectedKey={selectedKey}
					// 	items={componentsListNode}
					// 	onRenderCell={Cell}
					// 	// disabled={state.mapsStatusIntersectionPolygons}
					// 	version={componentsListNode}
					// />
						<DetailsList
							items={componentsListNode}
							// compact={isCompactMode}
							columns={_columnsGlobal}
							selectionMode={SelectionMode.none}
							// getKey={this._getKey}
							setKey="none"
							layoutMode={DetailsListLayoutMode.justified}
							isHeaderVisible={true}
							// onItemInvoked={this._onItemInvoked}
						/>
					: null}
				
				</div>
			</FocusZone>
			{copyStatus ? <div className="boxNotifyCopy">
				<Stack {...horizontalStackProps}>
					<Stack {...verticalStackProps}>
						<MessageBar
							// dismissButtonAriaLabel="Close"
							// onDismiss={onChoise}
							messageBarType={MessageBarType.success}
							isMultiline={true}
						
						>
							Текст скопирован
						</MessageBar>
					</Stack>
				</Stack>
			</div> : null}
			<Modal
				// titleAriaId={titleId}
				isOpen={state.dataPlansetInfo !== null}
				onDismiss={hidePlansetsEditModal}
				isBlocking={false}
				containerClassName={contentStyles.container}
				// dragOptions={isDraggable ? dragOptions : undefined}
			>
				<div className={contentStyles.header}>
					<span >{state.dataPlansetInfo?.name}</span>
					<IconButton
						styles={iconButtonStyles}
						iconProps={cancelIcon}
						ariaLabel="Close popup modal"
						onClick={hidePlansetsEditModal}
					/>
				</div>
				<div className={contentStyles.body}>
					
					{componentFloorModal}
				</div>
			</Modal>
		</div>
	);
};

export default ListPlanSetsMain;