import React, { useContext, useState } from 'react';
import GlobalDispatchContext from "../../../global_dispatch_context";
import ListObjects from './list_objects'
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import styled from 'styled-components';
import {ContextualMenu} from "office-ui-fabric-react/lib/ContextualMenu";
import {FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Stack} from "office-ui-fabric-react";
import {FontSizes} from "@uifabric/styling";
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, PrimaryButton, IStackTokens } from 'office-ui-fabric-react';

const ScTitleNav = styled.h3`
  margin-left: 10px;
  ${
    props => props.modeDisplay === 'light' ? null :
        `color:#827f7d`
    }
  
`;


const BoxLink = styled.span`
 
 margin: 0px;
 display: grid;
 align-self: center;
 ${p => p.markerColor ?
    `color: rgb(161, 159, 157);
    `
    : null}
`;

const ContainerLink = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  //grid-template-rows: 50% 50%;
  
  
  ${p => p.marker ?
    `
     grid-template-rows: 50% 50%;
    `
    : null}
  
  
`;

const theme = getTheme();

const DetailSubSectionsBox = (props) => {

    const {state, dispatch} = useContext(GlobalDispatchContext);
    const [dataOnContext, setDataOnContext] = useState(null);
    const [selectedKeyState, setSelectedKey] = useState(null);
    const [_selectedKeyState, _setSelectedKey] = useState(null);
    const [_selectedNameState, _setSelectedName] = useState(null);
    const [nameGroupZones, setNameGroupZones] = useState(null);
    const [markerGroupZones, setMarkerGroupZones] = useState(null);
    let componentNodeSection = [];
    
    const iconPropsForIconBuuton = { iconName: 'Cancel' };
    
    const tokensStack = { childrenGap: 50 };
    
    const stylesStack = { root: { width: 300 } };
    
    const columnProps = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 300 } }
    };
    
    
    const onSelectDetailButton = (e, item) => {
        if (state.sectionStatus === 'scale') {
            // console.log('>>>>>>>>>>>>>>>', state.dataPlanSetByFloor[state.selectedFloor].scale.polygon.coords, state.dataPlanSetByFloor[state.selectedFloor].scale.segment.coords)
            if (state.dataPlanSetByFloor[state.selectedFloor].scale.polygon.coords !== undefined &&
                state.dataPlanSetByFloor[state.selectedFloor].scale.segment.coords !== undefined) {

                if (state.dataPlanSetByFloor[state.selectedFloor].scale.segment.coords.length > 0 ||
                    state.dataPlanSetByFloor[state.selectedFloor].scale.polygon.coords.length > 0
                ) {
                    return;
                }
            }

        }
        if (item.key !== 'new') {
            dispatch({
                type:'GET_SUB_SECTION_STATUS',
                status: item.key
            })
        }
        else {
            props.setStatusShowModalOfNewGroupZones(true);
        }

    };
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                fontSize: FontSizes.xLarge,
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {
                    margin: '14px 0'
                },
                'p:first-child': {
                    marginTop: 0
                },
                'p:last-child': {
                    marginBottom: 0
                }
            }
        }
    });
    
    const iconButtonStyles = mergeStyleSets({
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px'
        },
        rootHovered: {
            color: theme.palette.neutralDark
        }
    });
    
    
    let selectedKey = null;
    if (state.dataPlanSetByFloor[state.selectedFloor] !== undefined) {
        if (state.sectionStatus === 'zones') {


            state.dataPlanSetByFloor[state.selectedFloor].layers.zone_layer.groups.forEach((item, index) => {
               
                selectedKey = state.selectedGruopMarker;
                componentNodeSection.push(
                    {
                        name: item.group_name,
                        url: '',
                        key: item.group_marker,
                        marker: item.group_marker,
                        isExpanded: true,
                        disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                    }
                )

            });
            componentNodeSection.push(
                {
                    name: 'New',
                    url: '',
                    key: 'new',
                    icon: 'Add',
                    isExpanded: true,
                    marker: 'new',
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            )
        }
        else if (state.sectionStatus === 'polygons') {
            componentNodeSection.push(
                {
                    name: 'polygon in',
                    url: '',
                    key: 'polygonIn',
                    isExpanded: true,
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            componentNodeSection.push(
                {
                    name: 'polygon out',
                    url: '',
                    key: 'polygonOut',
                    isExpanded: true,
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            selectedKey = state.selectedNavigationGeometryType;

        }
        else if (state.sectionStatus === 'scale') {
            componentNodeSection.push(
                {
                    name: 'Линия',
                    url: '',
                    key: 'segment',
                    isExpanded: true,
                    disabled: (state.dataPlanSetByFloor[state.selectedFloor].scale.polygon.coords.length > 0) || state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            componentNodeSection.push(
                {
                    name: 'Полигон',
                    url: '',
                    key: 'polygon',
                    isExpanded: true,
                    disabled: (state.dataPlanSetByFloor[state.selectedFloor].scale.segment.coords.length > 0) || state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            selectedKey = state.selectedScaleType;

        }
        else if (state.sectionStatus === 'tracks') {
            componentNodeSection.push(
                {
                    name: 'tracking',
                    url: '',
                    key: 'tracking',
                    isExpanded: true,
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            componentNodeSection.push(
                {
                    name: 'navigation',
                    url: '',
                    key: 'navigation',
                    isExpanded: true,
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            componentNodeSection.push(
                {
                    name: 'wifi',
                    url: '',
                    key: 'wifi',
                    isExpanded: true,
                    disabled: state.mapsStatusIntersectionPolygons ||  state.checkedPlacesInputCreate
                }
            );
            selectedKey = state.typeTrack;

        }

    }
    const onContextMenu = (e,key, name, marker) => {
        if (state.sectionStatus === 'zones') {
            e.preventDefault();
            // console.log('!!!!!!!!!!!!!!!!!!!')
            setDataOnContext({x: e.pageX,
                y: e.pageY});
            setSelectedKey(key);
            _setSelectedKey(key);
            _setSelectedName(name);
            setMarkerGroupZones(marker);

        }
    }

    const onHideContext = (e) => {
        setDataOnContext(null);
        setSelectedKey(null);
        // _setSelectedKey(null)
    };

    function _onRenderGroupHeader(group) {
        return <ScTitleNav modeDisplay={state.modeDisplay}>{group.name}</ScTitleNav>;
    }

    function _onRenderLink(item) {

        const onContext = (e) =>{
            // console.log('>>>>>>>>>>>>>>>', item)
            onContextMenu(e, item.key, item.name, item.marker)
        };
        let statusMarker = false;
        
        if (item.marker !== undefined) {
            statusMarker = true;
        }
        return <ContainerLink
            marker={statusMarker}
            style={{
                        width: '100%',
                        textAlign: 'left'
                    }}
            onContextMenu={onContext}
        >
            <BoxLink>
                {`Имя: ` + item.name}
            </BoxLink>
            {item.marker !== undefined ? <BoxLink markerColor>
                {`Маркер: ` + item.marker}
            </BoxLink> : null}
        </ContainerLink>;
    };

    const deleteGroupZones = () => {

        dispatch({
            type:'DELETE_GROUP_ZONES',
            keyGroupZone: selectedKeyState
        })
    };
    
    const renameGroupZones = () => {
        
        setNameGroupZones(selectedKeyState);
        // dispatch({
        //     type:'DELETE_GROUP_ZONES',
        //     keyGroupZone: selectedKeyState
        // })
    };
    
    
    const onChacngeGroupZones = (e, value) => {
        // console.log('>>>>!!>>',value);
        _setSelectedName(value);
        // dispatch({
        //     type:'DELETE_GROUP_ZONES',
        //     keyGroupZone: selectedKeyState
        // })
    };
    
    const onChacngeMarkerGroupZones = (e, value) => {
        // console.log('>>>>!!>>',value);
        setMarkerGroupZones(value);
        // dispatch({
        //     type:'DELETE_GROUP_ZONES',
        //     keyGroupZone: selectedKeyState
        // })
    };
    
    const saveNewNameGroupZones = (e, value) => {
        // console.log('>>>>!!>>',value);
        // setNameGroupZones(value);
        dispatch({
            type:'SAVE_NEW_NAME_GROUP_ZONE',
            _selectedKeyState,
            nameGroupZones: _selectedNameState,
            markerGroupZones
        });
        setNameGroupZones(null);
    };
    
    const hideModalNewNameGroupZone = (e, value) => {
        
        setNameGroupZones(null);
    };

    const menuItems = [
        {
            key: 'delete',
            text: 'Delete',
            onClick: () => deleteGroupZones()
        },
        {
            key: 'Rename',
            text: 'Rename',
            onClick: () => renameGroupZones()
        }
    ];

    if (componentNodeSection.length > 0) {
        const stylesNav = {
            root: {
                width: 208,
                height: 164,
                boxSizing: 'border-box',
                // border: '1px solid rgb(114, 115, 114)',
                // overflowY: 'hidden'
                margin: 0
            },
            navItems: {
                width: 208,
                height: 112,
                boxSizing: 'border-box',
                overflowY: 'auto',
            },
            // group: {
            //     margin: 0
            // },
            groupContent: {
                margin: 0,
                height: 112,
                boxSizing: 'border-box',
            }
        };

        const groupsNav = [
            {
                name: 'Детально',
                links: componentNodeSection
            },

        ];

        const target = dataOnContext !== null ? {x: dataOnContext.x, y: dataOnContext.y} : {x: 0, y: 0};

        return (
            <div className={'DetailSubSectionsBox'}>
                <div className='temp'>
                    <Nav
                        onLinkClick={onSelectDetailButton}
                        selectedKey={selectedKey}
                        onRenderGroupHeader={_onRenderGroupHeader}
                        selectedAriaLabel="Selected"
                        ariaLabel="Nav example with custom group headers"
                        onRenderLink={_onRenderLink}
                        // isLink={false}
                        styles={stylesNav}
                        groups={groupsNav}
                        
                    />
                    <ContextualMenu
                        items={menuItems}
                        hidden={dataOnContext === null}
                        target={target}
                        onItemClick={onHideContext}
                        onDismiss={onHideContext}
                    />

                </div>


                <ListObjects
                    withoutTempBox={false}
                />
                <Modal
                    // titleAriaId={'New Hash'}
                    // subtitleAriaId={'subText'}
                    isOpen={nameGroupZones !== null}
                    onDismiss={hideModalNewNameGroupZone}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                    // dragOptions={_dragOptions}
                >
        
                    <div className={contentStyles.header}>
                        <span id={'New group zone'}>Изменение названия группы зон</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={iconPropsForIconBuuton}
                            ariaLabel="Close popup modal"
                            onClick={hideModalNewNameGroupZone}
                        />
                    </div>
                    <div id={'subText'} className={contentStyles.body}>
                        <Stack vertical tokens={tokensStack} styles={stylesStack}>
                            <Stack {...columnProps}>
                                <div>
                                    <TextField
                                        value={_selectedNameState}
                                        label="Название"
                                        autoFocus={true}
                                        onChange={onChacngeGroupZones}
                                    />
                                    
                                </div>
                                <div>
                                    <TextField
                                        value={markerGroupZones}
                                        label="Маркер"
                                        autoFocus={true}
                                        onChange={onChacngeMarkerGroupZones}
                                    />
    
                                </div>
                                <PrimaryButton
                                    text="Сохранить"
                                    onClick={saveNewNameGroupZones}
                                    allowDisabledFocus
                                    
                                    // disabled={disabled}
                                    // checked={checked}
                                />
                            </Stack>
            
                        </Stack>
                    </div>
    
    
                </Modal>
            </div>
        );
    }
    else {
        return (
            <div className={'DetailSubSectionsBox withoutTempBox'}>


                <ListObjects
                    withoutTempBox={true}
                />
            </div>
        );
    }

};



export default DetailSubSectionsBox;