import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
// import _t from 'i18next';

const subDomains = [ 'admin', 'maps'];

const EngineeringModal = React.memo(({ backend, ...props }) => {
    const [localBackend, setLocalBackend] = useState(backend);

    const stackTokens = { childrenGap: 10 };

    const onModalButtonClick = (action, params) => (e) => {
        props.onModalButtonClick({ name: action }, params);
    };

    const _onChange = (parameter) => (e, newValue) => {
        const loc = { ...localBackend, [parameter]: newValue };
        setLocalBackend({ ...loc });
    };

    const _onCheckChange = (parameter) => (e, checked) => {
        const loc = { ...localBackend, checks: { ...localBackend.checks, [parameter]: checked } };
        setLocalBackend({ ...loc });
    };

    return (
        <MainWrapper>
            <Container>
                <StStack tokens={{ childrenGap: 15 }}>
                    {subDomains.map((item, i) => {
                        const checked = localBackend && localBackend.checks && localBackend.checks[item] ? localBackend.checks[item] : false;
                        return (
                            <Wrapper>
                                <StCheckbox checked={checked} onChange={_onCheckChange(item)} />
                                <StTextField
                                    key={`${i} - ${item}`}
                                    label={item}
                                    value={localBackend ? (localBackend[item] || '') : ''}
                                    onChange={_onChange(item)}
                                    // onBlur={_onBlur(parameter, 0)}
                                    // styles={{ fieldGroup: { width: 150 } }}
                                />
                            </Wrapper>
                        );
                    })}
                </StStack>
            </Container>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={'OK'} width={'100px'} onClick={onModalButtonClick('changeBackendUrls', { ...localBackend })} />
                    <DefaultButton text={'Отмена'} width={'100px'} onClick={onModalButtonClick(null)} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default EngineeringModal;

const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;

const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
`;
const Name = styled.div`
    border-right: ${(p) => '2px solid' + p.primaryColor};
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 15px;
    font-weight: 700;
`;
const InputsWrapper = styled.div`
    display: flex;
    padding-left: 10px;
    align-items: center;
`;

const StTextField = styled(TextField)`
    width: 100% !important;
    margin-left: 10px;
    textarea {
        max-height: 300px !important;
        overflow: auto !important;
    }
`;
const StStack = styled(Stack)`
    width: 100% !important;
`;
const StCheckbox = styled(Checkbox)`
    margin-bottom: 5px;
`;
