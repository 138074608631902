
import tools from '../../../tools'

const toolsForTopPanel = {
    save: (data) => {
        
    	let dataPlanSet = tools.copy(data.src.dataPlanSet);
        // let dataTrack = tools.copy(data.src.dataTrack);
        let tracksData = tools.copy(data.tracksData);
        dataPlanSet.plans.forEach((item, index) => {

            item.layers.appearance_layer.perimeters = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].perimeter.coords);
            item.layers.navigation_geometry_layer.polygons_in = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].polygonIn.coords);
            item.layers.navigation_geometry_layer.polygons_out = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].polygonOut.coords);
            item.layers.place_layer.places = [];
            // console.log('>>>>>>>>>>>>>>>>>>>', data.dataPlanSetByFloor[item.floor].places.entrances)
            data.dataPlanSetByFloor[item.floor].places.markers.forEach((itemPlaces, indexPlaces) => {

                item.layers.place_layer.places.push(
                    {
                        coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].places.coords[indexPlaces]),
                        is_opened: data.dataPlanSetByFloor[item.floor].places.isOpeneds[indexPlaces],
                        marker: itemPlaces,
                        entrances: data.dataPlanSetByFloor[item.floor].places.entrances[indexPlaces]
                    }
                )

            });
            // console.log('>>>>>>>>>>>2', data.dataPlanSetByFloor[item.floor].scale);
            item.layers.scale_layer.polygons = [];
            item.layers.scale_layer.segments = [];
            if (data.dataPlanSetByFloor[item.floor].scale.polygon.coords.length > 0) {
                // console.log('>>>>>>>>', data.dataPlanSetByFloor[item.floor].scale.polygon.coords)
                item.layers.scale_layer.polygons = [{
                    coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.polygon.coords),
                    area_m2: data.dataPlanSetByFloor[item.floor].scale.polygon.param
                }];
            }
            else if (data.dataPlanSetByFloor[item.floor].scale.segment.coords.length > 0) {
                // console.log('>>>>>>>>', toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.segment.coords))
                item.layers.scale_layer.segments = [{
                    coords:toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.segment.coords),
                    length_m: data.dataPlanSetByFloor[item.floor].scale.segment.param
                }];
            }

            item.layers.shopster_ipoint_layer.ipoints = [];
            data.dataPlanSetByFloor[item.floor].IPoint.markers.forEach((itemIPoint, indexIPoint) => {
                item.layers.shopster_ipoint_layer.ipoints.push({
                    coords: toolsForTopPanel.proccesCoordsIPoint(data.dataPlanSetByFloor[item.floor].IPoint.coords[indexIPoint]),
                    is_opened: data.dataPlanSetByFloor[item.floor].IPoint.isOpeneds[indexIPoint],
                    marker: itemIPoint,
                    type: data.dataPlanSetByFloor[item.floor].IPoint.types[indexIPoint]
                });

            });
            item.layers.zone_layer.groups = []
            for (let key in data.dataPlanSetByFloor[item.floor].zones) {
                let arrayZones = []
                data.dataPlanSetByFloor[item.floor].zones[key].markers.forEach((itemZone, indexZone) => {

                    arrayZones.push({
                        coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].zones[key].coords[indexZone]),
                        zone_marker: itemZone,
                        zone_name: data.dataPlanSetByFloor[item.floor].zones[key].names[indexZone]
                    });

                    // console.log('>>>>>>>>', data.dataPlanSetByFloor[item.floor].zones[key].names[indexZone])

                });
                item.layers.zone_layer.groups.push({
                    group_marker: key,
                    group_name: data.dataPlanSetByFloor[item.floor].zones[key].nameGroup,
                    zones: arrayZones
                });
            }
            // console.log('>>>>>>>>>>>>>>>', item);
        });
        let _tracksData = tools.copy(tracksData);
        for (var keyTrack in tracksData) {
            _tracksData[keyTrack] = [];
            tracksData[keyTrack].forEach((itemTrack, indexTrack) => {
                let _objDataTrackByIndex = {};
                data.dataTrackForDraw[keyTrack].data[itemTrack.track_type].data.forEach((itemTrack2, indexTrack2) => {
                    // console.log('>>>>>>>>>>>>>>>1', indexTrack, itemTrack2.id);
                    if (itemTrack2.id !== null) {
                        
                        if (itemTrack2.id === itemTrack.id) {
                            itemTrack = {
                                ...itemTrack2,
                                points: toolsForTopPanel.processCoords(data.dataTrackForDraw[keyTrack].data[itemTrack.track_type].coords[indexTrack2])
                            }
    
                            tracksData[keyTrack][indexTrack] = itemTrack;
                            _tracksData[keyTrack].push(itemTrack);
                        }
                        
                        
                        // console.log('>>>>>>>>>>>>>>>', tracksData[keyTrack][indexTrack]);
                    }
                    else if (itemTrack2.id === null) {
                        if (indexTrack === (tracksData[keyTrack].length - 1)) {
                            
                            itemTrack = {
                                ...itemTrack2,
                                points: toolsForTopPanel.processCoords(data.dataTrackForDraw[keyTrack].data[itemTrack.track_type].coords[indexTrack2])
                            }
                            tracksData[keyTrack][indexTrack] = itemTrack;
                            _tracksData[keyTrack].push(itemTrack);
                        }
                    }
                })
                // tracksData[keyTrack][indexTrack] = itemTrack;
                // _tracksData[keyTrack].push(itemTrack);
                
            });

            // console.log('>>>>>>>>>>>>>>>1', tracksData[keyTrack]);
        }
        let tempStatusTrackLength = false;
        for (var keyy in _tracksData) {
            
            _tracksData[keyy].forEach((itemKey, indexKey) => {
                // console.log('>>>>>>>>>>>>>>>1', itemKey.points.length);
                if (itemKey.points.length < 2) {
                    tempStatusTrackLength = true;
                }
            });
            
        }
        // console.log('>>>>>>>>>>>>>>>1', tempStatusTrackLength);
        
        if (tempStatusTrackLength) {
            alert('Один или несколько треков содержат либо коородинату одной точки либо совершенно нет координат, надо перерисовать!')
            
        }
        // console.log('!!!!!!!!@@@@@@@@@@@', dataPlanSet)
        return {tracksData: _tracksData, dataPlanSet, tempStatusTrackLength}
    },
    fixConflict: (data, listOnFix) => {
        let dataPlanSet = tools.copy(data.src.dataPlanSet);
        // let dataTrack = tools.copy(data.src.dataTrack);
        let tracksData = tools.copy(data.tracksData);
        dataPlanSet.plans.forEach((item, index) => {
            
            
            let _objStatus = {
                appearance_layer:'',
                navigation_geometry_layer:'',
                place_layer:'',
                shopster_ipoint_layer:'',
                zone_layer:'',
                tracks:''
            }
    
            listOnFix.forEach((itemConflict, indexConflict) => {
                
                if (itemConflict.floor === item.floor) {
                    // console.log('>>>>>>>>>>>>>>>', itemConflict.checkboxData);
                    if (itemConflict.checkboxData.checkboxServer) {
                        _objStatus[itemConflict.name] = 'checkboxServer'
                    }
                    else if (itemConflict.checkboxData.checkboxLocal) {
                        _objStatus[itemConflict.name] = 'checkboxLocal'
                    }
                    else {
                        _objStatus[itemConflict.name] = 'checkboxLocal'
                    }
                    
                }
            });
            // console.log('>>>>>>>>>>>>>>>', data.src.remoteDataPlanSet);
            if (_objStatus.appearance_layer === 'checkboxServer'){
                item.layers.appearance_layer = data.src.remoteDataPlanSet.plans[index].layers.appearance_layer;
            }
            else {
                item.layers.appearance_layer.perimeters = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].perimeter.coords);
            }
    
            if (_objStatus.navigation_geometry_layer === 'checkboxServer'){
                item.layers.navigation_geometry_layer = data.src.remoteDataPlanSet.plans[index].layers.navigation_geometry_layer;
            }
            else {
                item.layers.navigation_geometry_layer.polygons_in = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].polygonIn.coords);
                item.layers.navigation_geometry_layer.polygons_out = toolsForTopPanel.processCoordsForEach(data.dataPlanSetByFloor[item.floor].polygonOut.coords);
            }
    
            if (_objStatus.place_layer === 'checkboxServer'){
                item.layers.place_layer = data.src.remoteDataPlanSet.plans[index].layers.place_layer;
            }
            else {
                item.layers.place_layer.places = [];
                data.dataPlanSetByFloor[item.floor].places.markers.forEach((itemPlaces, indexPlaces) => {
                    // console.log('>>>>>>>>>>>>>>>1', data.dataPlanSetByFloor[item.floor].places.entrances);
                    item.layers.place_layer.places.push(
                        {
                            coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].places.coords[indexPlaces]),
                            is_opened: data.dataPlanSetByFloor[item.floor].places.isOpeneds[indexPlaces],
                            marker: itemPlaces,
                            entrances: data.dataPlanSetByFloor[item.floor].places.entrances[indexPlaces]
                        }
                    )
        
                });
            }
    
            if (_objStatus.scale_layer === 'checkboxServer'){
                item.layers.scale_layer = data.src.remoteDataPlanSet.plans[index].layers.scale_layer;
            }
            else {
                item.layers.scale_layer.polygons = [];
                item.layers.scale_layer.segments = [];
                if (data.dataPlanSetByFloor[item.floor].scale.polygon.coords.length > 0) {
                    // console.log('>>>>>>>>', data.dataPlanSetByFloor[item.floor].scale.polygon.coords)
                    item.layers.scale_layer.polygons = [{
                        coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.polygon.coords),
                        area_m2: data.dataPlanSetByFloor[item.floor].scale.polygon.param
                    }];
                }
                else if (data.dataPlanSetByFloor[item.floor].scale.segment.coords.length > 0) {
                    // console.log('>>>>>>>>', toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.segment.coords))
                    item.layers.scale_layer.segments = [{
                        coords:toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].scale.segment.coords),
                        length_m: data.dataPlanSetByFloor[item.floor].scale.segment.param
                    }];
                }
            }
    
            if (_objStatus.shopster_ipoint_layer === 'checkboxServer'){
                item.layers.shopster_ipoint_layer = data.src.remoteDataPlanSet.plans[index].layers.shopster_ipoint_layer;
            }
            else {
                item.layers.shopster_ipoint_layer.ipoints = [];
                data.dataPlanSetByFloor[item.floor].IPoint.markers.forEach((itemIPoint, indexIPoint) => {
                    item.layers.shopster_ipoint_layer.ipoints.push({
                        coords: toolsForTopPanel.proccesCoordsIPoint(data.dataPlanSetByFloor[item.floor].IPoint.coords[indexIPoint]),
                        is_opened: data.dataPlanSetByFloor[item.floor].IPoint.isOpeneds[indexIPoint],
                        marker: itemIPoint,
                        type: data.dataPlanSetByFloor[item.floor].IPoint.types[indexIPoint]
                    });
        
                });
            }
    
            if (_objStatus.zone_layer === 'checkboxServer'){
                item.layers.zone_layer = data.src.remoteDataPlanSet.plans[index].layers.zone_layer;
            }
            else {
                item.layers.zone_layer.groups = []
                for (let key in data.dataPlanSetByFloor[item.floor].zones) {
                    let arrayZones = []
                    data.dataPlanSetByFloor[item.floor].zones[key].markers.forEach((itemZone, indexZone) => {
            
                        arrayZones.push({
                            coords: toolsForTopPanel.processCoords(data.dataPlanSetByFloor[item.floor].zones[key].coords[indexZone]),
                            zone_marker: itemZone,
                            zone_name: data.dataPlanSetByFloor[item.floor].zones[key].names[indexZone]
                        });
            
                        // console.log('>>>>>>>>', data.dataPlanSetByFloor[item.floor].zones[key].names[indexZone])
            
                    });
                    item.layers.zone_layer.groups.push({
                        group_marker: key,
                        group_name: data.dataPlanSetByFloor[item.floor].zones[key].nameGroup,
                        zones: arrayZones
                    });
                }
            }
            
        
            
            
            // console.log('>>>>>>>>>>>>>>>', item);
        });
    
        let _tracksData = tools.copy(tracksData);
        for (var key in tracksData) {
            let _objStatus = {
                appearance_layer:'',
                navigation_geometry_layer:'',
                place_layer:'',
                shopster_ipoint_layer:'',
                zone_layer:'',
                tracks:''
            }
            listOnFix.forEach((itemConflict2, indexConflict2) => {
        
                if (itemConflict2.floor === +key) {
                    // console.log('>>>>>>>>>>>>>>>', itemConflict.checkboxData);
                    if (itemConflict2.checkboxData.checkboxServer) {
                        _objStatus[itemConflict2.name] = 'checkboxServer'
                    }
                    else if (itemConflict2.checkboxData.checkboxLocal) {
                        _objStatus[itemConflict2.name] = 'checkboxLocal'
                    }
                    else {
                        _objStatus[itemConflict2.name] = 'checkboxLocal'
                    }
            
                }
            });
            if (_objStatus.tracks === 'checkboxServer') {
                tracksData[key] = data.src.remoteTracks[key];
            }
            else {
                _tracksData[key] = [];
                tracksData[key].forEach((itemTrack, indexTrack) => {
                    data.dataTrackForDraw[key].data[itemTrack.track_type].data.forEach((itemTrack2, indexTrack2) => {
            
                        if (itemTrack2.id === itemTrack.id) {
                
                            itemTrack = {
                                ...itemTrack2,
                                points: toolsForTopPanel.processCoords(data.dataTrackForDraw[key].data[itemTrack.track_type].coords[indexTrack2])
                            }

                            tracksData[key][indexTrack] = itemTrack;
                            _tracksData[key].push(itemTrack);
                            // console.log('>>>>>>>>>>>>>>>', tracksData[keyTrack][indexTrack]);
                        }
                    })
        
                })
                tracksData[key] = _tracksData[key];
            }
            // console.log('>>>>>>>>>>>>>>>', data.src.remoteTracks)
        }
        
        
        
        return {tracksData, dataPlanSet}
    },
    proccesCoordsIPoint: (coords) => {
        return [coords.x, coords.y]
    },
    processCoords: (coords) => {
        let _coords = []
        // console.log('>>>>>>>>>>>>>>>', coords);
        coords.forEach((item, index) => {
            // console.log('>>>>>>>>>>>>>>>', item, item.x);
            _coords.push([item.x, item.y])
        });
        return _coords;
    },
    processCoordsForEach: (arrayCoords) => {
        let _arrayCoords = [];
        arrayCoords.forEach((item, index) => {
            let _coords = []
            item.forEach((_item, _index) => {
                // console.log('>>>>>>>>>>>>>>>', _item);
                _coords.push([
                    _item.x,
                    _item.y
                ])
            });
            _arrayCoords.push(_coords)
        });
        return _arrayCoords;
    }

};

export default toolsForTopPanel;